//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";

export default {
    name: "Modals",
    computed: {
        ...mapState("modals", ["arrOpenedModals"]),
        ...mapGetters("modals", ["defaultLayout", "getOpenedModal"]),
    },
};
