import Vue from "vue";
import YmapPlugin from "vue-yandex-maps";

const settings = {
    apiKey: "b232e0a4-28f8-4981-940a-fdbd0f70f971",
    lang: "ru_RU",
    coordorder: "latlong",
    version: "2.1",
};

Vue.use(YmapPlugin, settings);
