import { UrlItems } from "~/service/storages/urls-storage/types";
import {
    CatalogView,
    MarkupForModal,
    MenuCategory,
    MenuProduct,
    MenuStopList,
    ProductEnergyValue,
} from "~/util/api.types";

export const LOCAL_STORAGE_ADDITIONAL_PRODUCTS_KEY = "additionalProducts";

export interface ProductsState {
    stopList: MenuStopList | null;
    searchHistory: any[];
    zeroAdditionalsAccepted: boolean;
    recommendations: any | null;
    categories: MenuCategory[] | null;
    products: MenuProduct[] | null;
    urls: UrlItems;
    metadata: any;
    catalogView: CatalogView;
    currentSort: string;
    isSearchHide: boolean;
    additionalExceed: any;
    noticedProduct: any;
    nextScrollSpeed: number | null;
    viewOpacity: number;
    currentViewCategoryId: number | null;
    anotherMenu: number;
    currentProductEnergyValue: ProductEnergyValue;
    markupForModal: MarkupForModal;
    refresh: number;
    modifiersErrors: any[];
    recommendWaitToAdd: {} | null;
}
