import { ActionTree, GetterTree, MutationTree } from "vuex";
import config from "../config";
import { resizeBannerImages } from "../service/image-resize-helper/utils";
import { BannersStorage } from "../service/storages/banners-storage";
import { PromoState } from "~/store_types/promo.types";
import { RootState } from "~/store_types/index.types";
import { cloneDeep } from "~/util/clone";
import { Banner, MenuProduct, Stori } from "~/util/api.types";
import { preloadBannerFirstImages } from "~/service/storages/image-base64-uri-storage/utils";
import { time } from "~/util/helpers";

export const state: () => PromoState = () => ({
    requireUserPhonePromocode: null,
    // cancelledPromo: null,
    banners: [],
    stories: [],
    storiesHistory: [],
    recommendedProducts: null,
});

export const getters: GetterTree<PromoState, RootState> = {
    giftsAvailable: (state, getters, rootState, rootGetters) => {
        return rootGetters.cart.available_gifts && rootGetters.cart.available_gifts.length > 0;
    },
    promoeventsAvailable: (state, getters, rootState, rootGetters) => {
        return !!rootGetters.cart.promoevents_available && rootGetters.cart.promoevents_available.length > 0;
    },
    giftProducts: (state, getters, rootState, rootGetters) => {
        const selected = rootGetters.cart.selected_gifts || [];
        const available = cloneDeep(rootGetters.cart.available_gifts || []) as Array<any>;

        available.forEach((gift) => {
            let totalCnt = 0;
            gift.products.forEach((product) => {
                const selectedGift = selected.find((g) => g.id == gift.id);
                if (selectedGift) {
                    product.selected_count = selectedGift.products[product.id] || 0;
                } else {
                    product.selected_count = 0;
                }
                product.gift_id = gift.id;
                product.key = gift.id + "_" + product.id;
                product.canAdd = product.max_quantity === null || product.selected_count < product.max_quantity;
                totalCnt += product.selected_count;
            });

            if (totalCnt >= gift.max_quantity) {
                gift.products.forEach((product) => {
                    product.canAdd = false;
                });
            }
        });
        return available.flatMap((item) => item.products); // _(available).map('products').flatten().value()
    },
    giftProductsPopupDelivery: (state, getters) => {
        return getters.giftProducts.filter((gift) => gift.gift_id == getters.promoPopupDelivery.id);
    },
    promoevents: (state, getters, rootState, rootGetters) => {
        const applied = rootGetters.cart.promoevents_applied || [];
        const available = rootGetters.cart.promoevents_available || [];

        let hasActive = false;
        const promoList = available.map((promo) => {
            const newPromo = cloneDeep(promo);

            const promoId = parseInt(newPromo.id);
            newPromo.active = applied.includes(promoId);
            hasActive = hasActive || newPromo.active;

            return newPromo;
        });

        promoList.push({
            id: 0,
            title: "Без акции",
            active: !hasActive,
        });

        return promoList;
    },
    promoPopupDelivery: (state, getters) => {
        const promoInDelivery = getters.promoevents.filter((promo) => promo.show_delivery_popup);
        if (promoInDelivery) {
            return promoInDelivery[0];
        }
    },
    requireUserPhone: (state) => {
        return state.requireUserPhonePromocode !== null;
    },
    cancelledPromo(state, getters, rootState, rootGetters) {
        // console.log(rootGetters.cart.promoevents_cancelled, 'rootGetters.cart.promoevents_cancelled')
        return rootGetters.cart.promoevents_cancelled;
    },
    hasRecommendedProducts(state) {
        return !!state.recommendedProducts?.products;
    },
    getStoriesHistory(state) {
        return state.storiesHistory;
    },
};

export const mutations: MutationTree<PromoState> = {
    setRequireUserPhone(state, { promocode }) {
        state.requireUserPhonePromocode = promocode;
    },
    clearRequireUserPhone(state) {
        state.requireUserPhonePromocode = null;
    },
    setBanners(state, banners) {
        state.banners = banners;
    },
    setStories(state, stories) {
        state.stories = stories;
    },
    setRecommendation(state, prods: { products: MenuProduct[] }) {
        if (prods) {
            prods.products = [...prods.products];
            prods.products.sort((a, b) => (b.sort_order ?? 0) - (a.sort_order ?? 0));
        }
        state.recommendedProducts = prods;
    },
    setStoriesHistory(state, storiesHistory) {
        state.storiesHistory = storiesHistory;
    },
    updateStoriesHistory(state, storyId) {
        state.storiesHistory.find((story) => story.id === storyId).isChecked = true;
    },
};

export const actions: ActionTree<PromoState, RootState> = {
    async applyPromocode({ commit, dispatch }, promocode) {
        dispatch("errors/clearDisplayError", "promo.code", { root: true });
        commit("errors/clearError", "promo_phone_required", { root: true });
        dispatch("wait/start", "promo.code", { root: true });
        let resp;

        try {
            resp = await this.$postCartQueue.add("cart/promocode", { promocode }, 0);
            commit("clearRequireUserPhone");
        } catch (error) {
            if (error.error_code === "phone_required") {
                commit("setRequireUserPhone", { promocode });
            } else {
                dispatch("errors/addDisplayError", { "promo.code": error.error_message }, { root: true });
            }
        } finally {
            dispatch("wait/end", "promo.code", { root: true });
            // eslint-disable-next-line no-unsafe-finally
            return resp;
        }
    },
    async selectPromo({ commit, dispatch, getters, rootState }, payload) {
        commit("errors/clearError", "promo_phone_required", { root: true });

        let applied = [...((rootState.cart.content?.promoevents_applied || []) as number[])];
        console.log("applied", applied);

        if (payload.id == 0) {
            applied = [];
            commit("cart/resetGifts", null, { root: true });
        } else if (applied.find((id) => payload.id == id)) {
            applied = applied.filter((id) => payload.id != id);
        } else {
            applied.push(payload.id);
        }

        const promoeventsApplied = { promoevents_applied: applied };

        dispatch("errors/clearDisplayError", "promo.action", { root: true });
        dispatch("wait/start", "promo.action." + payload.id, { root: true });

        try {
            const resp = await this.$postCartQueue.add("cart/promoevents", promoeventsApplied, 0);
        } catch (error) {
            dispatch("errors/addDisplayError", { "promo.action": error.error_message }, { root: true });
        } finally {
            getters.promoevents.forEach((p) => {
                dispatch("wait/end", "promo.action." + p.id, { root: true });
            });
        }
    },

    async removePromoevents({ commit, dispatch, rootState, getters }, promo) {
        commit("errors/clearError", "promo_phone_required", { root: true });
        let applied = [...((rootState.cart.content?.promoevents_applied || []) as number[])];
        if (applied.find((id) => promo.id == id)) {
            applied = applied.filter((id) => promo.id != id);
        }

        const promoeventsApplied = { promoevents_applied: applied };

        dispatch("errors/clearDisplayError", "promo.action", { root: true });
        dispatch("wait/start", "promo.action." + promo.id, { root: true });

        let resp;
        try {
            resp = await this.$postCartQueue.add("cart/promoevents", promoeventsApplied, 0);
        } catch (error) {
            dispatch("errors/addDisplayError", { "promo.action": error.error_message }, { root: true });
        } finally {
            getters.promoevents.forEach((p) => {
                dispatch("wait/end", "promo.action." + p.id, { root: true });
            });
        }
        return resp;
    },

    async updateGift({ commit, dispatch, rootState, getters, rootGetters }, payload) {
        if (payload.quantity < 0) {
            return;
        }

        const selectedGift = rootGetters.cart.selected_gifts.find((sg) => sg.id == payload.product.gift_id);
        const giftProducts = Object.assign({}, selectedGift ? selectedGift.products : {}, {
            [payload.product.id]: payload.quantity,
        });

        dispatch("errors/clearDisplayError", "promo.gift", { root: true });
        dispatch("wait/start", "promo.gift", { root: true });

        const gift = { id: payload.product.gift_id, products: giftProducts };
        commit("cart/setSelectedGifts", gift, { root: true });

        try {
            const resp = await this.$postCartQueue.add("cart/gift", gift, 500);
        } catch (error) {
            dispatch("errors/addDisplayError", { "promo.gift": error.error_message }, { root: true });
        } finally {
            dispatch("wait/end", "promo.gift", { root: true });
        }
    },
    async addGift({ dispatch }, product) {
        if (!product.canAdd) {
            return;
        }
        await dispatch("updateGift", {
            product,
            quantity: product.selected_count + 1,
        });
    },
    async removeGift({ dispatch }, product) {
        await dispatch("updateGift", {
            product,
            quantity: product.selected_count - 1,
        });
    },

    async checkPhone({ commit, dispatch, state }, phone) {
        commit("form/setPhone", phone, { root: true });

        await dispatch("form/updateUserInfo", null, { root: true });
        if (state.requireUserPhonePromocode) {
            await dispatch("applyPromocode", state.requireUserPhonePromocode);
        }
    },

    validate({ commit, dispatch, getters, rootGetters }) {
        commit("errors/clearError", "promo_gift_not_selected", { root: true });

        if (getters.requireUserPhone && !rootGetters["form/getPhone"]) {
            return Promise.reject({ promo_phone_required: "Укажите номер телефона" });
        }

        // все подарки по акциям выбраны
        const availableGifts = rootGetters.cart.available_gifts;
        if (availableGifts) {
            const selectedGifts = rootGetters.cart.selected_gifts || [];
            let hasNotSelected = false;

            for (const available of availableGifts) {
                const selected = selectedGifts.find((sg) => sg.id == available.id);
                const selectedProd = selected ? selected.products : {};

                const selectedCount = (available.products as any[]).reduce((carry, pr) => {
                    carry += pr.id in selectedProd ? selectedProd[pr.id] : 0;
                    return carry;
                });

                if (selectedCount < available.max_quantity) {
                    hasNotSelected = true;
                }
            }

            if (hasNotSelected) {
                return Promise.reject({ promo_gift_not_selected: "Выберите подарки по акции" });
            }
        }
    },
    async loadBanners({ commit, rootState }) {
        let banners: Banner[] = [];
        let stories: Stori[] = [];

        if (process.client) {
            const cityId = rootState.delivery.city?.id;
            const departmentId = rootState.cart?.content ? rootState.cart.content.delivery_department_id : "";
            let requestUrl = `/info/banners?type=desktop&city_id=${cityId}&is_webp=1`;

            if (departmentId) {
                requestUrl += `&departmentId=${departmentId}`;
            }

            await this.$api
                .get(requestUrl)
                .then((resp) => {
                    banners = resp?.banners || [];
                    stories = resp?.promo_pages || [];
                })
                .catch((error) => {
                    console.warn("Ошибка при получение баннеров");
                    console.error(error);
                });
        } else {
            await new BannersStorage()
                .get()
                .then((resp) => {
                    banners = resp?.banners || [];
                    stories = resp?.promo_pages || [];
                })
                .catch((error) => {
                    console.warn("Ошибка при получение баннеров SSR");
                    console.error(error);
                });
        }

        // if (config.USE_IMAGE_RESIZER == "1" && !process.client) {
        //     // Ресайз баннеров
        //     for (const banner of banners) {
        //         resizeBannerImages(banner);
        //     }
        //
        //     // Предзагрузка в base64 размытых картинок слоёв первого баннера
        //     // if(banners.length > 0) {
        //     //     await time('Preload banner first', async() => await preloadBannerFirstImages(banners[0]));
        //     // }
        // }

        commit("setBanners", banners);
        commit("setStories", stories);
    },
};
