import { PromiseEvent, createPromiseEvent } from "../../util/promise-event";
import { Api } from "../api";
import { ServerStorage } from "../../modules/server-storage/storage";
import { ServerContext } from "./types";

let _context: ServerContext | null = null;
const _contextEvent: PromiseEvent = createPromiseEvent("_contextEvent");

export function setContext(ctx: ServerContext | null = null) {
    _context = ctx;
    _contextEvent.result();
}

export async function getContext(): Promise<ServerContext | null> {
    await _contextEvent.event;
    return _context;
}

export function getContextSync(): ServerContext | null {
    return _context;
}

async function getContextProperty(propKey: string): Promise<any | null> {
    await _contextEvent.event;
    if (!_context) {
        return null;
    }
    return getContextPropertyByValue(_context, propKey);
}

export function getContextPropertyByValue(ctx: ServerContext, propKey: string): any | null {
    if (ctx.app && ctx.app[propKey]) {
        return ctx.app[propKey];
    }
    if (ctx.ssrContext && ctx.ssrContext[propKey]) {
        return ctx.ssrContext[propKey];
    }
    return ctx[propKey] || null;
}

export async function getApi(): Promise<Api | null> {
    return await getContextProperty("$api");
}

export async function getServerStorage(): Promise<ServerStorage | null> {
    if (process.client) {
        return null;
    }
    return await getContextProperty("$serverStorage");
}

export async function getUpdateStorageFunction() {
    if (process.client) {
        return null;
    }
    return await getContextProperty("$updateScopedStorage");
}
