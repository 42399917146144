import moment from "moment/moment";
import { mapGetters, mapState } from "vuex";

export default {
    data() {
        return {
            modsStop: null,
        };
    },
    computed: {
        ...mapGetters("products", ["getStopList", "cartProducts"]),
        ...mapGetters("delivery", ["deliveryDateTime", "departments", "minDeliveryTime"]),
        ...mapGetters("cart", ["deliveryDepartmentId", "stopListProducts"]),
        ...mapState("errors", {
            error: "list",
        }),
        // Получение всех стопов в корзине
        getStops() {
            return this.getStopList.filter((item) => {
                return this.cartProducts.some((product) =>
                    product.modifiers_key
                        ? product.modifiers_key.includes(item.product_id)
                        : product.id === item.product_id
                );
            });
        },
        unavailableStopProducts() {
            return this.stopListProducts.filter((product) => product.in_stop && !product.stop_list);
        },
        // получение списка  всех стопов в корзине
        getFullStopList() {
            const stopList = [];

            this.getStops.forEach((stop) => {
                stop.stops.forEach((stopData) => {
                    stopList.push(stopData);
                });
            });
            return stopList;
        },
        stopFromToCart() {
            // продукты стопа с ошибки
            const errorStop = this.error.stop_list.products_removed || [];
            // стопы продуктов
            const stops = errorStop
                .map((product) => product.stop_list)
                .filter((stopList) => stopList && stopList.length > 0);

            const selectDate = moment.parseZone(this.deliveryDateTime);

            if (stops.length) {
                const dateFrom = this.setDateFrom(stops[0][0].date_from);
                let dateTo = moment.parseZone(stops[0][0].date_to);

                stops.forEach((stop) => {
                    const momentStops = stop.map((st) => ({
                        date_to: moment.parseZone(st.date_to),
                        date_from: this.setDateFrom(st?.date_from),
                    }));
                    for (const stop of momentStops) {
                        if (!stop.date_from) {
                            dateTo = stop.date_to;
                        } else if (
                            stop.date_from.isSameOrBefore(selectDate) &&
                            stop.date_to.isSameOrAfter(selectDate) &&
                            stop.date_to.isBefore(dateTo)
                        ) {
                            dateTo = stop.date_to;
                        }
                    }
                });

                return {
                    dateFrom,
                    dateTo,
                    textTo: `${dateTo.date()} ${this.getTextMonth(dateTo)} ${dateTo.format("HH:mm")}`,
                };
            }
            return null;
        },
    },
    methods: {
        // Проверка стопов модификаторов
        checkModsStop(groups, modifiers) {
            // стоп лист
            const allStopList = this.getStopList;

            // отсортированный список модификаторов
            const sortedMods = [];
            groups.multi.forEach((group) => {
                // модификаторы группы
                const groupMods = modifiers.filter((mod) => mod.group_id === group.id);
                // разрешено ли множественное добавление одного модификатора
                const allowStacking = group.allow_stacking_identical_dishes;
                // модификаторы не в стопе
                const groupModsNoStop = this.noStopModifiers(groupMods);
                const modsNoStopCount = groupModsNoStop.reduce((acc, mod) => acc + mod.max_number_to_choose, 0);
                // доступна ли группа модификаторов к заказу
                const isReadyToBuy = allowStacking
                    ? modsNoStopCount >= group.min_modifiers_count
                    : groupModsNoStop.length >= group.min_modifiers_count;

                sortedMods.push({
                    group: group.id,
                    mods: groupMods.map((item) => item.id),
                    isReadyToBuy,
                    allowStacking,
                });
            });

            let dateTo = null;
            let dateFrom = null;

            // список стоп-листов модификаторов
            const listStops = [];
            sortedMods.forEach((item) => {
                if (!item.isReadyToBuy && item.mods.length) {
                    item.mods.forEach((mod) => {
                        const modStopList = allStopList.find((stop) => stop.product_id === mod);
                        if (modStopList) {
                            const momentStops = modStopList.stops.map((stop) => ({
                                date_from: stop?.date_from ? moment.parseZone(stop.date_from) : stop?.date_from,
                                date_to: moment.parseZone(stop.date_to),
                            }));
                            listStops.push(...momentStops);
                        }
                    });
                }
            });
            if (listStops.length) {
                const currentDate = moment();
                // Замена null на текущее время
                listStops.forEach((item) => {
                    if (item.date_from === null) {
                        item.date_from = currentDate; // Текущее время
                    }
                });
                listStops.sort((a, b) => a.date_from - b.date_from);

                const [firstStop, ...restStops] = listStops;
                dateFrom = firstStop.date_from;
                dateTo = firstStop.date_to;

                listStops.forEach((product, index) => {
                    // Проверяем, входит ли текущая дата в интервал
                    if (currentDate.isBetween(product.date_from, product.date_to, null, "[]")) {
                        // Обновляем самую раннюю дату, если она пуста или текущая дата раньше
                        if (!dateFrom || product.date_from.isSameOrBefore(dateFrom)) {
                            dateFrom = product.date_from;
                        }
                        // Обновляем самую позднюю дату, если она пуста или текущая дата позже
                        if (!dateTo || product.date_to.isSameOrAfter(dateTo)) {
                            dateTo = product.date_to;
                        }
                    } else {
                        // Проверяем пересечение дат предыдущего запрета с текущим запретом
                        const prevProduct = listStops[index - 1];
                        if (this.datesOverlap(prevProduct, product)) {
                            // Обновляем самую раннюю дату, если она пуста или текущая дата раньше
                            if (!dateFrom || product.date_from.isSameOrBefore(dateFrom)) {
                                dateFrom = product.date_from;
                            }
                            // Обновляем самую позднюю дату, если она пуста или текущая дата позже
                            if (!dateTo || product.date_to.isSameOrAfter(dateTo)) {
                                dateTo = product.date_to;
                            }
                        }
                    }
                });

                if (dateFrom?.isSame(currentDate)) {
                    dateFrom = null;
                }
            }

            if (dateTo || dateFrom) {
                return {
                    dateTo,
                    dateFrom,
                };
            }

            return false;
        },
        noStopModifiers(mods) {
            const allStopList = this.getStopList;
            const modsNoStop = [];
            if (mods && mods.length) {
                mods.forEach((mod) => {
                    const modStopList = allStopList.find((stop) => stop.product_id === mod.id);
                    if (!modStopList) {
                        modsNoStop.push(mod);
                    } else if (modStopList && modStopList.stops.every((stop) => stop.date_from !== null)) {
                        modsNoStop.push(mod);
                    }
                });
            }
            return modsNoStop;
        },
        // Проверка date_from на null
        setDateFrom(from) {
            return from ? moment.parseZone(from) : null;
        },
        // Получение заблокированных дат
        getFullStopDates(schedule = []) {
            const disabledDates = [];
            // отделения
            const dep = this.departments.find((dep) => dep.id === this.deliveryDepartmentId);

            this.getFullStopList.forEach((stopData) => {
                const from = this.setDateFrom(stopData?.date_from);
                const to = moment.parseZone(stopData.date_to);

                const timeFrom = from ? from.format("HH:mm") : moment().format("HH:mm");
                const timeTo = to.format("HH:mm");

                const start = from || moment();

                const dates = this.checkDisableTo(start, to);

                disabledDates.push(...dates);

                // Блокировка дат в зависимости от schedule
                if (schedule.length) {
                    const scheduleTimeFrom = this.getScheduleDay(this.getNumberOfDay(from));

                    if (
                        this.stringTimeToFloat(timeFrom) < this.stringTimeToFloat(scheduleTimeFrom.time_from) ||
                        this.stringTimeToFloat(timeTo) > this.stringTimeToFloat(scheduleTimeFrom.time_to)
                    ) {
                        disabledDates.push(from.toDate());
                    }
                }
            });
            return disabledDates;
        },

        currentStops(data) {
            return this.getFullStopList.filter((stopData) => {
                const from = this.setDateFrom(stopData?.date_from);
                const to = moment.parseZone(stopData.date_to);

                const momentData = moment(data);

                const formatTo = to.format("YYYY-MM-DD");
                let formatFrom;
                if (from) {
                    formatFrom = from.format("YYYY-MM-DD");
                }

                return from
                    ? moment(formatFrom).isSameOrBefore(momentData) && moment(formatTo).isSameOrAfter(momentData)
                    : moment(formatTo).isSameOrAfter(momentData);
            });
        },
        // Получение заблокированного времени
        getTimeForStop(chosenMoment) {
            const data = chosenMoment.format("YYYY-MM-DD");
            let min = null;
            let max = null;

            const disabledTimeInDay = {
                min: null,
                max: null,
            };

            const currentStops = this.currentStops(data);

            currentStops.forEach((stop) => {
                const from = this.setDateFrom(stop?.date_from) || chosenMoment;
                const to = moment.parseZone(stop.date_to).format("YYYY-MM-DD");

                if (from.format("YYYY-MM-DD") === to) {
                    disabledTimeInDay.min = from.format("HH:mm");
                    disabledTimeInDay.max = moment.parseZone(stop.date_to).format("HH:mm");

                    if (disabledTimeInDay.min > disabledTimeInDay.max) {
                        min = from.format("HH:mm");
                    }
                } else {
                    if (from.format("YYYY-MM-DD") === data) {
                        min = chosenMoment.format("HH:mm");
                        max = from.format("HH:mm");
                    }

                    if (data === to) {
                        min = moment.parseZone(stop.date_to).format("HH:mm");
                    }
                }
            });

            min = min || "00:00";
            max = max || "23:59";
            return {
                min,
                max,
                fullStop: min === max || (min === disabledTimeInDay.min && max === disabledTimeInDay.max),
                disabledTimeInDay,
            };
        },
        checkDisableTo(start, to) {
            const dates = [];
            const startDate = start.format("YYYY-MM-DD");
            const endDate = to.format("YYYY-MM-DD");

            const minDate = moment(this.minDeliveryTime).format("YYYY-MM-DD");

            for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, "days")) {
                if (m.isBefore(minDate)) {
                    dates.push(m.toDate());
                }
                if (m.isAfter(startDate) && m.isBefore(endDate)) {
                    dates.push(m.toDate());
                } else {
                    const checkDay = m.format("YYYY-MM-DD") === startDate || m.format("YYYY-MM-DD") === endDate;

                    const checkTime = this.getTimeForStop(m);

                    const check = checkDay && !checkTime.fullStop;

                    if (!check) {
                        dates.push(m.toDate());
                    }
                }
            }

            return dates;
        },

        getCurrentStopList(product) {
            return this.getStopList.find((item) => item.product_id === product.id);
        },

        // Получение начала и конца стопа на продук с учетом наложения стопов
        getProductStopFromTo(stopList, stopMods = null) {
            let dateFrom = null;
            let dateTo = null;

            if (stopList && stopList.length) {
                const momentStops = stopList.map((stop) => ({
                    date_from: stop?.date_from ? moment.parseZone(stop.date_from) : stop?.date_from,
                    date_to: moment.parseZone(stop.date_to),
                }));

                const [firstStop, ...restStops] = momentStops;
                dateFrom = firstStop.date_from;
                dateTo = firstStop.date_to;

                for (const stop of restStops) {
                    if (stop.date_from === null) {
                        dateTo = stop.date_to;
                    } else if (dateTo.isSameOrAfter(moment.parseZone(stop.date_from), "minutes")) {
                        dateTo = stop.date_to;
                    }
                }
            }

            // проверка стопов модификаторов
            if (stopMods) {
                if (dateTo === null) {
                    dateTo = stopMods.dateTo;
                }
                if (stopMods.dateFrom) {
                    if (dateFrom === null) {
                        dateFrom = moment();
                    }
                    dateFrom = moment.max(stopMods.dateFrom, dateFrom);
                }
                dateTo = moment.max(stopMods.dateTo, dateTo);
            }

            return {
                dateFrom,
                dateTo,
            };
        },
        getStopListInfo({ product, location, stopMods }) {
            if (!product) {
                return;
            }

            let fromText = "";
            let toText = "";

            if (location === "modal") {
                const fromTo = this.getProductStopFromTo(product.stop_list);

                if (fromTo) {
                    const { dateTo } = fromTo;
                    toText = `${dateTo.date()} ${this.getTextMonth(dateTo)} ${dateTo.format("HH:mm")}`;
                }

                return toText ? `Недоступно до ${toText}` : "Недоступно";
            } else {
                const stopList = this.getCurrentStopList(product);

                if (!stopList && !stopMods) {
                    return null;
                }

                const { dateFrom, dateTo } = stopMods
                    ? this.getProductStopFromTo(stopList?.stops, stopMods)
                    : this.getProductStopFromTo(stopList?.stops);

                toText = `${dateTo.date()} ${this.getTextMonth(dateTo)} ${dateTo.format("HH:mm")}`;

                if (dateFrom) {
                    fromText = dateFrom
                        ? `${dateFrom.date()} ${this.getTextMonth(dateFrom)} ${dateFrom.format("HH:mm")}`
                        : "";
                    return `Будет недоступно с ${fromText} до ${toText}`;
                } else {
                    return `Будет доступно с ${toText}`;
                }
            }
        },

        // Вспомогательные функции

        // проверка на пересечение дат
        datesOverlap(checkDate, current) {
            return (
                checkDate.date_from.isBetween(current.date_from, current.date_to, null, "[]") ||
                checkDate.date_to.isBetween(current.date_from, current.date_to, null, "[]")
            );
        },
        getTextMonth(date) {
            const months = [
                "января",
                "февраля",
                "марта",
                "апреля",
                "мая",
                "июня",
                "июля",
                "августа",
                "сентября",
                "октября",
                "ноября",
                "декабря",
            ];

            return months[date.month()];
        },
        getNumberOfDay(dateStart) {
            return (new Date(dateStart).getDay() + 6) % 7;
        },
        stringTimeToFloat(startTime) {
            const [h, m] = startTime.split(":").map(Number);
            const totalMinutes = h * 60 + m;
            return totalMinutes / 60;
        },
    },
};
