import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _39f9c600 = () => interopDefault(import('../pages/bonus_card.vue' /* webpackChunkName: "pages/bonus_card" */))
const _6ac2ceaf = () => interopDefault(import('../pages/certs.vue' /* webpackChunkName: "pages/certs" */))
const _6096be81 = () => interopDefault(import('../pages/cloudpayment.vue' /* webpackChunkName: "pages/cloudpayment" */))
const _7d9598e3 = () => interopDefault(import('../pages/fiscal_check_getter.vue' /* webpackChunkName: "pages/fiscal_check_getter" */))
const _75dd4f9e = () => interopDefault(import('../pages/free-roll.vue' /* webpackChunkName: "pages/free-roll" */))
const _7d71e3a1 = () => interopDefault(import('../pages/guestbook.vue' /* webpackChunkName: "pages/guestbook" */))
const _7e6ef458 = () => interopDefault(import('../pages/mobile_app.vue' /* webpackChunkName: "pages/mobile_app" */))
const _f7caf70c = () => interopDefault(import('../pages/new_city.vue' /* webpackChunkName: "pages/new_city" */))
const _35af12da = () => interopDefault(import('../pages/o-pravilnom-podhode-k-prigotovleniyu-rollov.vue' /* webpackChunkName: "pages/o-pravilnom-podhode-k-prigotovleniyu-rollov" */))
const _198afdb8 = () => interopDefault(import('../pages/pages.vue' /* webpackChunkName: "pages/pages" */))
const _a37f59fa = () => interopDefault(import('../pages/pizza_man.vue' /* webpackChunkName: "pages/pizza_man" */))
const _2ca52ab8 = () => interopDefault(import('../pages/rest.vue' /* webpackChunkName: "pages/rest" */))
const _103f4b95 = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _140a0890 = () => interopDefault(import('../pages/tetris_game.vue' /* webpackChunkName: "pages/tetris_game" */))
const _438248c0 = () => interopDefault(import('../pages/api/storages/index.vue' /* webpackChunkName: "pages/api/storages/index" */))
const _3bd7d158 = () => interopDefault(import('../pages/user/bonus_card.vue' /* webpackChunkName: "pages/user/bonus_card" */))
const _891375e2 = () => interopDefault(import('../pages/user/current_orders.vue' /* webpackChunkName: "pages/user/current_orders" */))
const _8c7effd6 = () => interopDefault(import('../pages/user/mystery-shoper.vue' /* webpackChunkName: "pages/user/mystery-shoper" */))
const _828c0c60 = () => interopDefault(import('../pages/user/orders-history.vue' /* webpackChunkName: "pages/user/orders-history" */))
const _3b744496 = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _98e42f82 = () => interopDefault(import('../pages/api/catalog/urls.vue' /* webpackChunkName: "pages/api/catalog/urls" */))
const _19e43da9 = () => interopDefault(import('../pages/api/storages/ip.vue' /* webpackChunkName: "pages/api/storages/ip" */))
const _0fd593e6 = () => interopDefault(import('../pages/api/storages/stats.vue' /* webpackChunkName: "pages/api/storages/stats" */))
const _279e3b74 = () => interopDefault(import('../pages/bonus_card_wallet/_phone.vue' /* webpackChunkName: "pages/bonus_card_wallet/_phone" */))
const _d905a84a = () => interopDefault(import('../pages/gift_cert/_encoded.vue' /* webpackChunkName: "pages/gift_cert/_encoded" */))
const _222174ab = () => interopDefault(import('../pages/landing_preview/_id.vue' /* webpackChunkName: "pages/landing_preview/_id" */))
const _77e0520a = () => interopDefault(import('../pages/order/_step.vue' /* webpackChunkName: "pages/order/_step" */))
const _7e3d4cba = () => interopDefault(import('../pages/supply_request/_id.vue' /* webpackChunkName: "pages/supply_request/_id" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3fdf212d = () => interopDefault(import('../layouts/error.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/bonus_card",
    component: _39f9c600,
    name: "bonus_card"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/certs",
    component: _6ac2ceaf,
    name: "certs"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/cloudpayment",
    component: _6096be81,
    name: "cloudpayment"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/fiscal_check_getter",
    component: _7d9598e3,
    name: "fiscal_check_getter"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/free-roll",
    component: _75dd4f9e,
    name: "free-roll"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/guestbook",
    component: _7d71e3a1,
    name: "guestbook"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/mobile_app",
    component: _7e6ef458,
    name: "mobile_app"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/new_city",
    component: _f7caf70c,
    name: "new_city"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/o-pravilnom-podhode-k-prigotovleniyu-rollov",
    component: _35af12da,
    name: "o-pravilnom-podhode-k-prigotovleniyu-rollov"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/pages",
    component: _198afdb8,
    name: "pages"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/pizza_man",
    component: _a37f59fa,
    name: "pizza_man"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/rest",
    component: _2ca52ab8,
    name: "rest"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/sitemap",
    component: _103f4b95,
    name: "sitemap"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/tetris_game",
    component: _140a0890,
    name: "tetris_game"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/api/storages",
    component: _438248c0,
    name: "api-storages"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/user/bonus_card",
    component: _3bd7d158,
    name: "user-bonus_card"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/user/current_orders",
    component: _891375e2,
    name: "user-current_orders"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/user/mystery-shoper",
    component: _8c7effd6,
    name: "user-mystery-shoper"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/user/orders-history",
    component: _828c0c60,
    name: "user-orders-history"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/user/profile",
    component: _3b744496,
    name: "user-profile"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/api/catalog/urls",
    component: _98e42f82,
    name: "api-catalog-urls"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/api/storages/ip",
    component: _19e43da9,
    name: "api-storages-ip"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/api/storages/stats",
    component: _0fd593e6,
    name: "api-storages-stats"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/bonus_card_wallet/:phone?",
    component: _279e3b74,
    name: "bonus_card_wallet-phone"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/gift_cert/:encoded?",
    component: _d905a84a,
    name: "gift_cert-encoded"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/landing_preview/:id?",
    component: _222174ab,
    name: "landing_preview-id"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/order/:step?",
    component: _77e0520a,
    name: "order-step"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/supply_request/:id?",
    component: _7e3d4cba,
    name: "supply_request-id"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/:cityName(|pervouralsk|tagil|kamensk|chelyabinsk|tyumen|pyshma|bereza|surgut|revda|ryazan|verkhnyaya-salda|orenburg|sysert|polevskoy|tobolsk)?/fiscal_check/:order_id/:fn/:fd/:fp",
    component: _7d9598e3,
    name: "fiscal_check"
  }, {
    path: "*",
    component: _3fdf212d,
    name: "error_404"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
