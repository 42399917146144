import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters("modals", ["getOpenedModal"]),
        addressOpen() {
            if (this.getOpenedModal) {
                return this.getOpenedModal.modalName === "DeliveryMap";
            }
            return false;
        },
    },
    methods: {
        // Drag and drop product card
        initDragAndDrop(event, scrollInit) {
            this.innerWrap = document.querySelector(".product-info__inner");
            if (
                event.target.className === "product-popup-close__img" ||
                event.target.className === "product-popup-close-button__img" ||
                event.target.className === "product-popup-close"
            ) {
                console.log("** clearing productPopup on ProductPopup close");
                this.$store.dispatch("productPopup/clearState");
                this.$store.commit("productPopup/setModifiersButtonClicked", false);
                document.body.classList.remove("open-modal-default");
            }
            if (this.innerWrap.scrollTop > 0) {
                return;
            }
            if (this.$screen.width > 480) {
                return;
            }

            this.event = event || this.event;
            this.productPopupEl = document.querySelector(".js-popup-drag");
            this.productPopupEl.classList.remove("animate");

            if (this.$device.isIos && !this.hasScrollEvent && this.innerWrap.scrollTop > 0) {
                this.innerWrap.addEventListener("scroll", this.onElScroll);
                this.hasScrollEvent = true;
            }

            if (this.innerWrap.scrollTop == 0 && this.$device.isMobile && !this.$device.isIos) {
                this.innerWrap.scrollTop = 0.4;
            }

            this.dragInProcess = true;
            document.addEventListener("touchmove", this.onTouchMove);

            if (!scrollInit) {
                this.popupTopScroll = this.innerWrap.scrollTop || 0;
            }

            let iosScrollTop = 0;
            if (this.innerWrap.scrollTop < 0) {
                iosScrollTop = this.innerWrap.scrollTop;
                this.innerWrap.scrollTop = 0;
            }

            this.shiftY =
                this.event.targetTouches[0].clientY -
                this.productPopupEl.getBoundingClientRect().top +
                this.popupTopScroll +
                iosScrollTop;

            this.moveAt(this.event.targetTouches[0].clientY);

            this.productPopupEl.ondragstart = () => {
                return false;
            };
        },
        onElScroll(event) {
            if (this.innerWrap.scrollTop < 0.4) {
                this.innerWrap.style["overflow-y"] = "hidden";
            }
        },
        onTouchMove(event) {
            if (this.innerWrap.scrollTop <= 0.4) {
                this.moveAt(event.targetTouches[0].clientY);
            }
        },
        moveAt(clientY) {
            this.indent = clientY - this.shiftY;
            if (this.indent > 0) {
                this.productPopupEl.style.transform = `translateY(${this.indent}px)`;
                this.innerWrap.style["overflow-y"] = "hidden";
            } else {
                this.productPopupEl.style.transform = "unset";
            }
        },
        checkModifiersBtn() {
            if (this.isModifiersButtonClicked) {
                setTimeout(() => {
                    const cartBlock = document.querySelector(".product-in-cart__link");
                    cartBlock.scrollIntoView({ behavior: "smooth" });
                }, 1000);
            }
        },
        destroyDragAndDrop() {
            this.hasScrollEvent = false;
            this.event = null;
            this.popupTopScroll = 0;
            if (!this.dragInProcess) {
                return;
            }
            this.innerWrap.style["overflow-y"] = "auto";

            if (this.indent > 120) {
                this.closeMobileAnimation();
            } else {
                this.cancelCloseAnimation();
            }

            this.dragInProcess = false;
            this.innerWrap.removeEventListener("scroll", this.onElScroll);
            document.removeEventListener("touchmove", this.onTouchMove);
        },
        closeMobileAnimation(isHistory) {
            setTimeout(() => {
                document.body.classList.remove("modal-open");
                if (this.$device.isIos) {
                    enableBodyScroll(this.innerWrap);
                }
            }, 0);
            this.productPopupEl = document.querySelector(".js-popup-drag");
            this.productPopupEl.classList.add("animate");
            this.productPopupEl.addEventListener("transitionend", this.fullClose);
            this.productPopupEl.style.transform = "translateY(100vh)";
            this.productPopupEl.style.opacity = "0";
            this.close(isHistory);
        },
        cancelCloseAnimation() {
            const vm = this;
            function removeAnimation() {
                vm.productPopupEl.classList.remove("animate");
            }
            this.productPopupEl.classList.add("animate");
            this.productPopupEl.style.transform = "unset";
            this.productPopupEl.removeEventListener("transitionend", removeAnimation);
        },
        setOnCloseFunction(f) {
            this.onClose = f;
        },
        // resetState() {
        //     this.modifier = null;
        // },
        onShow() {
            if (!process.client) {
                return;
            }

            if (window.innerWidth > 480) {
                // Открытие хэдера для декстопов/планшетов
                const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
                this.styleModal = document.createElement("style");
                window.scrollBy(0, 1);
                this.styleModal.innerHTML = `.modal-open {padding-right: ${scrollBarWidth}px;overflow-y: hidden;} .modal-open .wrap-top-line {padding-right: ${scrollBarWidth}px;}`;

                document.head.appendChild(this.styleModal);
                // document.body.parentNode.insertBefore(, document.body);
            }
            document.body.classList.add("open-modal-default");
            document.body.classList.add("modal-open");

            this.$nuxt.$on("Escape-pressed", () => {
                if (this.isShow) {
                    this.close();
                }
            });
            this.$nuxt.$on("Enter-pressed", () => {
                if (this.product) {
                    this.addProduct();
                }
            });
            this.$nuxt.$on("Backspace-pressed", () => {
                if (this.product && !this.addressOpen) {
                    this.removeProduct();
                }
            });

            this.$nextTick(() => {
                this.isInvisible = false;

                if (this.$device.isMobile && !this.$device.isIos) {
                    this.innerWrap = document.querySelector(".js-product-popup__inner");
                    this.innerWrap.scrollTop = 0.4;
                }
                if (this.$device.isIos) {
                    this.innerWrap = document.querySelector(".js-product-popup__inner");
                    disableBodyScroll(this.innerWrap);
                }
            });
            window.addEventListener("popstate", this.closeWithoutHistory);
            window.history.pushState({ productName: "productName" }, "Name", this.$route.path);
            this.checkModifiersBtn();
        },
    },
};
