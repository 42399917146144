const IS_DEBUG_PROMISE_EVENTS = false;

export interface PromiseEvent {
    event: Promise<any>;
    result: () => any;
}

function debugLog(promiseName: string, ...pieces) {
    if (!IS_DEBUG_PROMISE_EVENTS) {
        return;
    }
    console.log("[PROMISE]", promiseName, "::", ...pieces);
}

export function createPromiseEvent(logName: string): PromiseEvent {
    const uniqName = `prm_${Date.now()}_${logName}`;
    let notResolvedInterval: any = null;

    if (IS_DEBUG_PROMISE_EVENTS) {
        notResolvedInterval = setInterval(() => {
            debugLog(uniqName, "still not resolved!");
        }, 15000);
    }

    let resolver = (v?: any) => {};

    const p = new Promise((resolve) => {
        resolver = (v?: any) => {
            if (notResolvedInterval) {
                clearInterval(notResolvedInterval);
            }
            debugLog(uniqName, "resolved");
            resolve(v);
        };
    });

    debugLog(uniqName, "created");
    return {
        event: p,
        result: resolver,
    };
}
