//
//
//
//
//
//
//
//
//

export default {
    name: "Shimmer",
    props: {
        borderRadius: {
            type: [Number, String],
            default: 0,
        },
        color: {
            type: String,
            default: "#F2F2F2",
        },
    },
    computed: {
        shimmerStyle() {
            const radius = Number.isSafeInteger(this.borderRadius) ? `${this.borderRadius}px` : this.borderRadius;
            return {
                background: this.color,
                borderRadius: radius,
            };
        },
    },
};
