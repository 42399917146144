//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { plural, throttle } from "~/util/helpers";
import CustomProgressBar from "~/components/common/CustomProgressBar.vue";
import BurgerClose from "~/components/icons/BurgerClose.vue";

export default {
    name: "ProductScore",
    components: { BurgerClose, CustomProgressBar },
    props: {
        product: { required: true },
        showFullInfo: {
            type: Boolean,
            default: true,
        },
        view: {
            type: String,
            default: null,
            validator(value) {
                return ["catalog", "popup", "details"].includes(value);
            },
        },
    },
    data() {
        return {
            scoreBlock: false,
            isHaveMouse: false,
        };
    },
    computed: {
        showScoreBtn() {
            return this.isHaveMouse ? true : !this.scoreBlock;
        },
        averageScore() {
            const score = Math.round(this.product.avg_score * 10) / 10;
            return (score ^ 0) === score && score !== 10 ? `${score},0` : score.toString();
        },
        showScore() {
            return this.product.scores_count > 10;
        },
        averageScoreColor() {
            if (this.product.avg_score < 8 && this.product.avg_score >= 7) {
                return "yellow";
            }
            if (this.product.avg_score < 7) {
                return "red";
            }
            return "";
        },
        scorePercentBest() {
            return 100 - this.scorePercentGood - this.scorePercentBad;
        },
        scorePercentGood() {
            return Math.floor(this.product.scores[1] / (this.product.scores_count / 100));
        },
        scorePercentBad() {
            return Math.floor(this.product.scores[0] / (this.product.scores_count / 100));
        },
        pluralizedScoresCount() {
            return plural(this.product.scores_count, "голоса", "голосов", "голосов");
        },
    },
    mounted() {
        this.isHaveMouse = !window.matchMedia("(max-width: 1024px)").matches;
        window.addEventListener("resize", throttle(this.checkScreenSize, 300));
    },
    methods: {
        showScoreBlock() {
            if (this.isHaveMouse) {
                this.scoreBlock = true;
            }
        },
        hideScoreBlock() {
            if (this.isHaveMouse) {
                this.scoreBlock = false;
            }
        },
        toggleScoreBlock() {
            console.log(" this.scoreBlock", this.scoreBlock);
            this.scoreBlock = !this.scoreBlock;
            console.log(" this.scoreBlock", this.scoreBlock);
        },
        checkScreenSize() {
            this.isHaveMouse = !window.matchMedia("(max-width: 1024px)").matches;
        },
    },
};
