//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
    name: "ProductModifiersGroups",
    props: ["productCart"],
    data() {
        return {
            modifierMain: "",
            modifierSecondary: "",
        };
    },
    computed: {
        ...mapGetters("products", ["getProductFullInfo"]),
        productFullInfo() {
            return this.getProductFullInfo(this.productCart.id);
        },
    },
    mounted() {
        this.setModifiers();
    },
    methods: {
        setModifiers() {
            if (this.productCart.sel_modifiers) {
                const tempArr = [];
                this.productFullInfo.available_modifiers.forEach((available_modifier) => {
                    this.productCart.sel_modifiers.forEach((sel_modifier) => {
                        if (available_modifier.id === sel_modifier.id) {
                            if (available_modifier.group_id === 0) {
                                this.modifierMain = available_modifier.name;
                            } else {
                                tempArr.push(available_modifier.name);
                            }
                        }
                    });
                });
                this.modifierSecondary = tempArr.join(", ");
            }
        },
    },
};
