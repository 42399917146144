import { ActionTree, GetterTree, MutationTree } from "vuex";
import { Context } from "@nuxt/types/app";
import VueRouter, { Route } from "vue-router";
import { NuxtCookies } from "cookie-universal-nuxt";
import Vue from "vue";
import { getContextPropertyByValue, setContext } from "../service/context-keeper";
import { ClientInitializedEvent, RootState } from "../store_types/index.types";
import { getIpFromNuxtContext } from "../util/ip-helpers";
import { DEFAULT_CITY_ID, DELIVERY_TYPE } from "../util/types";
import { ServerContext } from "../service/context-keeper/types";
import cityLinksHelper from "../util/city-links-helper";
import { checkMobileBrowser, debounce, time } from "~/util/helpers";
import config from "~/config";
import { createPromiseEvent } from "~/util/promise-event";

import localStorageHelper from "~/service/local-storage-helper/index";
import { RequestUtmTags, getRequestUtmTags } from "~/util/utm-helper";
import { applyEnvConfig } from "~/util/envHelpers";

const _clientInitializedObservable: ClientInitializedEvent = Vue.observable({
    promiseEvent: process.client ? createPromiseEvent("_clientInitializedObservable") : null,
    isInitializing: false,
    isInitialized: false,
});

// @ts-ignore
export const state: () => RootState = () => ({
    encodedIP: null,
    uuid: null,
    siteId: null,

    isAfterInitDone: false,
    isServerInitialized: false,
    ip: null,
    isOnline: false,
    gaClientId: null,
    isMobileBrowser: false,
    utmTags: null,
    // newYearAnimation: true,
});

export const getters: GetterTree<RootState, RootState> = {
    cart(state) {
        return state.cart.content || {};
    },
    clientInitializedEvent(): Promise<any> | null {
        if (_clientInitializedObservable.promiseEvent) {
            return _clientInitializedObservable.promiseEvent.event;
        }
        return null;
    },
    isClientInitialized(): boolean {
        return _clientInitializedObservable.isInitialized;
    },
    /* auth(state) {
        return state.auth;
    }, */
};

export const mutations: MutationTree<RootState> = {
    setUUID(state, uuid: string) {
        state.uuid = uuid;
    },
    setSiteId(state, value: string) {
        state.siteId = value;
    },
    setServerInitialized(state, value: boolean) {
        state.isServerInitialized = value;
    },
    setAfterInitDone(state, value: boolean) {
        state.isAfterInitDone = value;
    },
    setIsOnline(state, value: boolean) {
        state.isOnline = value;
    },
    setGAClientId(state, value) {
        state.gaClientId = value;
    },
    setIsMobileBrowser(state, value: boolean) {
        state.isMobileBrowser = value;
    },
    setUtmTags(state, value: RequestUtmTags | null) {
        state.utmTags = value;
    },
    // toogleNewYearAnimation(state, show) {
    //     state.newYearAnimation = show;
    // },
    setEncodedIP(state, value: string | null) {
        state.encodedIP = value;
    },
    setIp(state, value: string | null) {
        state.ip = value;
    },
};

export const actions: ActionTree<RootState, RootState> = {
    // SSR-инициализация Vuex store
    async nuxtServerInit({ state, commit, dispatch, rootGetters }, context: Context) {
        await time("nuxtServerInit", async () => {
            commit("setIsMobileBrowser", checkMobileBrowser(context.req.headers["user-agent"]));
            commit("account/changeMobileBanner", (this.$cookies.get("appBanner") ?? true) && state.isMobileBrowser);

            commit("setIsOnline", false);
            setContext(context);

            if (state.isServerInitialized) {
                return;
            }

            commit("setSiteId", process.env.SITE_ID);

            await Promise.all([
                dispatch("findUtmTags", context),
                dispatch("fillAuthCredentials"),
                dispatch("metrika/detectParamsABValue", null, { root: true }),
            ]);

            const isApiRoute = context.route.fullPath.includes("/api/storages");

            await time("cities-cart-eco Promise.all", async () => {
                await Promise.all([
                    time(
                        "delivery/loadCities",
                        async () => await dispatch("delivery/loadCities", null, { root: true })
                    ),
                    ...(!isApiRoute
                        ? [
                              // time('cart/loadCart', async() => await dispatch('cart/loadCart', null, { root: true })),
                              // time('cart/getEcoAvailable', async() => await dispatch('cart/getEcoAvailable', { root: true })),
                          ]
                        : []),
                ]);
            });

            if (!isApiRoute) {
                await time("detectCityId", async () => await dispatch("detectCityId", context));
            }

            // dispatch("fillCityMetaData", context);

            await time("load-catalog-urls-banners Promise.all", async () => {
                await Promise.all([
                    time(
                        "products/loadCatalog",
                        async () => await dispatch("products/loadCatalog", { context }, { root: true })
                    ),
                    time("products/loadUrls", async () => await dispatch("products/loadUrls", null, { root: true })),
                    time("promo/loadBanners", async () => await dispatch("promo/loadBanners", null, { root: true })),
                    time("getCatalogView", async () => await dispatch("getCatalogView", context)),
                ]);
            });

            const serverTime = new Date();

            commit("delivery/setServerTime", serverTime.getTime());
            commit("setServerInitialized", true);
        });
    },
    async afterNuxtServerInit({ state, commit, dispatch }, context: Context) {
        const extendedContext = context as Context & { to_route?: Route };

        if (state.isAfterInitDone) {
            return;
        }
        commit("setAfterInitDone", true);

        // Всё, что должно выполнится после nuxtServerInit и подгрузки catalogRouter.fill()
        await Promise.all([
            dispatch("meta/onRouteChangeMeta", extendedContext.to_route || undefined, { root: true }),
            dispatch("products/findCurrentViewCategoryId", extendedContext.to_route, { root: true }),
        ]);
    },

    async nuxtClientInit({ commit, dispatch }): Promise<void | null> {
        const clientTime: number = new Date().getTime();
        const cookies: NuxtCookies = this.$cookies;

        if (!process.client) {
            return null;
        }

        if (_clientInitializedObservable.isInitializing || _clientInitializedObservable.isInitialized) {
            return null;
        }

        _clientInitializedObservable.isInitializing = true;

        await Promise.all([
            dispatch("sendUtmTags"),
            dispatch("delivery/setCity", cookies.get("city_id")),
            dispatch("account/fetchProfile").catch((error) => {
                console.warn("Ошибка получения профиля");
                console.error(error);
            }),
        ]);

        commit("delivery/setClientTime", clientTime);
        await dispatch("delivery/detectDesiredTime");

        if (_clientInitializedObservable.promiseEvent) {
            _clientInitializedObservable.promiseEvent.result();
        }

        _clientInitializedObservable.isInitializing = false;
        _clientInitializedObservable.isInitialized = true;
    },
    findUtmTags({ commit }, context: Context) {
        commit("setUtmTags", getRequestUtmTags(context.req?.originalUrl));
    },
    async sendUtmTags({ state, commit }) {
        if (!state.utmTags) {
            return;
        }

        await this.$postCartQueue.add("cart/setUtmTags", state.utmTags);
        commit("setUtmTags", null);
    },

    fillAuthCredentials({ commit }) {
        const uuid = this.$api.getUid();
        const auth = this.$api.getAuth();

        commit("setUUID", uuid, { root: true });
        if (auth) {
            commit("auth/setToken", auth, { root: true });
        }
    },
    detectCityId({ state, getters, commit, dispatch }, context: ServerContext) {
        let cityId: string | number = DEFAULT_CITY_ID;

        const cookiesCityId = context.$cookies.get("city_id");
        const ip = getIpFromNuxtContext(context);
        commit("setIp", ip);

        if (cookiesCityId) {
            cityId = cookiesCityId;
        } else if (ip) {
            cityId = String(DEFAULT_CITY_ID);
        }

        // Назначили свойство 'to_route' в хуке роутера
        // @ts-ignore
        if (context.to_route) {
            /* eslint-disable-line dot-notation */
            const route: Route = context["to_route"]; /* eslint-disable-line dot-notation */
            const routeCity = cityLinksHelper.getCityItemFromPath(route.path);

            if (routeCity) {
                cityId = routeCity.id;
            }
        }

        dispatch("delivery/setCityId", cityId);
    },
    async getCatalogView({ dispatch }, context: Context) {
        const catalogView = {
            view: context.app.$cookies.get("viewCatalog"),
            screenWidth: context.app.$cookies.get("userScreenWidth"),
        };

        if (catalogView?.view) {
            await dispatch("products/changeViewCatalog", catalogView, { root: true });
        }
    },
    showModalOnOffline({ dispatch }) {
        dispatch(
            "modals/openModal",
            {
                modalName: "InfoModal",
                modalData: {
                    text: "Отсутствует доступ к сети Интернет",
                    confirmation: false,
                },
            },
            { root: true }
        );
    },
};
