import { AbstractStorage } from "../abstract-storage/index";

export class CitiesStorage extends AbstractStorage {
    protected getKey() {
        return "_cities_list";
    }

    protected getLogName() {
        return "CITIES_STORAGE";
    }

    protected async apiRequest() {
        const resp = await this.getApi().get("cities", { displayWorkTime: true });
        return resp || [];
    }
}
