//
//
//

export default {
    name: "AppOnlineChecker",
    watch: {
        "$nuxt.isOnline"(newVal, oldVal) {
            this.checkIsOnline();

            if (newVal != oldVal && newVal == true) {
                this.onBecomeOnline();
            }
        },
    },
    mounted() {
        this.checkIsOnline();
    },
    methods: {
        checkIsOnline() {
            this.$store.commit("setIsOnline", this.$nuxt.isOnline);
        },
        async onBecomeOnline() {
            await this.$store.dispatch("cart/loadCart", true);
        },
    },
};
