//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from "vuex";
import { setClientInterval } from "~/util/interval-helpers";

export default {
    name: "ProductNoticement",
    data() {
        return {
            timeRepeater: null,
            toggler: false,
            closeDelay: null,
        };
    },
    computed: {
        ...mapState("products", ["noticedProduct"]),
        mobileWidth() {
            if (process.client) {
                return window.innerWidth <= 480;
            }

            return false;
        },
        photoUrl() {
            return this.noticedProduct.photo_small || this.noticedProduct.photo;
        },
    },
    watch: {
        noticedProduct(nVal, oVal) {
            if (nVal) {
                this.openNotification();
                this.toggler = false;
                setTimeout(() => {
                    this.toggler = true;
                });
                if (oVal) {
                    clearTimeout(this.closeDelay);
                }
                this.closeDelay = setTimeout(() => {
                    this.makeNotification(null);
                }, 3000);
            } else {
                this.closeNotification();
                this.toggler = false;
            }
        },
    },
    beforeDestroy() {
        this.closeNotification();
    },
    methods: {
        ...mapMutations("products", ["makeNotification"]),
        setPosition() {
            if (this.noticedProduct && this.toggler && this.$refs.container) {
                const cartPriceContainer = document.querySelector(".cart");
                if (cartPriceContainer) {
                    const initTop =
                        cartPriceContainer?.offsetTop +
                        cartPriceContainer?.offsetHeight / 2 -
                        this.$refs.container?.offsetHeight / 2;
                    const initLeft = cartPriceContainer?.offsetLeft - this.$refs.container?.offsetWidth - 20;
                    this.$refs.container.style.top = initTop + "px";
                    this.$refs.container.style.left = initLeft + "px";
                }
            } else {
                this.closeNotification();
            }
        },
        openNotification() {
            this.timeRepeater = setClientInterval("ProductNoticement_timeRepeater", this.setPosition, 1000 / 60);
        },
        closeNotification() {
            clearInterval(this.timeRepeater);
        },
    },
};
