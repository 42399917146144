function stringToPath(path): string[] {
    if (typeof path !== "string") {
        return path;
    }

    const output: string[] = [];

    for (const item of path.split(".")) {
        for (const key of item.split(/\[([^}]+)\]/g)) {
            if (key.length > 0) {
                output.push(key);
            }
        }
    }
    return output;
}

export function setByPath(obj, path, value) {
    path = stringToPath(path);
    let current = obj;

    for (let i = 0; i < path.length; i++) {
        const key = path[i];
        if (current[key] === undefined) {
            current[key] = {};
        }
        if (i == path.length - 1) {
            current[key] = value;
        }
        current = current[key];
    }
}

export function getByPath(obj, path, def = undefined) {
    path = stringToPath(path);
    let current = obj;

    for (let i = 0; i < path.length; i++) {
        const key = path[i];
        if (!current[key]) {
            return def;
        }
        current = current[key];
    }

    return current;
}

export function unsetByPath(obj, path) {
    path = stringToPath(path);
    let current = obj;

    for (let i = 0; i < path.length; i++) {
        const key = path[i];
        if (current[key] === undefined) {
            current[key] = {};
        }
        if (i == path.length - 1) {
            delete current[key];
        }
        current = current[key];
    }
}

export function hasByPath(obj, path) {
    path = stringToPath(path);
    let current = obj;

    for (let i = 0; i < path.length; i++) {
        const key = path[i];
        if (current[key] === undefined) {
            return false;
        }
        current = current[key];
    }

    return true;
}
