import { ActionTree, GetterTree, MutationTree } from "vuex";
import { EcommerceYandex, canUseEcommerce } from "../service/ecommerce";
import { RootState } from "~/store_types/index.types";
import { MetrikaState } from "~/store_types/metrika.types";

export const state: () => MetrikaState = () => ({
    traceCharityProduct: false,
    paramsAB: "B",
    paramsXY: "",
});

export const getters: GetterTree<MetrikaState, RootState> = {};

export const mutations: MutationTree<MetrikaState> = {
    setTraceCharityProduct(state, payload) {
        state.traceCharityProduct = payload;
    },
    setParamsAB(state, value) {
        state.paramsAB = value;
    },
    setParamsXY(state, value) {
        state.paramsXY = value;
    },
};

export const actions: ActionTree<MetrikaState, RootState> = {
    sendCharityProductGoal({ state, commit, rootGetters }) {
        const charityInCart = rootGetters["cart/productsInCart"].some((product) => product.id == 8082);

        if (state.traceCharityProduct && charityInCart) {
            EcommerceYandex.reachGoal("anketakorzina");
        }

        commit("setTraceCharityProduct", false);
    },
    detectParamsABValue({ commit }) {
        // Согласовать с СУ: ApiCart::getABParameter()
        let value = "A";

        const uuid = this.$api.getUid();
        if (uuid) {
            const firstChar = uuid.substr(0, 1);
            const n = parseInt(firstChar, 16);
            value = n % 2 === 0 ? "A" : "B";
        }

        commit("setParamsAB", value);
    },
};
