import { render, staticRenderFns } from "./Sort.vue?vue&type=template&id=282339c8&scoped=true&"
import script from "./Sort.vue?vue&type=script&lang=js&"
export * from "./Sort.vue?vue&type=script&lang=js&"
import style0 from "./Sort.vue?vue&type=style&index=0&id=282339c8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "282339c8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SortIcon: require('/usr/src/app/components/icons/SortIcon.vue').default,Arrow: require('/usr/src/app/components/icons/Arrow.vue').default,SortIconDown: require('/usr/src/app/components/icons/SortIconDown.vue').default,SortIconUp: require('/usr/src/app/components/icons/SortIconUp.vue').default,NewIcon: require('/usr/src/app/components/icons/NewIcon.vue').default,Like: require('/usr/src/app/components/icons/Like.vue').default,StarIcon: require('/usr/src/app/components/icons/StarIcon.vue').default})
