//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from "vuex";
import PaymentPay from "~/components/icons/PaymentPay.vue";
import PaymentSpb from "~/components/icons/PaymentSpb.vue";
import Logo from "~/components/icons/Logo.vue";
import PaymentMir from "~/components/icons/PaymentMir.vue";
import PaymentMastercard from "~/components/icons/PaymetnMastercard.vue";
import PaymentVisa from "~/components/icons/PaymetnVisa.vue";
import Container from "~/components/common/Container.vue";
import AppQr from "~/components/icons/AppQr.vue";
// import LogoNg from "~/components/icons/LogoNg.vue";

export default {
    name: "FooterSection",
    components: {
        Logo,
        AppQr,
        Container,
        PaymentVisa,
        PaymentMastercard,
        PaymentMir,
        PaymentSpb,
        PaymentPay,
    },
    props: ["newCity"],
    computed: {
        ...mapState("delivery", ["city"]),
        ...mapGetters("delivery", ["selfDeliveryDepartments"]),
        phones() {
            if (!this.city) {
                return [];
            }
            return {
                hotline: [...this.city.phonesHotline].filter((p) => !!p),
                delivery: [...this.city.phonesUrban, ...this.city.phonesMobile].filter((p) => !!p),
            };
        },
        isNewCityPage() {
            return this.$route.name === "new_city";
        },
        downloadLinksShow() {
            if (this.isMobile) {
                return {
                    ios: this.iOS,
                    android: this.isMobile && !this.iOS,
                };
            }

            return {
                ios: true,
                android: true,
            };
        },
        iOS() {
            return this.$device.isIos;
        },
        isMobile() {
            return this.$device.isMobile;
        },
    },
    methods: {
        ...mapActions("account", ["showPersonalDataProcessingAgreement"]),
        downloadApp() {
            if (this.isMobile) {
                const linkStore = this.iOS
                    ? "https://apps.apple.com/ru/app/%D1%81%D1%83%D1%88%D0%BA%D0%BE%D1%84-%D0%B8-%D0%B4%D0%B5%D0%BB%D1%8C-%D0%BF%D0%B5%D1%81%D1%82%D0%BE/id1434266039"
                    : "https://play.google.com/store/apps/details?id=ru.sushkof.android&hl=ru";
                window.open(linkStore, "_blank");
            }
            this.$router.push("/mobile_app/");
        },
    },
};
