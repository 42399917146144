//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations, mapState } from "vuex";
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import NuxtCityLink from "~/components/common/NuxtCityLink.vue";
import { debounce } from "~/util/helpers";
import cityLinksHelper from "~/util/city-links-helper";
import { isIndexPage } from "~/service/catalog-router/helpers";
import SearchIcon from "~/components/icons/SearchIcon.vue";
import CrossSmall from "~/components/icons/CrossSmall.vue";
import Arrow from "~/components/icons/Arrow.vue";
import Container from "~/components/common/Container.vue";
import BurgerClose from "~/components/icons/BurgerClose.vue";
import Shimmer from "~/components/common/Shimmer.vue";
import BeforeSearch from "~/components/icons/BeforeSearch.vue";
import EmptySearch from "~/components/icons/EmptySearch.vue";
import { sendYandexMetric } from "~/service/ecommerce";

export default {
    name: "Search",
    components: {
        EmptySearch,
        BeforeSearch,
        Shimmer,
        BurgerClose,
        Container,
        Arrow,
        CrossSmall,
        SearchIcon,
        NuxtCityLink,
    },
    data() {
        return {
            inputFocus: false,
            haveInputValue: false,
            searchProducts: [],
            searchWrapElem: null,
            waitSearch: false,
            searchDebounce: null,
            stepImg: 1,
            windowSize: null,

            isShowTooltip: false,
            tooltipTrigger: null,
            tooltipText: "",
        };
    },
    computed: {
        ...mapState("products", ["products"]),
        ...mapGetters("products", ["getCategories", "getSearchHistory"]),
        ...mapState("account", ["showMobileAppBanner"]),
        searchCategories() {
            const allCategories = [];
            this.searchProducts.forEach((product) => {
                product.categories_ids.forEach((id) => allCategories.push(id));
            });
            const uniqCategories = [...new Set(allCategories)];
            return this.getCategories.filter((category) => uniqCategories.includes(category.id));
        },
        searchTags() {
            const set = new Set();
            this.searchCategories.forEach((category) => {
                category.tags.reduce((s, e) => s.add(e), set);
            });

            return set;
        },
        allTags() {
            const set = new Set();
            this.getCategories.forEach((category) => {
                category.tags.reduce((s, e) => s.add(e), set);
            });

            return set;
        },
        productsRecommend() {
            const productsCopy = this.products.slice();
            return productsCopy
                .sort((a, b) => {
                    return b.rating - a.rating;
                })
                .slice(0, 12);
        },
        routeIsCatalog() {
            return isIndexPage(this.$router.currentRoute);
        },
        heightTopLine() {
            return document.querySelector(".wrap-top-line").getBoundingClientRect().height;
        },
    },
    mounted() {
        this.$refs.searchInput.focus();
        // this.inputFocus = true;
        if (this.$device.isIos) {
            this.searchWrapElem = document.querySelector(".js-search-content");
            disableBodyScroll(this.searchWrapElem);
        }
        this.searchDebounce = debounce(this.sendApiSearch, 800);
        this.windowSize = window.innerWidth;
        this.$nuxt.$on("windowSizeChanged", () => {
            this.windowSize = window.innerWidth;
        });
        document.body.classList.add("modal-search");

        const searchHistory = JSON.parse(localStorage.getItem("search-history"));
        if (searchHistory) {
            this.setSearchHistory(searchHistory);
        }
    },
    beforeDestroy() {
        if (this.$device.isIos) {
            clearAllBodyScrollLocks();
        }
        document.body.classList.remove("modal-search");
    },
    methods: {
        ...mapMutations("modals", ["setSearchOpen"]),
        ...mapMutations("products", ["setSearchHistory", "addToSearchHistory", "delForSearchHistory"]),
        showTooltip(payload) {
            this.tooltipText = payload.text;
            this.tooltipTrigger = payload.trigger;

            this.isShowTooltip = true;
        },
        hideTooltip() {
            this.tooltipText = "";
            this.tooltipTrigger = null;
            this.isShowTooltip = false;
        },
        getDestination(category) {
            return this.$catalogRouter.getCategoryPath(category);
        },
        clearSearchValue() {
            this.$refs.searchInput.value = "";
            this.haveInputValue = false;
            this.$refs.searchInput.focus();
            this.inputFocus = true;
            this.searchProducts = [];
        },
        setSearchValue(e) {
            this.haveInputValue = !!e.target.value;
            this.waitSearch = true;
            if (!this.searchDebounce) {
                return;
            }
            this.searchDebounce(e.target.value);
        },
        scrollToCategory(path) {
            this.closeSearch();
            this.$nuxt.$emit("scroll-to-path", path);
        },
        closeSearch() {
            if (this.$device.isIos) {
                enableBodyScroll(this.searchWrapElem);
            }
            this.setSearchOpen(false);
        },
        sendApiSearch(value) {
            this.$api.get(`/menu/search?query=${value}&limit=20`).then((resp) => {
                if (resp && Array.isArray(resp.products)) {
                    const foundProducts = resp.products;

                    this.searchProducts = this.products.filter((item) => {
                        return foundProducts.find((foundProduct) => foundProduct.id == item.id);
                    });

                    if (value.length > 0 && this.searchProducts.length > 0) {
                        this.addToSearchHistory(value);
                        localStorage.setItem("search-history", JSON.stringify(this.getSearchHistory));
                    }

                    sendYandexMetric("search", { search_query: value });
                } else {
                    this.searchProducts = [];
                }
                this.waitSearch = false;
            });
        },
        searchForHistory(elem) {
            this.clearSearchValue();
            this.haveInputValue = !!elem;
            this.$refs.searchInput.value = elem;
            this.$refs.searchInput.blur();
            this.waitSearch = true;
            if (!this.searchDebounce) {
                return;
            }
            this.searchDebounce(elem);
        },
        delItemSearchHistory(elem) {
            this.delForSearchHistory(elem);
            localStorage.setItem("search-history", JSON.stringify(this.getSearchHistory));
        },
    },
};
