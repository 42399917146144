import { Dispatch } from "vuex";
import { AbstractSocket } from "../abstract";
import { UpdateOrdersSocketMessage } from "./types";

export class UpdateOrdersSocket extends AbstractSocket<UpdateOrdersSocketMessage> {
    private dispatch: Dispatch | undefined;

    setDispatch(dispatch: Dispatch) {
        this.dispatch = dispatch;
    }

    protected getLogName() {
        return "UpdateOrdersSocket";
    }

    protected getClientKey() {
        return "all-orders";
    }

    protected async handleMessage(data: UpdateOrdersSocketMessage) {
        if (!this.dispatch) {
            return;
        }
        if (!data?.data?.orders) {
            return;
        }

        await this.dispatch("account/setCurrentOrders", data.data.orders, { root: true });
    }
}
