import Vue from "vue";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { FormState } from "~/store_types/form.types";
import { RootState } from "~/store_types/index.types";
import { validateDeliveryAddress, validateUserData } from "~/util/data-validation";

export const state: () => FormState = () => ({
    fio: "",
    phone: "",
    email: "",
    changed: false,
    hasBonusCard: {},
});

export const getters: GetterTree<FormState, RootState> = {
    getFio: (state, getters, rootState, rootGetters) => {
        if (state.fio) {
            return state.fio;
        } else if (getters.isLogged && rootState.account.profile && rootState.account.profile.name) {
            return rootState.account.profile.name;
        } else if (rootState.cart.content && rootState.cart.content.userinfo && rootState.cart.content.userinfo.name) {
            return rootState.cart.content.userinfo.name;
        }
        return "";
    },
    getPhone: (state, getters, rootState, rootGetters) => {
        if (getters.isLogged && rootState.account.profile) {
            return rootState.account.profile.phone;
        } else if (state.phone) {
            return state.phone;
        } else if (rootState.cart.content && rootState.cart.content.userinfo && rootState.cart.content.userinfo.phone) {
            return rootState.cart.content.userinfo.phone;
        }
        return "";
    },
    getEmail: (state, getters, rootState, rootGetters) => {
        if (state.email) {
            return state.email;
        } else if (getters.isLogged && rootState.account.profile && rootState.account.profile.email) {
            return rootState.account.profile.email;
        }

        return "";
    },
    getCurrentEmail: (state) => {
        return state.email;
    },
    getAddressComponents: (state, getters, rootState, rootGetters) => {
        return rootGetters["delivery/deliveryAddress"];
    },
    getIsDoorToDoor: (state, getters, rootState, rootGetters) => {
        return rootGetters["delivery/isDoorToDoor"];
    },
    getIsExternalDelivery: (state, getters, rootState, rootGetters) => {
        return rootGetters["delivery/isExternalDelivery"];
    },
    getNeedExtendedAddressComponents: (state, getters, rootState, rootGetters) => {
        return rootGetters["delivery/needExtendedAddressComponents"];
    },
    isLogged: (state, getters, rootState, rootGetters) => {
        return !!rootGetters["auth/isLoggedIn"] && !!rootState.account.profile;
    },
    showRegisterBonusCard: (state, getters, rootState, rootGetters) => {
        return (
            (getters.isLogged && rootState.account.profile && !rootState.account.profile.bonus_card) ||
            (!getters.isLogged && getters.getPhone.length == 10 && state.hasBonusCard[getters.getPhone] === false)
        );
    },
};

export const actions: ActionTree<FormState, RootState> = {
    async updateUserInfo({ state, getters, commit, dispatch, rootState }) {
        // dispatch("errors/clearError", "form", {root: true});

        if (getters.getPhone || getters.getEmail || getters.getFio) {
            try {
                const resp = await this.$postCartQueue.add("cart/userInfo", {
                    phone: getters.getPhone,
                    email: getters.getEmail,
                    name: getters.getFio,
                });
            } catch (err) {
                return Promise.reject("Update user info failed");
            }

            commit("setChanged", false);
        }

        if (!getters.isLogged || !rootState.account.profile) {
            return;
        }

        let updateProfile = false;
        if (rootState.account.profile.name != getters.getFio) {
            commit("account/updateName", getters.getFio, { root: true });
            updateProfile = true;
        }
        if (rootState.account.profile.email != getters.getEmail) {
            commit("account/updateEmail", getters.getEmail, { root: true });
            updateProfile = true;
        }

        if (updateProfile) {
            try {
                await dispatch("account/updateProfile", null, { root: true });
            } catch (err) {
                //
            }
        }
    },
    validate({ commit, dispatch, state, getters }) {
        dispatch("errors/clearError", "form", { root: true });

        let errors = validateUserData({
            // fio: getters.getFio,
            phone: getters.getPhone,
            // email: getters.getEmail,
        });

        errors = Object.assign(
            errors,
            validateDeliveryAddress(
                getters.getAddressComponents,
                getters.getIsExternalDelivery && getters.getIsDoorToDoor
            )
        );

        if (Object.keys(errors).length > 0) {
            return Promise.reject({ form: errors });
        }

        // if(state.changed) {
        //     try {
        //         await dispatch('updateUserInfo');
        //     } catch({ error_code, error_message }) {
        //         return Promise.reject({ form: { update: error_message } });
        //     }
        // }
    },

    async setPhone({ commit, dispatch, state, getters, rootState }, phone) {
        const oldPhone = state.phone;
        commit("setPhone", phone);

        if (
            !getters.isLogged &&
            state.phone != oldPhone &&
            state.phone.length == 10 &&
            !state.hasBonusCard[state.phone]
        ) {
            try {
                const resp = await this.$api.post("user/checkBonusCard", { phone: state.phone });
                commit("setHasBonusCard", resp.success && resp.has_card);
                commit(
                    "account/setBonusDefaultPercent",
                    resp && resp.default_bonus_percent ? resp.default_bonus_percent : 0,
                    { root: true }
                );
            } catch (err) {
                commit("setHasBonusCard", false);
            }

            if (oldPhone && rootState.payment.bonusCardCode) {
                dispatch("payment/clearBonusCard", null, { root: true });
            }
        }
    },
};

export const mutations: MutationTree<FormState> = {
    setFIO(state, fio) {
        if (state.fio != fio) {
            state.fio = fio;
            state.changed = true;
        }
    },
    setPhone(state, phone) {
        // if(phone && phone.length != 10) {
        //     phone = '9' + phone;
        // }
        if (state.phone != phone) {
            state.phone = phone;
            state.changed = true;
        }
    },
    setEmail(state, email) {
        if (state.email != email) {
            state.email = email;
            state.changed = true;
        }
    },
    setChanged(state, val) {
        state.changed = val;
    },
    setHasBonusCard(state, val) {
        Vue.set(state.hasBonusCard, state.phone, val);
    },
};
