//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { numberWithSpaces } from "~/util/helpers";

export default {
    name: "ProductBeforeDiscountPrice",
    props: ["price", "count", "total"],
    methods: {
        formatValue(value) {
            return numberWithSpaces(value);
        },
    },
};
