import { Context } from "@nuxt/types";
import { NuxtCookies } from "cookie-universal-nuxt";
import { getContext, getServerStorage, getUpdateStorageFunction } from "../../context-keeper/index";
import { Api } from "../../api";
import { getTime, randInt } from "../../../util/helpers";
import { DEFAULT_CITY_ID } from "../../../util/types";
import { cloneDeep } from "../../../util/clone";
import { EXPIRED_STORAGE_KEY_PREFIX, PossibleContextData } from "./types";
import { SharedContextData } from "./shared-context-data";

export abstract class AbstractStorage {
    private contextData: PossibleContextData = {};

    private sharedContextData: SharedContextData | null = null;

    protected abstract getKey(): string;

    protected abstract getLogName(): string;

    protected abstract apiRequest(): any;

    beforeStorageUpdate() {}

    afterStorageUpdate() {}

    protected async generateSharedContextData(): Promise<SharedContextData | null> {
        const serverStorage = await this.getServerStorage();
        if (!serverStorage) {
            return null;
        }

        const updateFunction = await this.getUpdateFunction();
        if (!updateFunction) {
            return null;
        }

        return new SharedContextData()
            .setData(this.contextData)
            .setServerStorage(serverStorage)
            .setUpdateFunction(updateFunction);
    }

    protected isCloneValuesOnGet(): boolean {
        return true;
    }

    setSharedContextData(value: SharedContextData | null = null) {
        this.sharedContextData = value;
        return this;
    }

    protected getContextValue(key, defaultValue: any | null = null): any | null {
        if (this.contextData[key] === undefined) {
            return defaultValue;
        }
        return this.contextData[key];
    }

    protected getApi(): Api {
        return new Api();
    }

    protected getCityId() {
        return this.getContextValue("cityId") || DEFAULT_CITY_ID;
    }

    public async getUpdateKey() {
        return await this.getKey();
    }

    protected async getServerStorage() {
        if (this.sharedContextData) {
            return this.sharedContextData.getServerStorage();
        }
        return await getServerStorage();
    }

    protected async getUpdateFunction() {
        if (this.sharedContextData) {
            return this.sharedContextData.getUpdateFunction();
        }
        const f = await getUpdateStorageFunction();
        return typeof f === "function" ? f : null;
    }

    /**
     * Время, через которое будет запрошено обновление элемента в хранилище.
     * При запросе элемента, он останется в хранилище и будет возвращен.
     */
    protected getUpdatePeriod(): number | null {
        return 2 * 3600;
    }

    /**
     * Время, через которое элемент будет удалён из хранилища при его запросе.
     * При запросе элемента будет возвращено undefined.
     */
    protected getRemovePeriod(): number | null {
        return null;
    }

    protected async getExpirationKey(): Promise<string> {
        return `${EXPIRED_STORAGE_KEY_PREFIX}${await this.getKey()}`;
    }

    private async isNeedUpdate(): Promise<boolean> {
        const $serverStorage = await this.getServerStorage();
        if (!$serverStorage) {
            return false;
        }

        const expKey = await this.getExpirationKey();
        if (!expKey) {
            return false;
        }

        const expirationTimestamp = await $serverStorage.getSharedValue(expKey);
        // this.log('** checking expiration', expirationTimestamp, getTime());
        if (this.getUpdatePeriod() && expirationTimestamp === undefined) {
            return true;
        }

        return expirationTimestamp != null && getTime() >= expirationTimestamp;
    }

    private getNextExpirationTimestamp(): number | null {
        const expirationSeconds = this.getUpdatePeriod();
        if (!expirationSeconds) {
            return null;
        }
        return getTime() + expirationSeconds; // + randInt(-100, 100);
    }

    protected log(...pieces) {
        // if(this.getLogName() !== 'URLS_STORAGE') return;
        // console.log(`[${this.getLogName()}]`, ...pieces);
    }

    private async getFromStorage(): Promise<any | undefined> {
        this.log("Loading from storage...");

        const $serverStorage = await this.getServerStorage();
        if (!$serverStorage) {
            return undefined;
        }

        const key = this.getKey();
        if (!key) {
            return undefined;
        }

        return await $serverStorage.getSharedValue(key);
    }

    public async updateFromApi(): Promise<any | undefined> {
        this.log("Loading from API...");

        let result: any | null = null;

        try {
            result = await this.apiRequest();
        } catch (err) {
            // console.log('** error while apiRequest', this.getLogName(), err);
            this.log("error while apiRequest", err);
            return undefined;
        }

        this.log("result of apiRequest", result);

        if (!result) {
            return undefined;
        }

        const key = this.getKey();
        const expKey = await this.getExpirationKey();

        this.log("storage keys", key, expKey);

        if (!key || !expKey) {
            return undefined;
        }

        const $serverStorage = await this.getServerStorage();
        if ($serverStorage) {
            // Удаление элемента из хранилища
            const removeCacheTime = this.getRemovePeriod();

            // Значение
            // this.log('setting value', this.storageKey);
            await $serverStorage.setSharedValue(key, result, removeCacheTime);

            // Срок истечения актуальности
            const expTimestamp = this.getNextExpirationTimestamp();
            // this.log('setting exp', this.storageExpKey, expTimestamp);
            await $serverStorage.setSharedValue(expKey, expTimestamp, removeCacheTime);
        } else {
            this.log("$serverStorage is unavailable");
        }

        return result;
    }

    public async isInStorage(): Promise<boolean> {
        return (await this.getFromStorage()) !== undefined;
    }

    public async shouldUpdateFromApi(): Promise<boolean> {
        return !(await this.isInStorage()) || (await this.isNeedUpdate());
    }

    private async loadValues() {
        const updateFunction = await this.getUpdateFunction();
        if (!updateFunction) {
            console.log("** !!! NO update function!");
            return;
        }

        if (!(await this.isInStorage())) {
            await updateFunction(this);
        }

        const output = await this.getFromStorage();

        this.log("getFromStorage is undefined", output === undefined);

        if (await this.isNeedUpdate()) {
            this.log("getFromStorage is expired", true);
            updateFunction(this);
        }

        return output;
    }

    protected async runParseContextData() {
        // Не переопределять! Лучше переопределить parseContextData, который ниже :)
        let data = {};

        if (this.sharedContextData) {
            data = this.sharedContextData.getData();
        } else {
            const ctx = await getContext();
            if (ctx) {
                data = this.parseContextData(ctx);
            }
        }

        Object.assign(this.contextData, data);
    }

    protected parseContextData(ctx: Context): PossibleContextData {
        // Можно переопределить, объединив результат с данным
        const cookies: NuxtCookies | undefined = ctx["$cookies"]; /* eslint-disable-line dot-notation */
        return { cityId: cookies ? cookies.get("city_id", { fromRes: true }) : undefined };
    }

    protected isClientSideAllowed(): boolean {
        return false;
    }

    protected getOnClientSide(): any {
        return null;
    }

    async get() {
        this.log("Getting values");
        if (process.client) {
            if (this.isClientSideAllowed()) {
                return this.getOnClientSide();
            }
            console.warn(`Storage '${this.getLogName()}' is not allowed on client side`);
            return null;
        }

        await this.runParseContextData();

        let values = await this.loadValues();
        if (this.isCloneValuesOnGet()) {
            values = cloneDeep(values);
        }
        return values;
    }
}
