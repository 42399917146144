import { render, staticRenderFns } from "./ProductPopup.vue?vue&type=template&id=595231b6&scoped=true&"
import script from "./ProductPopup.vue?vue&type=script&lang=js&"
export * from "./ProductPopup.vue?vue&type=script&lang=js&"
import style0 from "./ProductPopup.vue?vue&type=style&index=0&id=595231b6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "595231b6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Arrow: require('/usr/src/app/components/icons/Arrow.vue').default,BurgerClose: require('/usr/src/app/components/icons/BurgerClose.vue').default,ProductPopupImg: require('/usr/src/app/components/products/ProductPopupImg.vue').default,RecommendedProducts: require('/usr/src/app/components/products/RecommendedProducts.vue').default,NuxtCityLink: require('/usr/src/app/components/common/NuxtCityLink.vue').default,ProductTags: require('/usr/src/app/components/products/ProductTags.vue').default,AttractiveOffers: require('/usr/src/app/components/products/AttractiveOffers.vue').default,ProductConsist: require('/usr/src/app/components/products/ProductConsist.vue').default,ProductIngredients: require('/usr/src/app/components/products/ProductIngredients.vue').default,ProductInCart: require('/usr/src/app/components/products/ProductInCart.vue').default,ProductActions: require('/usr/src/app/components/products/ProductActions.vue').default})
