import { getContext } from "../service/context-keeper";
import { isEmpty, pick } from "./helpers";
import { Cart } from "~/util/api.types";
import { PaymentType } from "~/store_types/payment.types";

async function getCartRequestData() {
    const ctx = await getContext();
    if (!ctx) {
        return null;
    }

    const store = ctx.store;
    if (!store) {
        return null;
    }

    const kitchenComments: any[] = [];
    if (store.state.cart.markdown) {
        // комментарий уценка
        kitchenComments.push(19378);
    }

    const deliveryAddress = {};
    const checkAdditionalFields = ["flat", "entrance", "floor", "domofon"];
    for (const field of checkAdditionalFields) {
        deliveryAddress[field] =
            !isEmpty(store.getters["delivery/deliveryAddress"]) &&
            !isEmpty(store.getters["delivery/deliveryAddress"][field])
                ? store.getters["delivery/deliveryAddress"][field]
                : "";
    }

    const sendData: any = {
        payment_info: {
            type: store.state.payment.currentPaymentType.type,
            change_from: store.state.payment.change_from,
            is_qr_prepayment: true,
            online:
                store.state.payment.currentPaymentType.type == PaymentType.ONLINE
                    ? {
                          token: store.state.payment.paymentToken,
                          type: store.state.payment.currentPaymentType.online_type,
                          save_card: store.getters["auth/isLoggedIn"] ? store.state.payment.saveCard : false,
                          card_id:
                              store.getters["auth/isLoggedIn"] && !store.getters["payment/emptyPaymentCardId"]
                                  ? store.state.payment.paymentCardId
                                  : null,
                      }
                    : null,
        },
        source: "site",
        kitchen_comments: kitchenComments,
        delivery_address: deliveryAddress,
        user_data: {
            ...pick(store.state.account.profile, [
                "name",
                "email",
                "birthdate",
                "sex",
                "subscribe_sms",
                "subscribe_email",
            ]),
            ga_clientId: store.state.gaClientId,
        },
    };

    // sendData.payment_info.is_payment_qr = true; // TODO: Активировать, если выбран тип оплаты СБП (по QR-коду)

    if (store.state.form.fio && store.state.form.fio.length > 0) {
        sendData.user_data.name = store.state.form.fio;
    }
    if (store.state.form.email && store.state.form.email.length > 0) {
        sendData.user_data.email = store.state.form.email;
    }

    return sendData;
}

function calculateTotalAmounts(cart: Cart) {
    const totalAmount = cart.products.reduce((sum, item) => sum + item.price * item.quantity, 0);
    const discountAmount = Math.round((totalAmount * cart.discount_percent) / 100);

    const promoeventsAmount = 0; // TODO
    const deliveryPrice = cart.delivery_price || 0;
    const compensationsAmount = (cart.compensations || []).reduce((sum, item) => sum + item.amount, 0);
    const paidByBonus = cart.paid_by_bonuses || 0;

    const totalAmountClean = Math.max(
        0,
        totalAmount - discountAmount - promoeventsAmount + deliveryPrice - compensationsAmount - paidByBonus
    );

    return {
        totalAmount,
        totalAmountClean,
    };
}

export default {
    getCartRequestData,
    calculateTotalAmounts,
};
