import { render, staticRenderFns } from "./ModifiersProductGroup.vue?vue&type=template&id=d1d50f2e&scoped=true&"
import script from "./ModifiersProductGroup.vue?vue&type=script&lang=js&"
export * from "./ModifiersProductGroup.vue?vue&type=script&lang=js&"
import style0 from "./ModifiersProductGroup.vue?vue&type=style&index=0&id=d1d50f2e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1d50f2e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductBeforeDiscountPrice: require('/usr/src/app/components/products/ProductBeforeDiscountPrice.vue').default,Minus: require('/usr/src/app/components/icons/Minus.vue').default,AllCustomButtons: require('/usr/src/app/components/common/AllCustomButtons.vue').default,Plus: require('/usr/src/app/components/icons/Plus.vue').default,ProductEnergy: require('/usr/src/app/components/products/ProductEnergy.vue').default,SmallInfoModal: require('/usr/src/app/components/modal/smallInfoModal.vue').default})
