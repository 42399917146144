//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "CircleLoader",
    props: {
        color: {
            type: String,
            default: "#fff",
        },
    },
};
