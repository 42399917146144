import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { plural } from "../util/helpers";
// import {ACCOUNT_TABS} from '@/util/types'

export default {
    name: "AuthBlock",
    props: ["menuPopup"],
    computed: {
        ...mapGetters("auth", { isLoggedIn: "isLoggedIn" }),
        ...mapGetters("account", {
            hasCurrentOrders: "hasCurrentOrders",
            profile: "profile",
            bonusCard: "bonusCard",
            expectedDeliveryTime: "expectedDeliveryTime",
            isPasswordRecoveryLink: "isPasswordRecoveryLink",
        }),
        ...mapGetters("account", {
            bonus_points: "bonusPoints",
            user_name: "userName",
            // hasCurrentOrders: 'hasCurrentOrders',
            // hasHistoryOrders: 'hasHistoryOrders',
            // currenfavoriteProducttOrders: 'currenfavoriteProducttOrders',
        }),
        // isAccountPage: function () {
        //     return window.app_page=='account';
        // },
        // tabs() {
        //     return ACCOUNT_TABS;
        // },
        formatName() {
            if (!this.user_name) {
                return;
            }
            return this.user_name.length > 20 ? this.user_name.slice(0, 18) + "..." : this.user_name;
        },
        pluralizedBonusPoints() {
            return plural(this.bonus_points, "бонус", "бонуса", "бонусов");
        },
        formatExpectedDeliveryTime() {
            if (!this.expectedDeliveryTime) {
                return;
            }
            const currentDate = moment();
            const deliveryPrediction = moment(this.expectedDeliveryTime);
            const diffMinutes = deliveryPrediction.diff(currentDate, "minutes");
            if (diffMinutes > 60) {
                const hours = Math.floor(diffMinutes / 60);
                const minutes = diffMinutes - 60 * hours;
                return `≈ ${hours} ч.` + (minutes ? ` ${minutes} мин.` : "");
            }
            return diffMinutes > 0 ? `≈ ${diffMinutes} мин.` : "Курьер подъезжает";
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "openModal"]),
        openRegistration() {
            if (!this.isLoggedIn) {
                this.openModal({ modalName: "HeaderLoginForm" });
            }
        },
        decideClickProfile() {
            if (this.isLoggedIn) {
                this.$router.push("/user/profile");
            } else {
                this.openRegistration();
            }
        },
        // logoutModal(){
        //     this.confirmText = 'Вы действительно хотите выйти?'
        // },
        // logoutCancel() {
        //     this.confirmText = ''
        // },
        logout() {
            this.openModal({
                modalName: "InfoModal",
                modalData: {
                    text: "Вы действительно хотите выйти?",
                    class: "logout-modal",
                    confirmation: true,
                    yes: "Выйти",
                    no: "Отмена",
                    agreeHandler: async () => {
                        await this.$store.dispatch("auth/logout");
                        await this.$store.dispatch("cart/loadCart", false);
                    },
                    regretHandler: () => {},
                },
            });
            // this.$store.dispatch('auth/logout');
        },
        goBonusCard() {
            if (this.profile && this.profile.bonus_card) {
                this.$router.push("/user/bonus_card");
            } else {
                this.$router.push("/bonus_card");
            }
        },
        checkPasswordRecoveryLink() {
            if (this.isPasswordRecoveryLink) {
                if (!this.isLoggedIn) {
                    this.openModal({ modalName: "HeaderLoginForm" });
                }
            }
        },
        // gotoTab(tab) {
        //     if(window.app_page == 'account') {
        //         if(this.windowWidth < 1024) {
        //             $(".js-navbar-bg").click();
        //         }else {
        //             $("body").click();
        //         }
        //         this.$store.dispatch("account/gotoTab", tab);
        //     } else {
        //         this.$store.dispatch("account/redirectTo", tab);
        //     }
        // },
        // isMobile(){
        //     return this.windowWidth < 1279
        // },
    },
    data() {
        return {};
    },
    mounted() {
        setTimeout(() => {
            this.checkPasswordRecoveryLink();
        }, 2000);

        // window.onresize = () => {
        //     this.windowWidth = window.innerWidth
        // }
    },
};
