// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/no-img.webp");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.blured-container[data-v-01a4afe7] {\n    position: fixed;\n    height: 52px;\n    z-index: 200;\n}\n.real-notification[data-v-01a4afe7] {\n    background-color: rgb(255 255 255 / 50%);\n    backdrop-filter: blur(3px);\n    height: 100%;\n    width: 100%;\n    max-width: 230px;\n    border-radius: 5px;\n    padding: 8px 15px 8px 8px;\n    display: grid;\n    grid-template-columns: 40px 1fr;\n    column-gap: 8px;\n}\n.closing-effect[data-v-01a4afe7] {\n    position: relative;\n}\n.text[data-v-01a4afe7] {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    max-width: 140px;\n}\n.product-name[data-v-01a4afe7] {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n.blur-in .real-notification[data-v-01a4afe7] {\n    animation: opening-data-v-01a4afe7 0.6s linear forwards,closing-data-v-01a4afe7 0.3s linear 2.7s forwards;\n}\n@keyframes opening-data-v-01a4afe7 {\n0% {\n        opacity: 0;\n        backdrop-filter: blur(0);\n}\n50% {\n        opacity: 1;\n        backdrop-filter: blur(1px);\n}\n100% {\n        backdrop-filter: blur(3px);\n}\n}\n@keyframes closing-data-v-01a4afe7 {\n0% {\n        opacity: 1;\n        backdrop-filter: blur(3px);\n}\n100% {\n        opacity: 0;\n        backdrop-filter: blur(0);\n}\n}\n.image[data-v-01a4afe7] {\n    max-height: 40px;\n    overflow: hidden;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 100%;\n    display: flex;\n    justify-content: center;\n    border-radius: 5px;\n}\n.image img[data-v-01a4afe7] {\n    height: 40px;\n}\n", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
