import { Plugin } from "@nuxt/types";
import { Context, Inject } from "@nuxt/types/app";
import { UpdateOrdersSocket } from "./index";

const exportPlugin: Plugin = (context: Context, inject: Inject) => {
    const ws = new UpdateOrdersSocket();
    ws.setDispatch(context.store.dispatch);

    inject("updateOrdersSocket", ws);
};

export default exportPlugin;
