// Управление скроллом при переходе между страницами (nuxt сам находит файл и подключает)
// https://v2.nuxt.com/docs/configuration-glossary/configuration-router#scrollbehavior
import { sleep } from "../util/helpers";
import { isIndexPage } from "../service/catalog-router/helpers";

export default function (to, from, savedPosition) {
    const nuxt = window && window.$nuxt ? window.$nuxt : null;
    const position = savedPosition || { x: 0, y: 0 };

    if (isIndexPage(to) && isIndexPage(from)) {
        // Запрещаем скролл при смене урла в каталоге
        return undefined;
    }
    if (isIndexPage(to) && !isIndexPage(from)) {
        // Если в каталог переходят с других страниц...
        return to.fullPath === "/"
            ? { x: 0, y: 0 } // Переход просто на главную - скроллим в начало
            : undefined; // Переход в какую-либо категорию - оставляем на усмотрение CurrentCategory.vue
    }

    if (!nuxt) {
        return position;
    }

    // Особенность в том, что скролл срабатывает перед переходом на другую страницу
    // Данный хак призван дождаться загрузки новой страницы, а затем уже применять скролл
    return new Promise((resolve) => {
        (async () => {
            for (let i = 0; i < 5; i++) {
                // eslint-disable-next-line no-await-in-loop
                await sleep(200);
                if (nuxt.$route.path == to.path) {
                    break;
                }
            }
            resolve(position);
        })();
    });
}
