import { ActionTree, GetterTree, MutationTree } from "vuex";
import { Payment, PaymentBank, PaymentOnlineType, PaymentState, PaymentType } from "~/store_types/payment.types";
import { RootState } from "~/store_types/index.types";
import { validateUserData } from "~/util/data-validation";

export const state: () => PaymentState = () => ({
    bonusCardUserToken: null,
    bonusCardCode: null,
    hasBonusCard: false,
    bonus: false,
    bonusPoints: 0,
    bonusPointsApplied: 0,
    paymentToken: null,
    currentPaymentType: null,
    availablePayments: [],
    paymentReady: {},
    gpayReady: false,
    apayReady: false,
    gpay: null,
    apay: null,
    gpayToken: null,
    apayToken: null,
    qrCode: null,
    cloudPaymentToken: null,
    saveCard: true,
    paymentCardId: null,
    change_from: null,

    sms_subscription: true,
    email_subscription: true,
    // user_agreement: true,
    requireAuth: false,

    paymentUrl: null,

    qrId: null,
    qrUrl: null,
});

export const getters: GetterTree<PaymentState, RootState> = {
    availablePaymentTypes: (state, getters, rootState, rootGetters) => {
        return rootGetters.cart.available_payment_types?.filter((type) => !type.disabled) || [];
    },
    paymentType: (state, getters, rootState, rootGetters) => {
        if (rootGetters.cart.payment_info) {
            return getters.availablePaymentTypes.find((p) => p.id == rootGetters.cart.payment_info.payment_type);
        }
        return null;
    },
    cash: (state, getters, rootState, rootGetters) => {
        return getters.availablePaymentTypes.find((p) => p.id == PaymentType.CASH);
    },
    cashless: (state, getters, rootState, rootGetters) => {
        return getters.availablePaymentTypes.find((p) => p.id == PaymentType.CASHLESS);
    },
    online: (state, getters, rootState, rootGetters) => {
        // if(rootGetters['form/getPhone'] == '9089030734') {
        return getters.availablePaymentTypes.find((p) => p.id == PaymentType.ONLINE);
        // }
        // return null;
    },
    qrCode: (state, getters, rootState, rootGetters) => {
        return getters.availablePaymentTypes.find((p) => p.id == PaymentType.QR);
    },
    isApplePay: (state, getters) => {
        return state.currentPaymentType?.online_type == PaymentOnlineType.APPLE_PAY;
    },
    isGooglePay: (state, getters) => {
        return state.currentPaymentType?.online_type == PaymentOnlineType.GOOGLE_PAY;
    },
    isSberPay: (state, getters) => {
        return state.currentPaymentType?.online_type == PaymentOnlineType.SBERPAY;
    },
    emptyPaymentCardId: (state, getters, rootState, rootGetters) => {
        return state.paymentCardId == 0 || state.paymentCardId == null;
    },
    cloudPaymentKey: (state, getters, rootState, rootGetters) => {
        return getters.online ? getters.online.cloud_payment_key : null;
    },
    isOnlinePayment: (state, getters, rootState, rootGetters) => {
        return (
            state.currentPaymentType?.type != PaymentType.CASH && state.currentPaymentType?.type != PaymentType.CASHLESS
        );
    },
    isOnlineCardPayment: (state, getters, rootState, rootGetters) => {
        return (
            state.currentPaymentType?.online_type == PaymentOnlineType.CARD &&
            state.currentPaymentType?.type == PaymentType.ONLINE
        );
    },
    isOnlineCloudPayment: (state, getters, rootState, rootGetters) => {
        return getters.isOnlineCardPayment && state.currentPaymentType?.bank == PaymentBank.CLOUD_PAYMENTS;
    },
    availableBanknotes: (state, getters, rootState, rootGetters) => {
        const resultMap: { [key: number]: boolean } = {};
        const sum: number = rootGetters.cart.total_amount_clean;
        const banknotes: number[] = [50, 100, 200, 500, 1000, 2000, 5000];

        for (let i = 0; i < banknotes.length; i++) {
            const b1 = banknotes[i];

            if (b1 > sum) {
                resultMap[b1] = true;
            }

            for (let k = 0; k <= i; k++) {
                const b2 = banknotes[k];
                const cnt = Math.ceil((sum - b1) / b2);

                if (cnt > 0) {
                    const val = b1 + cnt * b2;

                    if (val > sum) {
                        resultMap[val] = true;
                    }
                }
            }
        }

        const res: string[] = Object.keys(resultMap);
        res.push("Без сдачи");
        return res;
    },
    bonusCard: (state, getters, rootState, rootGetters) => {
        const accountBonusCard = rootGetters["account/bonusCard"];
        const bonusCard = {
            code: accountBonusCard ? accountBonusCard.code : state.bonusCardCode,
            hasCard: !!(accountBonusCard || state.hasBonusCard),
            bonusPoints: accountBonusCard ? accountBonusCard.bonuses : state.bonusPoints,
            bonusPointsApplied: rootGetters.cart.paid_by_bonuses,
        };
        return bonusCard;
    },
    bonusCardAmounUsed: (state, getters, rootState, rootGetters) => {
        return rootGetters.cart.paid_by_bonuses;
    },
    currentPaymentMerchant: (state, getters) => {
        return state.currentPaymentType?.merchant;
    },
    getQrId: (state) => {
        return state.qrId;
    },
    getQrUrl: (state) => {
        return state.qrUrl;
    },
};

export const mutations: MutationTree<PaymentState> = {
    requireAuth(state, val) {
        state.requireAuth = Boolean(val);
    },
    updateBonusCardCode(state, bonusCard) {
        state.bonusCardCode = bonusCard;
    },
    updateBonusCardPoints(state, amount) {
        state.bonusPoints = amount;
        state.hasBonusCard = true;
    },
    setBonusCardToken(state, token) {
        state.bonusCardUserToken = token;
    },
    clearBonusCard(state) {
        // state.bonusCardCode = null;
        state.bonusPoints = 0;
        state.hasBonusCard = false;
        state.bonusCardUserToken = null;
    },
    setPaymentReady(state, payload) {
        if (payload === null) {
            state.paymentReady = {};
        } else {
            state.paymentReady = Object.assign({}, state.paymentReady, payload);
        }
    },
    setPayments(state, val) {
        state.availablePayments = val;
    },
    setCurrentPayment(state, val) {
        state.currentPaymentType = val;
    },
    setChangeFrom(state: PaymentState, val: string | null): void {
        state.change_from = val;
    },
    setSmsSubscription(state, val) {
        state.sms_subscription = val;
    },
    setEmailSubscription(state, val) {
        state.email_subscription = val;
    },
    // setUserAgreement(state, val) {
    //     state.user_agreement = val;
    // },
    setPaymentUrl(state, val) {
        state.paymentUrl = val;
    },
    setSaveCard(state, val) {
        state.saveCard = val;
    },
    setPaymentCardId(state, val) {
        state.paymentCardId = val;
    },
    setPaymentToken(state, val) {
        state.paymentToken = val;
    },
    setQrId(state, val) {
        state.qrId = val;
    },
    setQrUrl(state, val) {
        state.qrUrl = val;
    },
};

export const actions: ActionTree<PaymentState, RootState> = {
    initPayments({ commit, getters, dispatch, state }) {
        const payments: Array<Payment> = [];

        if (state.paymentReady[PaymentOnlineType.APPLE_PAY] === undefined) {
            commit("setPaymentReady", { [PaymentOnlineType.APPLE_PAY]: false });
        }
        if (state.paymentReady[PaymentOnlineType.GOOGLE_PAY] === undefined) {
            commit("setPaymentReady", { [PaymentOnlineType.GOOGLE_PAY]: false });
        }

        getters.availablePaymentTypes.forEach((p) => {
            if (p.id != PaymentType.ONLINE) {
                payments.push({
                    id: p.id,
                    type: p.id,
                    name: p.name,
                    ready: true,
                });
            } else if (p.types) {
                p.types.forEach((pt) => {
                    payments.push({
                        id: pt.type,
                        type: p.id,
                        online_type: pt.type,
                        name: pt.name,
                        merchant: pt.token,
                        bank: pt.gateway,
                        ready: state.paymentReady[pt.type] !== undefined ? state.paymentReady[pt.type] : true,
                    });
                });
            }
        });

        commit("setPayments", payments);

        if (payments.length == 1 && !state.currentPaymentType) {
            dispatch("setPaymentType", payments[0]);
        }
    },
    setPaymentType({ commit, dispatch, state }, payload) {
        commit("setCurrentPayment", payload);
    },
    async loadBonusCard({ commit, dispatch, rootGetters, getters }) {
        dispatch("errors/clearError", "bonusCard", { root: true });
        commit("clearBonusCard");

        try {
            const resp = await this.$api.post("user/checkBonusCard", {
                phone: rootGetters["form/getPhone"],
                bonus_card: getters.bonusCard.code,
            });

            if (resp.success && resp.has_card) {
                commit("updateBonusCardPoints", resp.bonus_points);
                if (resp.token) {
                    commit("setBonusCardToken", resp.token);
                }
                return true;
            } else if (resp.success && !resp.has_card) {
                dispatch("errors/addError", { bonusCard: "Не найдена бонусная карта" }, { root: true });
            } else {
                dispatch("errors/addError", { bonusCard: resp.error_message }, { root: true });
            }
        } catch ({ error_code, error_message }) {
            dispatch("errors/addError", { bonusCard: error_message }, { root: true });
        }
        return false;
    },
    async clearBonusCard({ commit, dispatch, rootState, state, getters }) {
        dispatch("errors/clearError", "bonusCardAmountUsed", { root: true });
        commit("updateBonusCardCode", null);
        commit("clearBonusCard");

        try {
            await this.$postCartQueue.add("cart/clearBonusCard", {}, 0);
        } catch (err) {
            //
        }
    },
    async setBonusAmountUsed({ commit, dispatch, rootState, rootGetters, state, getters }, value) {
        dispatch("errors/clearError", "bonusCardAmountUsed", { root: true });

        dispatch("wait/start", "cart.payment.bonus", { root: true });
        // commit('cart/setBonusPointsUsed', value, {root: true});

        try {
            const data = { paid_by_bonuses: value };
            let method = "cart/paidByBonus";

            if (!rootGetters["auth/isLoggedIn"]) {
                Object.assign(data, {
                    phone: rootState.form.phone,
                    bonus_card: getters.bonusCard.code,
                });
                method = "cart/paidByBonusCardAndPhone";
            }

            await this.$postCartQueue.add(method, data, 0);
        } catch (err) {
            dispatch("errors/addError", { bonusCardAmountUsed: err.error_message }, { root: true });
        } finally {
            dispatch("wait/end", "cart.payment.bonus", { root: true });
        }
    },
    enter({ commit, dispatch }) {
        dispatch("errors/clearError", "payment", { root: true });
        dispatch("errors/clearError", "cart", { root: true });
    },
    validate({ commit, dispatch, state, getters, rootGetters }) {
        dispatch("errors/clearError", "payment", { root: true });

        const errorValidate = validateUserData({ email: rootGetters["form/getCurrentEmail"] });

        if (rootGetters.cart.minimum_amount && rootGetters.cart.total_amount < rootGetters.cart.minimum_amount) {
            return Promise.reject({ "payment.min_sum": "Минимальная сумма заказа " + rootGetters.cart.minimum_amount });
        }
        // else if (!state.user_agreement) {
        //     return Promise.reject({
        //         "payment.user_agreement":
        //             "Простите, но мы не можем принять Ваш заказ без Вашего согласия на обработку персональных данных",
        //     });
        // }
        else if (!state.currentPaymentType) {
            return Promise.reject({ "payment.type": "Выберите тип оплаты" });
        } else if (getters.isOnlinePayment && Object.keys(errorValidate).length > 0) {
            return Promise.reject({
                "payment.email": errorValidate.email,
                "payment.errorsDisplay": true,
            });
        } else if (state.change_from === null && state.currentPaymentType.id == PaymentType.CASH) {
            return Promise.reject({ "payment.change": "С какой суммы потребуется сдача?" });
        } else {
            const paymentType = getters.availablePaymentTypes.find((p) => p.id == state.currentPaymentType?.type);
            if (!paymentType) {
                return Promise.reject({ "payment.type": "Данный тип оплаты не доступен" });
            }
        }
    },
    auth({ commit, rootState, state, rootGetters }) {
        if (!rootGetters["auth/isLoggedIn"]) {
            commit("requireAuth", true);
            return Promise.reject("Payment auth needed");
        }
    },
};
