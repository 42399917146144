//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from "vuex";
import { numberWithSpaces } from "~/util/helpers";
import { isIndexPage } from "~/service/catalog-router/helpers";
import CircleLoader from "~/components/CircleLoader.vue";
import { sendYandexMetric } from "~/service/ecommerce";

export default {
    name: "TopCart",
    data() {
        return {
            width: "unset",
            cartPreview: false,
        };
    },
    computed: {
        ...mapGetters("cart", ["isCartLoaded", "totals"]),
        isLoading() {
            return this.$wait.is("cart.update_product");
        },
        isMobile() {
            return this.$screen.width < 1024;
        },
        isRouteCatalog() {
            return isIndexPage(this.$router.currentRoute);
        },
        cartSumm() {
            return this.isCartLoaded && this.totals.summClear ? this.formatValue(this.totals.summClear) : 0;
        },
    },
    methods: {
        async gotoCart() {
            sendYandexMetric("go-to-basket");
            await this.$router.push("/order/products");
        },
        formatValue(value) {
            return numberWithSpaces(value);
        },
        showCartPreview() {
            this.cartPreview = true;
        },
        hideCartPreview() {
            this.cartPreview = false;
        },
    },
};
