var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideButtonInCatalog),expression:"!hideButtonInCatalog"}],staticClass:"product-action-wrap",class:{
        cart: _vm.location === 'cart',
        preview: _vm.location === 'preview',
        added: _vm.productCount,
        popup: _vm.inPopup,
        'in-catalog': _vm.inCatalog,
        'in-search': _vm.inSearch,
        disabled: _vm.disabled,
    }},[(_vm.isShowDefaultBtn)?_c('all-custom-buttons',{staticClass:"product-action",attrs:{"disabled":_vm.disabled},on:{"clickDetected":function($event){return _vm.addProduct()}}},[_c('p',{staticClass:"product-action__add-block",class:{ 'product-action__add-block_no-address': _vm.location === 'popup' && !_vm.addressSelected }},[(_vm.location === 'popup' && !_vm.addressSelected)?_c('span',{staticClass:"product-action__no-address"},[_c('address-map-icon'),_vm._v(" "),_c('span',[_vm._v("Выберите адрес, чтобы добавить в корзину")])],1):_vm._e(),_vm._v(" "),_c('span',{staticClass:"product-action__add-price"},[(_vm.priceBeforeDiscount && _vm.isShowDiscount)?_c('product-before-discount-price',{staticClass:"product-action__discount-price-before",attrs:{"total":_vm.priceBeforeDiscount}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"product-action__add"},[_c('meta',{attrs:{"itemprop":"price","content":_vm.price}}),_vm._v("\n\n                    "+_vm._s(_vm.formatValue(_vm.price))+" ₽\n                ")])],1)])]):_vm._e(),_vm._v(" "),(!_vm.productCount && _vm.viewCatalog == 'list' && _vm.canAddProduct && !_vm.showOnlyDelete)?_c('button',{staticClass:"btn product-action-list",attrs:{"aria-label":"Добявить блюдо"},on:{"click":function($event){$event.stopPropagation();return _vm.addProduct()}}},[_c('span',{staticClass:"product-action-list__price"},[_vm._v(_vm._s(_vm.formatValue(_vm.price))+" ₽")]),_vm._v(" "),_c('meta',{attrs:{"itemprop":"price","content":_vm.price}}),_vm._v(" "),_c('meta',{attrs:{"itemprop":"priceCurrency","content":"RUB"}})]):_vm._e(),_vm._v(" "),(_vm.productCount && !_vm.showOnlyDelete)?_c('div',{staticClass:"quanity-action",class:{
            'quanity-action_disabled': _vm.disabled,
        }},[[_c('button',{staticClass:"quanity-action__change-value quanity-action__decrease",attrs:{"aria-label":"Уменьшить количество блюд","disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.removeProduct()}}},[_c('minus',{attrs:{"type":"white"}})],1),_vm._v(" "),(_vm.location == 'catalog' || _vm.location == 'popup' || _vm.location == 'search')?_c('span',{staticClass:"product-action__add"},[_vm._v("\n                "+_vm._s(_vm.price * _vm.productCount)+" ₽\n            ")]):_c('span',{staticClass:"product-action__quanity"},[_vm._v("\n                "+_vm._s(_vm.productCount)+"\n            ")]),_vm._v(" "),_c('button',{staticClass:"quanity-action__change-value quanity-action__increase",attrs:{"aria-label":"Увеличить количество блюд","disabled":!_vm.canAddProduct || _vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.addProduct()}}},[_c('plus',{attrs:{"type":"white"}})],1)]],2):_vm._e(),_vm._v(" "),(_vm.showOnlyDelete)?_c('all-custom-buttons',{staticClass:"product-action__delete",on:{"clickDetected":function($event){return _vm.removeProduct()}}},[_c('trash')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }