import { Route } from "vue-router";

export function isIndexPage(route: Route): boolean {
    if (route.name === "index") {
        return true;
    }

    for (const match of route.matched || []) {
        if (match.name === "index") {
            return true;
        }
        if (match.parent && match.parent.name === "index") {
            return true;
        }
    }

    return false;
}

export function isPagesPage(route: Route): boolean {
    if (route.name === "pages") {
        return true;
    }

    for (const match of route.matched || []) {
        if (match.name === "pages") {
            return true;
        }
        if (match.parent && match.parent.name === "pages") {
            return true;
        }
    }

    return false;
}
