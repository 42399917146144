import Vue from "vue";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { CLEAR_ERRORS_POPUP_TIMEOUT, CLEAR_ERRORS_TIMEOUT, findAllKeys, getError, getErrors } from "../util/errors";
import { castArray, isEmpty } from "../util/helpers";
import { ErrorsState } from "~/store_types/errors.types";
import { RootState } from "~/store_types/index.types";
import { setByPath, unsetByPath } from "~/util/get-set";
import { cloneDeep } from "~/util/clone";

// initial state
export const state: () => ErrorsState = () => ({
    list: {},
    displayList: {},
    clearDisplayErrorTimeout: {},
});

// getters
export const getters: GetterTree<ErrorsState, RootState> = {};

// actions
export const actions: ActionTree<ErrorsState, RootState> = {
    addError({ commit, dispatch, state }, errors) {
        if (errors === undefined) {
            return;
        }

        for (const errName in errors) {
            commit("addError", {
                key: errName,
                value: errors[errName],
            });
        }
        dispatch("addDisplayError", { errors, timeout: CLEAR_ERRORS_TIMEOUT });
    },
    addDisplayError({ commit, dispatch, state }, payload) {
        const errors = payload.errors || payload;
        const timeout = payload.timeout || CLEAR_ERRORS_TIMEOUT;

        for (const errName in errors) {
            commit("addDisplayError", {
                key: errName,
                value: errors[errName],
            });

            if (state.clearDisplayErrorTimeout[errName]) {
                clearTimeout(state.clearDisplayErrorTimeout[errName]);
            }
            const clearTimeoutId = setTimeout(() => {
                dispatch("clearDisplayError", errName);
            }, timeout);

            commit("setDisplayErrorTimeout", {
                key: errName,
                timeoutId: clearTimeoutId,
            });
        }
    },
    addPopupError({ dispatch }, error) {
        dispatch(
            "modals/openModal",
            {
                modalName: "NotificationModal",
                modalData: { title: error.errorTitle },
            },
            { root: true }
        );
        dispatch("addDisplayError", {
            errors: { [error.errorKey || "error_modal"]: error.errorTitle || "Неизвестная ошибка" },
        });
    },
    clearError({ commit }, key) {
        commit("clearError", key);
    },
    clearDisplayError({ commit, state }, errName) {
        commit("clearDisplayError", errName);
        if (state.clearDisplayErrorTimeout[errName]) {
            clearTimeout(state.clearDisplayErrorTimeout[errName]);
        }
        commit("setDisplayErrorTimeout", {
            key: errName,
            timeoutId: null,
        });
    },

    checkErrors({ commit, state }, errors) {
        return new Promise((resolve, reject) => {
            castArray(errors).forEach((errorKey) => {
                if (!isEmpty(state.list[errorKey])) {
                    reject({ [errorKey]: state.list[errorKey] });
                }
            });
            resolve(false);
        });
    },
};

// mutations
export const mutations: MutationTree<ErrorsState> = {
    addError(state, error) {
        _addErrors(state, error, "list");
    },

    addDisplayError(state, error) {
        _addErrors(state, error, "displayList");
    },

    clearError(state, errorKey) {
        _clearErrors(state, errorKey, "list");
        _clearErrors(state, errorKey, "displayList");
    },

    clearDisplayError(state, errorKey) {
        _clearErrors(state, errorKey, "displayList");
    },

    clearAllDisplayErrors(state) {
        state.displayList = {};
        state.clearDisplayErrorTimeout = {};
    },

    setDisplayErrorTimeout(state, payload) {
        Vue.set(state.clearDisplayErrorTimeout, payload.key, payload.timeoutId);
    },
};

function _addErrors(state, error, listKey) {
    const newList = cloneDeep(state[listKey]);
    setByPath(newList, error.key, error.value);

    state[listKey] = newList;
}

function _clearErrors(state, errorKey, listKey) {
    let newList = {};
    if (errorKey) {
        newList = cloneDeep(state[listKey]);
        unsetByPath(newList, errorKey);
    }
    state[listKey] = newList;
}
