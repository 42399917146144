import { render, staticRenderFns } from "./ProductScore.vue?vue&type=template&id=2a3a68ab&scoped=true&"
import script from "./ProductScore.vue?vue&type=script&lang=js&"
export * from "./ProductScore.vue?vue&type=script&lang=js&"
import style0 from "./ProductScore.vue?vue&type=style&index=0&id=2a3a68ab&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a3a68ab",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BurgerClose: require('/usr/src/app/components/icons/BurgerClose.vue').default,CustomProgressBar: require('/usr/src/app/components/common/CustomProgressBar.vue').default})
