//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "CustomProgressBar",
    props: {
        max: { type: Number, default: 100 },
        current: { type: Number },
        bgColor: {
            type: String,
            default: "#f2f2f2",
        },
        barColor: {
            type: String,
            default: "#6DB700",
        },
        textColor: {
            type: String,
            default: "#242323",
        },
    },
    computed: {
        barPercent() {
            return `${(this.current / this.max) * 100}%`;
        },
    },
};
