import { render, staticRenderFns } from "./AttractiveOffers.vue?vue&type=template&id=4579e29a&scoped=true&"
import script from "./AttractiveOffers.vue?vue&type=script&lang=js&"
export * from "./AttractiveOffers.vue?vue&type=script&lang=js&"
import style0 from "./AttractiveOffers.vue?vue&type=style&index=0&id=4579e29a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4579e29a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductBeforeDiscountPrice: require('/usr/src/app/components/products/ProductBeforeDiscountPrice.vue').default,Shimmer: require('/usr/src/app/components/common/Shimmer.vue').default})
