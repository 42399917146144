//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "SetConsist",
    props: ["product", "productModifier"],
    data() {
        return {};
    },
    computed: {
        haveSetProducts() {
            return this.product.set_products?.length;
        },
    },
};
