//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "Arrow",
    props: {
        width: {
            type: [Number, String],
            default: 8.851,
        },
        height: {
            type: [Number, String],
            default: 15.703,
        },
        type: { type: String },
        direction: {
            type: String,
            default: "right",
        },
    },
    computed: {
        arrowDirection() {
            switch (this.direction) {
                case "right":
                    return {};
                case "left":
                    return { transform: "rotate(180deg)" };
                case "top":
                    return { transform: "rotate(-90deg)" };
                case "bottom":
                    return { transform: "rotate(90deg)" };
            }

            return "";
        },
    },
};
