import { ActionTree, GetterTree, MutationTree } from "vuex";
import { Route } from "vue-router";
import cityLinksHelper from "../util/city-links-helper";
import { ProductPopupState } from "~/store_types/productPopup.types";
import { RootState } from "~/store_types/index.types";
import { Gift, MenuProduct } from "~/util/api.types";
import { CatalogItemType } from "~/service/catalog-router/types";

interface AllowedMutation {
    field: string;
    mutation: string;
}

const allowedMutations: AllowedMutation[] = [
    { field: "product", mutation: "setProduct" },
    { field: "gift", mutation: "setGift" },
    { field: "colorProduct", mutation: "setColorProduct" },
    { field: "sel_modifiers", mutation: "setSelModifiers" },
    { field: "selModifiersPrice", mutation: "setSelModifiersPrice" },
    { field: "disableChange", mutation: "setDisableChange" },
    { field: "openInCart", mutation: "setOpenInCart" },
];

// for product popup open from cart with modifiers

export const state: () => ProductPopupState = () => ({
    product: null,
    isLoading: false,
    gift: null,
    colorProduct: "",
    isKitchenCommentsExpanded: false,
    sel_modifiers: null,
    selModifiersPrice: null,
    isModifiersButtonClicked: false,
    disableChange: false,
    openInCart: false,
    openIn: null,
});

export const getters: GetterTree<ProductPopupState, RootState> = {
    isShow(state) {
        return !!state.product;
    },
    isModifiersButtonClicked(state) {
        return state.isModifiersButtonClicked;
    },
};

export const mutations: MutationTree<ProductPopupState> = {
    setProduct(state, product: MenuProduct) {
        state.product = product;
    },
    setOpenIn(state, value: string) {
        state.openIn = value;
    },
    setGift(state, gift: Gift) {
        state.gift = gift;
    },
    setColorProduct(state, color: string) {
        state.colorProduct = color;
    },
    clearState(state) {
        state.product = null;
        state.gift = null;
        state.colorProduct = "";
        state.sel_modifiers = null;
        state.selModifiersPrice = null;
        state.disableChange = false;
        state.openInCart = false;
        state.openIn = null;
    },
    setIsLoading(state, value: boolean) {
        state.isLoading = value;
    },
    setSelModifiers(state, value) {
        state.sel_modifiers = value;
    },
    setSelModifiersPrice(state, value) {
        if (state.sel_modifiers) {
            state.selModifiersPrice = value;
        }
    },
    setModifiersButtonClicked(state, val) {
        state.isModifiersButtonClicked = val;
    },
    setDisableChange(state, val) {
        state.disableChange = val;
    },
    setOpenInCart(state, val) {
        state.openInCart = val;
    },
};

export const actions: ActionTree<ProductPopupState, RootState> = {
    showProductByPayload({ state, commit, dispatch, rootGetters }, payload: any) {
        for (const mutation of allowedMutations) {
            if (payload[mutation.field] !== undefined) {
                commit(mutation.mutation, payload[mutation.field]);
            }
        }

        if (!state.product) {
            return;
        }

        commit("setIsLoading", true);

        dispatch("products/loadProductById", state.product, { root: true })
            // Не будем ждать загрузки полной инфы о продукте, а сразу покажем его
            .then(() => {
                if (!state.product) {
                    return;
                }
                commit("setProduct", rootGetters["products/getProductFullInfo"](state.product.id));
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                commit("setIsLoading", false);
            });
    },
    async showProductByRoute({ dispatch, rootState }, route: Route) {
        const catalogItem = this.$catalogRouter.getItemByPath(cityLinksHelper.cleanCityPath(route.path));
        if (!catalogItem || catalogItem.type != CatalogItemType.PRODUCT) {
            return;
        }

        const product = (rootState.products.products || []).find((p) => p.id == catalogItem.id);
        if (!product) {
            return;
        }

        await dispatch("showProductByPayload", { product });
    },
    clearState({ commit }) {
        commit("clearState");
    },
    modifyCurrentGift({ state, commit }, gift: Gift | null) {
        if (state.gift && gift && state.gift.id == gift.id) {
            commit("setGift", gift);
        }
    },
};
