import { trimUrlSlashes } from "../service/sitemap-generator/helpers";
import { CITY_ID_EKB, DEFAULT_CITY_ID } from "./types";
import { cloneDeep } from "./clone";

export interface CityLinkItem {
    id: string;
    slug: string;
    prepos: string; // Предложный падеж названия города
}

export const EKD_CITY_LINK_ITEM: CityLinkItem = { id: "" + CITY_ID_EKB, slug: "", prepos: "Екатеринбурге" };

const cities: CityLinkItem[] = [
    { id: "" + CITY_ID_EKB, slug: "", prepos: "Екатеринбурге" },
    { id: "2", slug: "pervouralsk", prepos: "Первоуральске" },
    { id: "3", slug: "tagil", prepos: "Нижнем Тагиле" },
    { id: "4", slug: "kamensk", prepos: "Каменск-Уральском" },
    { id: "5", slug: "chelyabinsk", prepos: "Челябинске" },
    { id: "6", slug: "tyumen", prepos: "Тюмени" },
    { id: "7", slug: "pyshma", prepos: "Верхней Пышме" },
    { id: "9", slug: "bereza", prepos: "Берёзовском" },
    { id: "10", slug: "surgut", prepos: "Сургуте" },
    { id: "11", slug: "revda", prepos: "Ревде" },
    { id: "12", slug: "ryazan", prepos: "Рязань" },
    { id: "13", slug: "verkhnyaya-salda", prepos: "Верхней Салде" },
    { id: "15", slug: "orenburg", prepos: "Оренбурге" },
    { id: "18", slug: "sysert", prepos: "Сысерть" },
    { id: "19", slug: "polevskoy", prepos: "Полевской" },
    { id: "38", slug: "tobolsk", prepos: "Тобольске" },
];

const cityRegExp = new RegExp(`/(${getCitiesSlugs().join("|")})(/|$)`, "gi");

function getCitiesSlugs(): string[] {
    return cities.map((c) => c.slug);
}

function getCityItems(): CityLinkItem[] {
    return cloneDeep([EKD_CITY_LINK_ITEM, ...cities]);
}

export type CityItemsMap = { [key: string]: CityLinkItem };

function getCityItemsAsMap(): CityItemsMap {
    return getCityItems().reduce((carry, item) => {
        carry[item.id] = item;
        return carry;
    }, {});
}

function getRouterRegExpPath(path) {
    return `/:cityName(${getCitiesSlugs().join("|")})?${path}`;
}

function getNuxtLinkToPath(path, cityId: number | string | null = null) {
    if (!cityId) {
        return path;
    }

    for (const city of cities) {
        if (city.id == cityId) {
            return getNuxtLinkToPathByLinkItem(path, city);
        }
    }

    return path;
}

function getNuxtLinkToPathByLinkItem(path: string, item: CityLinkItem) {
    if (item.slug.length === 0) {
        return path;
    }
    return "/" + trimUrlSlashes(`${item.slug}${path}`);
}

function getCityIdFromVuex(vuexOrRootState): string {
    const state = vuexOrRootState.state ? vuexOrRootState.state : vuexOrRootState;
    if (!state || !state.delivery || !state.delivery.city || !state.delivery.city.id) {
        return String(DEFAULT_CITY_ID);
    }
    return String(state.delivery.city.id);
}

function cleanCityPath(path: string, isTrimQuery = false): string {
    let [baseUrl, query] = path.split("?", 2);

    baseUrl = baseUrl.replace(cityRegExp, "/").replace(/\/+/, "/").replace(/\/$/, "");

    return !isTrimQuery && query !== undefined ? `${baseUrl}?${query}` : baseUrl;
}

function getCityItemFromPath(path: string): CityLinkItem | undefined {
    const match = path.match(cityRegExp); // [0].replace('/', '')
    if (!match) {
        return undefined;
    }
    const slug = match[0].replace(/\//g, "");
    return cities.find((c) => c.slug == slug);
}

function getCityItemById(cityId?: any): CityLinkItem | undefined {
    return cities.find((c) => c.id == cityId);
}

function getCityPrepositionalTitle(cityId?: any) {
    const item = cityId == CITY_ID_EKB ? EKD_CITY_LINK_ITEM : getCityItemById(cityId);

    return item?.prepos;
}

export default {
    getCitiesSlugs,
    getCityItems,
    getCityItemsAsMap,
    getRouterRegExpPath,
    getNuxtLinkToPath,
    getNuxtLinkToPathByLinkItem,
    getCityIdFromVuex,
    cleanCityPath,
    getCityItemFromPath,
    getCityItemById,
    getCityPrepositionalTitle,
};
