import Vue from "vue";
import { META_PAGE_TITLE_FIELD, getMetaPageTitleFromComponentOptions } from "../service/meta-helpers";

declare module "vue/types/vue" {
    interface Vue {
        metaPageTitle?: string;
    }
}

function registerMixin() {
    // @ts-ignore
    if (Vue.__hasMetaPageTitleMixin__) {
        /* eslint-disable-line dot-notation */
        return;
    }
    Vue["__hasMetaPageTitleMixin__"] = true; /* eslint-disable-line dot-notation */

    Vue.mixin({
        created() {
            const opts: any = this["$options"]; /* eslint-disable-line dot-notation */
            if (opts && opts[META_PAGE_TITLE_FIELD]) {
                const metaPageTitle = getMetaPageTitleFromComponentOptions(
                    // @ts-ignore
                    this.$route,
                    opts
                ); /* eslint-disable-line dot-notation */
                // @ts-ignore
                this.metaPageTitle = metaPageTitle || "";
            }
        },
    });
}

registerMixin();
