//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from "vuex";
import CameraIcon from "~/components/icons/CameraIcon.vue";
import CameraIconCircle from "~/components/icons/CameraIconCircle.vue";

export default {
    name: "CameraLive",
    components: { CameraIconCircle, CameraIcon },
    props: ["menuPopup"],
    computed: {
        ...mapState("delivery", ["city"]),
        hasCamersInCity() {
            const cityIds = [1, 2, 5, 10, 12];
            return cityIds.findIndex((id) => id == this.city.id) >= 0;
        },
    },
    methods: {
        ...mapActions("modals", ["openModal"]),
        openPopupCamers() {
            this.openModal({ modalName: "VideocameraOnline" });
        },
    },
};
