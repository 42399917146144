//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from "vuex";
import ProductBeforeDiscountPrice from "~/components/products/ProductBeforeDiscountPrice.vue";
import Shimmer from "~/components/common/Shimmer.vue";

export default {
    name: "AttractiveOffers",
    components: { Shimmer, ProductBeforeDiscountPrice },
    props: ["product", "loading"],
    data() {
        return {};
    },
    computed: {
        ...mapState("products", ["products"]),
        ...mapState("metrika", ["paramsAB"]),
        offers() {
            return this.product.attractive_offers;
        },
    },
    mounted() {},
    methods: {
        ...mapMutations("productPopup", ["setOpenIn"]),
        async offerClick(product) {
            await this.$store.dispatch("productPopup/clearState");
            const parts = this.$route.path.split("/");
            this.setOpenIn(`/${parts[1]}`);
            await this.$store.dispatch("productPopup/showProductByPayload", {
                product,
            });
        },
    },
};
