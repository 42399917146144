import { render, staticRenderFns } from "./ProductConsist.vue?vue&type=template&id=3caefa46&scoped=true&"
import script from "./ProductConsist.vue?vue&type=script&lang=js&"
export * from "./ProductConsist.vue?vue&type=script&lang=js&"
import style0 from "./ProductConsist.vue?vue&type=style&index=0&id=3caefa46&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3caefa46",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductEnergyTable: require('/usr/src/app/components/products/ProductEnergyTable.vue').default,SetConsist: require('/usr/src/app/components/products/SetConsist.vue').default,CustomAccordion: require('/usr/src/app/components/common/CustomAccordion.vue').default})
