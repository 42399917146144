export const PAYMENT_IFRAME_QUERY_KEY = "external";

export enum PaymentType {
    CASH = "cash",
    CASHLESS = "cashless",
    ONLINE = "online",
    QR = "qr",
}

export enum PaymentOnlineType {
    APPLE_PAY = "apple",
    GOOGLE_PAY = "google",
    CARD = "online",
    SBERPAY = "sber",
}

export enum PaymentBank {
    CLOUD_PAYMENTS = "cloud",
    SBER = "sber",
    TOCHKA = "tochka",
    TINKOFF = "tinkoff",
    UBRIR = "ubrir",
}

export interface Payment {
    id: string;
    bank?: PaymentBank | null;
    ready?: boolean;
    merchant?: any | null;
    type: PaymentType;
    name?: string;
    online_type?: PaymentOnlineType;
}

export interface PaymentState {
    bonusCardUserToken: string | null;
    bonusCardCode: string | null;
    hasBonusCard: boolean;
    bonusPoints: number;
    currentPaymentType: Payment | null;
    availablePayments: Payment[];
    paymentReady: Object;
    qrCode: any | null;
    saveCard: boolean;
    paymentCardId: number | null;
    paymentToken: any | null;
    change_from: string | null;
    sms_subscription: boolean;
    email_subscription: boolean;
    // user_agreement: boolean;
    requireAuth: boolean;
    paymentUrl: string | null;
    qrId: string | null;
    qrUrl: string | null;
}
