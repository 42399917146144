import config from "../config/index";

export function isEmptyString(str) {
    if (typeof str !== "string") {
        return true;
    }
    return str.trim().length == 0;
}

export function isAddressEmpty(address) {
    return !address || Object.keys(address).length === 0 || !address.street || !address.house;
}

export function getEcommerceProductData(payload) {
    const metadata = {};
    if (payload.id) {
        metadata.id = payload.id;
    }
    if (payload.name) {
        metadata.name = payload.name;
    }
    if (payload.price) {
        metadata.price = payload.price;
    }
    if (payload.brand) {
        metadata.brand = payload.brand;
    }
    if (payload.category) {
        metadata.category = payload.category;
    }

    return metadata;
}

export function getTime() {
    return Math.floor(new Date().getTime() / 1000);
}

export function getCarPhone(deliveryDepartment, raw) {
    const phones = {
        40: "+7(912) 244 51 24",
        55: "+7(919) 375 35 69",
        49: "+7(919) 120 00 77",
        62: "+7(999) 562-84-81",
    };

    if (phones[deliveryDepartment] != undefined) {
        return raw
            ? phones[deliveryDepartment].replace(/[^\d+]/g, "")
            : phones[deliveryDepartment].replace(/\s+/g, "&nbsp;");
    }
}

export function getNowTime() {
    return Math.floor(new Date().getTime() / 1000);
}

export function getUniqueId() {
    const s4 = function () {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

export function isMobile() {
    return window.matchMedia("(max-width: 1024px)").matches;
}

export function throttle(func, limit) {
    let lastFunc;
    let lastRan;
    return function () {
        const context = this;
        const args = arguments;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function () {
                if (Date.now() - lastRan >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
}

export function debounce(func, limit) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, limit);
    };
}

export function iOS() {
    if (!navigator) {
        return false;
    }
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}

// export function getHotLine(){
//     return document.querySelector('#hotLinePhone').innerHTML
// }

export function generateMessengerConfirmUrl(url, phone) {
    return url.replace("{phone}", encodeURI(phone));
}

export function plural(n, s1, s2, s5) {
    const n10 = n % 10;
    const n100 = n % 100;

    if (n == 0 || (n100 >= 10 && n100 <= 20) || n10 >= 5 || n10 == 0) {
        return s5;
    } else if (n10 == 1) {
        return s1;
    }

    return s2;
}

export function castArray(v) {
    return Array.isArray(v) ? v : [v];
}

export function isNumeric(v) {
    return isFinite(v);
}

export function pick(obj, keys) {
    if (!obj) {
        return {};
    }

    const output = {};

    for (const key of keys) {
        if (obj[key] === undefined) {
            continue;
        }
        output[key] = obj[key];
    }

    return output;
}

export function orderBy(arr, field = null, order = "asc") {
    const orderNumber = order === "desc" ? -1 : 1;
    return arr.sort((a, b) => {
        const aValue = field ? a[field] || 0 : a;
        const bValue = field ? b[field] || 0 : b;

        if (Number.isFinite(aValue) && Number.isFinite(bValue)) {
            let returnValue = 0;
            if (aValue < bValue) {
                returnValue = -1;
            } else if (aValue > bValue) {
                returnValue = 1;
            }
            return returnValue * orderNumber;
        }

        return String(aValue).localeCompare(String(bValue)) * orderNumber;
    });
}

export function isEmpty(obj) {
    if (obj === null || obj === undefined) {
        return true;
    }
    if (typeof obj === "object") {
        return Object.keys(obj).length === 0;
    }
    if (Array.isArray(obj)) {
        return obj.length === 0;
    }
    return !obj;
}

export function randInt(min, max) {
    return Math.round(min + (max - min) * Math.random());
}

export function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export function intersect(a, b) {
    const setB = new Set(b);
    return [...new Set(a)].filter((x) => setB.has(x));
}

export function sanitizeHtml(html) {
    return html.replace(/</g, "&lt;").replace(/>/g, "&gt;");
}

export function numberWithSpaces(x) {
    if (!x && x !== 0) {
        return;
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export async function time(title, f) {
    // const startTime = Date.now();
    await f();
    // console.log(`[TIME] [${title}] took ${(Date.now() - startTime) / 1000} seconds`);
}

export function normalizePhone(phoneNumberString) {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
}

export function copyText(val) {
    const elem = document.createElement("textarea");
    elem.value = val;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
}

export function transliterate(word) {
    const keys = {
        а: "a",
        б: "b",
        в: "v",
        г: "g",
        д: "d",
        е: "e",
        ё: "e",
        ж: "j",
        з: "z",
        и: "i",
        й: "i",
        к: "k",
        л: "l",
        м: "m",
        н: "n",
        о: "o",
        п: "p",
        р: "r",
        с: "s",
        т: "t",
        у: "u",
        ф: "f",
        х: "h",
        ц: "c",
        ч: "ch",
        ш: "sh",
        щ: "shch",
        ы: "y",
        э: "e",
        ю: "u",
        я: "ya",
        ъ: "",
        ь: "",
        А: "A",
        Б: "B",
        В: "V",
        Г: "G",
        Д: "D",
        Е: "E",
        Ё: "E",
        Ж: "J",
        З: "Z",
        И: "I",
        Й: "I",
        К: "K",
        Л: "L",
        М: "M",
        Н: "N",
        О: "O",
        П: "P",
        Р: "R",
        С: "S",
        Т: "T",
        У: "U",
        Ф: "F",
        Х: "H",
        Ц: "C",
        Ч: "CH",
        Ш: "SH",
        Щ: "SHCH",
        Ы: "Y",
        Э: "E",
        Ю: "U",
        Я: "YA",
        Ъ: "",
        Ь: "",
    };
    return word
        .split("")
        .map((char) => (keys[char] !== undefined ? keys[char] : char))
        .join("");
}

export function keyBy(arr, key) {
    return arr.reduce((carry, item) => {
        carry[item[key]] = item;
        return carry;
    }, {});
}

export function checkMobileBrowser(userAgent) {
    try {
        return (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(-n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(-m|-p|-t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-a-w)|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                    a.substr(0, 4)
                )
            ) {
                return true;
            }
            return false;
        })(userAgent);
    } catch (err) {
        return false;
    }
}

export function isTestSite() {
    return config.IS_TEST_SITE == 1;
}

export function isEqualObjects(object1, object2) {
    const props1 = Object.getOwnPropertyNames(object1);
    const props2 = Object.getOwnPropertyNames(object2);

    if (props1.length !== props2.length) {
        return false;
    }

    for (let i = 0; i < props1.length; i += 1) {
        const prop = props1[i];
        const bothAreObjects = typeof object1[prop] === "object" && typeof object2[prop] === "object";

        if (
            (!bothAreObjects && object1[prop] !== object2[prop]) ||
            (bothAreObjects && !isEqualObjects(object1[prop], object2[prop]))
        ) {
            return false;
        }
    }

    return true;
}

export function webpToJpg(path) {
    // замена последнего вхождения в строку .webp
    if (!path) {
        return;
    }
    return path.replace(/(.webp*)$/, ".jpg");
}
