import { Config } from "~/config/types";

const JSON_MARKER = "<JSON>";

function transformValue(value: string): any {
    if (value.indexOf(JSON_MARKER) === 0) {
        return JSON.parse(value.substring(JSON_MARKER.length));
    }
    return value;
}

export function applyEnvConfig(baseConfig: Config, envConfig: Partial<Config>) {
    for (const key in envConfig) {
        const value = envConfig[key];
        if (typeof value !== "string") {
            continue;
        }
        baseConfig[key] = transformValue(value);
    }
}
