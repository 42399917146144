//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import cityLinksHelper from "~/util/city-links-helper";
import { CITY_ID_EKB } from "~/util/types";
import { time } from "~/util/helpers";
import launchCityHelper from "~/service/launch-city-helper/index";

let path = null;
let fsHelpers = null;
if (!process.client) {
    path = require("path");
    fsHelpers = require("~/util/fs-helpers").default;
}

export default {
    name: "Metrika",
    async fetch() {
        await time("Metrika.vue fetch", async () => {
            if (process.client) {
                return;
            }
            if (!path || !fsHelpers) {
                return;
            }

            this.metrikaKey = this.getMetrikaKey();

            if (!this.metrikaKey || this.metrikaKey.length == 0) {
                return;
            }

            for (const key of this.metrikaKey) {
                try {
                    const htmlPath = path.resolve("", `./components/metrika/${key}.html`);
                    // eslint-disable-next-line no-await-in-loop
                    const htmlPathExist = await fsHelpers.exists(htmlPath);

                    if (!htmlPathExist) {
                        return;
                    }

                    // eslint-disable-next-line no-await-in-loop
                    const buffer = await fsHelpers.readFile(htmlPath);
                    this.content.push(this.preProcessMetrika(buffer.toString()));
                } catch (err) {
                    // console.log('** error while read metrika file', err);
                }
            }
        });
    },
    data() {
        return {
            metrikaKey: [],
            content: [],
        };
    },
    computed: { ...mapState("metrika", ["paramsAB"]) },
    methods: {
        getMetrikaKey() {
            const cityId = cityLinksHelper.getCityIdFromVuex(this.$store);

            const metrikaKey = ["common"];

            const launchCityItem = launchCityHelper.getLaunchCityItemByPath(this.$route.path);
            if (launchCityItem) {
                metrikaKey.push(launchCityItem.path.replace(/\//g, ""));
                return metrikaKey;
            }

            if (cityId == CITY_ID_EKB) {
                metrikaKey.push("ekb");
                return metrikaKey;
            }

            const cityLinkItem = cityLinksHelper.getCityItemById(cityId);
            if (cityLinkItem) {
                metrikaKey.push(cityLinkItem.slug);

                // Метрика для лендинга тюмени
                if (cityLinkItem.slug === "tyumen" && this.$route.path.includes("/tyumen-restart")) {
                    metrikaKey.push("tyumen-restart");
                }
                return metrikaKey;
            }
        },
        preProcessYaMetrikaAB(content) {
            // устанавливаем значение для АБ-тестирование
            // в шаблоне прописано B, при необходимости заменяем на А
            const abc = this.paramsAB;
            if (abc == "B") {
                return content;
            }
            return content.replace("ab: 'B'", `ab: '${abc}'`);
        },
        preProcessYaMetrikaXY(content) {
            const xy = this.$store.state.metrika.paramsXY;
            // в шаблоне пустая строка ''
            if (xy == "") {
                return content;
            }
            return content.replace("xy: ''", `xy: '${xy}'`);
        },
        preProcessMetrika(content) {
            let output = content;
            output = this.preProcessYaMetrikaAB(output);
            output = this.preProcessYaMetrikaXY(output);
            return output;
        },
    },
};
