//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from "vuex";
import LogoMobile from "~/components/icons/LogoMobile.vue";
import CrossSmall from "~/components/icons/CrossSmall.vue";
import { sendYandexMetric } from "~/service/ecommerce";

export default {
    name: "MobileAppBanner",
    components: { CrossSmall, LogoMobile },
    data: () => ({}),
    computed: {
        ...mapGetters("modals", ["anyOpenModals"]),
        getLink() {
            if (this.iOS) {
                return "https://itunes.apple.com/ru/app/%D1%81%D1%83%D1%88%D0%BA%D0%BE%D1%84-%D0%B8-%D0%B4%D0%B5%D0%BB%D1%8C-%D0%BF%D0%B5%D1%81%D1%82%D0%BE/id1434266039";
            }

            if (this.isAndroid) {
                return "market://details?id=ru.sushkof.android&hl=ru";
            }

            return "";
        },
        iOS() {
            return this.$device.isIos;
        },
        isAndroid() {
            return this.$device.isAndroid;
        },
    },
    methods: {
        ...mapActions("account", ["setAppBanner"]),
        closeBanner() {
            sendYandexMetric("close-banner-heder-app");
            this.setAppBanner(false);
        },
        redirect() {
            if (process.browser) {
                window.location.href = this.getLink;
            }
        },
    },
};
