//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations, mapState } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SearchIcon from "~/components/icons/SearchIcon.vue";
import Container from "~/components/common/Container.vue";
import { isIndexPage } from "~/service/catalog-router/helpers";
// import 'swiper/css/swiper.css'

export default {
    name: "MenuCategory",
    components: {
        Container,
        SearchIcon,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiperPosition: {
                isBeginning: true,
                isEnd: false,
            },
            menuPosition: 0,
            currentScrollPosition: 0,
            isEventTouch: false,
        };
    },
    computed: {
        ...mapState("products", ["anotherMenu", "currentSort", "currentViewCategoryId"]),
        ...mapGetters("products", ["getCategories"]),
        ...mapGetters("modals", ["getSearchOpen"]),
        swiperOption() {
            return {
                slidesPerView: "auto",
                spaceBetween: 0,
                freeMode: true,
                centeredSlides: true,
                centeredSlidesBounds: true,
                pagination: { clickable: true },
                watchSlidesVisibility: true,
                on: {
                    init: () => {
                        this.$nuxt.$emit("on-catalog-categories-swiper-init");
                    },
                },
            };
        },
        inStartPosition() {
            return this.swiperPosition.isBeginning;
        },
        inEndPosition() {
            return this.swiperPosition.isEnd;
        },
        routeIsCatalog() {
            return isIndexPage(this.$router.currentRoute);
        },
    },
    watch: {
        currentViewCategoryId() {
            setTimeout(() => {
                this.swiperPosition = {
                    isBeginning: this.$refs.mySwiper?.swiperInstance?.isBeginning,
                    isEnd: this.$refs.mySwiper?.swiperInstance?.isEnd,
                };
            }, 300);
        },
    },

    mounted() {
        setTimeout(() => {
            this.swiperPosition = {
                isBeginning: this.$refs.mySwiper?.swiperInstance?.isBeginning,
                isEnd: this.$refs.mySwiper?.swiperInstance?.isEnd,
            };
        }, 300);
    },
    methods: {
        ...mapMutations("modals", ["setSearchOpen"]),
        categoryTitle(category) {
            return category.id !== this.anotherMenu
                ? category.title
                : category.title.replace(/у/gi, "<span·class='revert'>у</span>");
        },
        getDestination(category) {
            return this.$catalogRouter.getCategoryPath(category);
        },
        scrollToCategory(path) {
            this.$nuxt.$emit("scroll-to-path", path);
            setTimeout(() => {
                this.swiperPosition = {
                    isBeginning: this.$refs.mySwiper?.swiperInstance?.isBeginning,
                    isEnd: this.$refs.mySwiper?.swiperInstance?.isEnd,
                };
            }, 300);
        },
        toggleSearch() {
            this.setSearchOpen(!this.getSearchOpen);
        },
    },
};
