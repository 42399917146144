import { Plugin } from "@nuxt/types";
import { Context, Inject } from "@nuxt/types/app";
import { Api, PostCartQueue } from "../service/api";
import { encodeIP, generateUuid } from "../service/api/helpers";
import { DEFAULT_COOKIES_OPTIONS } from "~/service/cookies-helper";
import { getIpFromNuxtContext } from "~/util/ip-helpers";

function parseAuthFromCookies(context: Context) {
    let uuid = "";
    let auth = "";

    // UUID
    uuid = context.app.$cookies.get("uuid");
    if (!uuid) {
        uuid = generateUuid();
        context.app.$cookies.set("uuid", uuid, DEFAULT_COOKIES_OPTIONS);
    }

    // Authorization
    auth = context.app.$cookies.get("auth");

    return { uuid, auth };
}

const exportPlugin: Plugin = (context: Context, inject: Inject) => {
    if (!process.client) {
        const ip = getIpFromNuxtContext(context);
        if (ip) {
            context.store.commit("setEncodedIP", encodeIP(ip));
        }
    }

    const { uuid, auth } = parseAuthFromCookies(context);
    const api = new Api(uuid, auth, context.store.state.encodedIP || undefined);

    inject("api", api);
    inject("postCartQueue", new PostCartQueue(api, context));
};

export default exportPlugin;
