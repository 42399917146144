import { AbstractStorage } from "../abstract-storage/index";
import { URLS_STORAGE_KEY_PREFIX } from "./types";

export class UrlsStorage extends AbstractStorage {
    /*
        ХРАНИТ ТОЛЬКО СЛЕДУЮЩИЕ ДАННЫЕ:
        - Урлы категорий и продуктов со старого сайта
        - Сгенерированные относительные slug'и для продуктов
     */

    protected getKey() {
        return URLS_STORAGE_KEY_PREFIX;
    }

    protected isCloneValuesOnGet(): boolean {
        return false;
    }

    protected getUpdatePeriod(): number | null {
        return null;
    }

    protected getRemovePeriod(): number | null {
        return null;
    }

    protected getLogName() {
        return "URLS_STORAGE";
    }

    protected apiRequest() {
        return {};
    }
}
