//
//
//
//
//
//
//
//
//
//

import cityLinksHelper from "~/util/city-links-helper";

export default {
    name: "NuxtCityLink",
    props: ["to"],
    computed: {
        patchedTo() {
            return cityLinksHelper.getNuxtLinkToPath(this.to, cityLinksHelper.getCityIdFromVuex(this.$store));
        },
    },
};
