import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  AllCustomButtons: () => import('../../components/common/AllCustomButtons.vue' /* webpackChunkName: "components/all-custom-buttons" */).then(c => wrapFunctional(c.default || c)),
  AppOnlineChecker: () => import('../../components/common/AppOnlineChecker.vue' /* webpackChunkName: "components/app-online-checker" */).then(c => wrapFunctional(c.default || c)),
  Container: () => import('../../components/common/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c)),
  CustomAccordion: () => import('../../components/common/CustomAccordion.vue' /* webpackChunkName: "components/custom-accordion" */).then(c => wrapFunctional(c.default || c)),
  CustomButton: () => import('../../components/common/CustomButton.vue' /* webpackChunkName: "components/custom-button" */).then(c => wrapFunctional(c.default || c)),
  CustomCheckbox: () => import('../../components/common/CustomCheckbox.vue' /* webpackChunkName: "components/custom-checkbox" */).then(c => wrapFunctional(c.default || c)),
  CustomProgressBar: () => import('../../components/common/CustomProgressBar.vue' /* webpackChunkName: "components/custom-progress-bar" */).then(c => wrapFunctional(c.default || c)),
  CustomСounter: () => import('../../components/common/CustomСounter.vue' /* webpackChunkName: "components/customсounter" */).then(c => wrapFunctional(c.default || c)),
  DatetimePicker: () => import('../../components/common/DatetimePicker.vue' /* webpackChunkName: "components/datetime-picker" */).then(c => wrapFunctional(c.default || c)),
  DottedLoader: () => import('../../components/common/DottedLoader.vue' /* webpackChunkName: "components/dotted-loader" */).then(c => wrapFunctional(c.default || c)),
  ExternalPhoneConfirm: () => import('../../components/common/ExternalPhoneConfirm.vue' /* webpackChunkName: "components/external-phone-confirm" */).then(c => wrapFunctional(c.default || c)),
  GuestbookForm: () => import('../../components/common/GuestbookForm.vue' /* webpackChunkName: "components/guestbook-form" */).then(c => wrapFunctional(c.default || c)),
  ImageFilePreview: () => import('../../components/common/ImageFilePreview.vue' /* webpackChunkName: "components/image-file-preview" */).then(c => wrapFunctional(c.default || c)),
  Loading: () => import('../../components/common/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c)),
  Maps: () => import('../../components/common/Maps.vue' /* webpackChunkName: "components/maps" */).then(c => wrapFunctional(c.default || c)),
  MaxQuantityPopover: () => import('../../components/common/MaxQuantityPopover.vue' /* webpackChunkName: "components/max-quantity-popover" */).then(c => wrapFunctional(c.default || c)),
  NativeDatetimePicker: () => import('../../components/common/NativeDatetimePicker.vue' /* webpackChunkName: "components/native-datetime-picker" */).then(c => wrapFunctional(c.default || c)),
  NuxtCityLink: () => import('../../components/common/NuxtCityLink.vue' /* webpackChunkName: "components/nuxt-city-link" */).then(c => wrapFunctional(c.default || c)),
  OrderProductScore: () => import('../../components/common/OrderProductScore.vue' /* webpackChunkName: "components/order-product-score" */).then(c => wrapFunctional(c.default || c)),
  OtpInputs: () => import('../../components/common/OtpInputs.vue' /* webpackChunkName: "components/otp-inputs" */).then(c => wrapFunctional(c.default || c)),
  Preloader: () => import('../../components/common/Preloader.vue' /* webpackChunkName: "components/preloader" */).then(c => wrapFunctional(c.default || c)),
  ProductImg: () => import('../../components/common/ProductImg.vue' /* webpackChunkName: "components/product-img" */).then(c => wrapFunctional(c.default || c)),
  QrCode: () => import('../../components/common/QrCode.vue' /* webpackChunkName: "components/qr-code" */).then(c => wrapFunctional(c.default || c)),
  ResizeTextArea: () => import('../../components/common/ResizeTextArea.vue' /* webpackChunkName: "components/resize-text-area" */).then(c => wrapFunctional(c.default || c)),
  Shimmer: () => import('../../components/common/Shimmer.vue' /* webpackChunkName: "components/shimmer" */).then(c => wrapFunctional(c.default || c)),
  Test: () => import('../../components/common/Test.vue' /* webpackChunkName: "components/test" */).then(c => wrapFunctional(c.default || c)),
  TimeSelect: () => import('../../components/common/TimeSelect.vue' /* webpackChunkName: "components/time-select" */).then(c => wrapFunctional(c.default || c)),
  ServerStorageKeySection: () => import('../../components/common/api-storages/ServerStorageKeySection.vue' /* webpackChunkName: "components/server-storage-key-section" */).then(c => wrapFunctional(c.default || c)),
  PaymentTypes: () => import('../../components/common/order/PaymentTypes.vue' /* webpackChunkName: "components/payment-types" */).then(c => wrapFunctional(c.default || c)),
  SupplyRequestDecisionBlock: () => import('../../components/common/supply_request/SupplyRequestDecisionBlock.vue' /* webpackChunkName: "components/supply-request-decision-block" */).then(c => wrapFunctional(c.default || c)),
  SupplyRequestInfoBlock: () => import('../../components/common/supply_request/SupplyRequestInfoBlock.vue' /* webpackChunkName: "components/supply-request-info-block" */).then(c => wrapFunctional(c.default || c)),
  SupplyRequestListBlock: () => import('../../components/common/supply_request/SupplyRequestListBlock.vue' /* webpackChunkName: "components/supply-request-list-block" */).then(c => wrapFunctional(c.default || c)),
  SupplyRequestMultiRequestBlock: () => import('../../components/common/supply_request/SupplyRequestMultiRequestBlock.vue' /* webpackChunkName: "components/supply-request-multi-request-block" */).then(c => wrapFunctional(c.default || c)),
  SupplyRequestTable: () => import('../../components/common/supply_request/SupplyRequestTable.vue' /* webpackChunkName: "components/supply-request-table" */).then(c => wrapFunctional(c.default || c)),
  PaymentCash: () => import('../../components/common/order/tabs/PaymentCash.vue' /* webpackChunkName: "components/payment-cash" */).then(c => wrapFunctional(c.default || c)),
  AddressAutoComplete: () => import('../../components/AddressAutoComplete.vue' /* webpackChunkName: "components/address-auto-complete" */).then(c => wrapFunctional(c.default || c)),
  AddressSelectMenu: () => import('../../components/AddressSelectMenu.vue' /* webpackChunkName: "components/address-select-menu" */).then(c => wrapFunctional(c.default || c)),
  AuthBlock: () => import('../../components/AuthBlock.vue' /* webpackChunkName: "components/auth-block" */).then(c => wrapFunctional(c.default || c)),
  BirthdateSelector: () => import('../../components/BirthdateSelector.vue' /* webpackChunkName: "components/birthdate-selector" */).then(c => wrapFunctional(c.default || c)),
  BonusAddToWallet: () => import('../../components/BonusAddToWallet.vue' /* webpackChunkName: "components/bonus-add-to-wallet" */).then(c => wrapFunctional(c.default || c)),
  BonusCardForm: () => import('../../components/BonusCardForm.vue' /* webpackChunkName: "components/bonus-card-form" */).then(c => wrapFunctional(c.default || c)),
  BonusCardQr: () => import('../../components/BonusCardQr.vue' /* webpackChunkName: "components/bonus-card-qr" */).then(c => wrapFunctional(c.default || c)),
  CameraLive: () => import('../../components/CameraLive.vue' /* webpackChunkName: "components/camera-live" */).then(c => wrapFunctional(c.default || c)),
  Cards: () => import('../../components/Cards.vue' /* webpackChunkName: "components/cards" */).then(c => wrapFunctional(c.default || c)),
  CategoryCatalog: () => import('../../components/CategoryCatalog.vue' /* webpackChunkName: "components/category-catalog" */).then(c => wrapFunctional(c.default || c)),
  CircleLoader: () => import('../../components/CircleLoader.vue' /* webpackChunkName: "components/circle-loader" */).then(c => wrapFunctional(c.default || c)),
  ConfirmPhoneMethods: () => import('../../components/ConfirmPhoneMethods.vue' /* webpackChunkName: "components/confirm-phone-methods" */).then(c => wrapFunctional(c.default || c)),
  ConnectInfo: () => import('../../components/ConnectInfo.vue' /* webpackChunkName: "components/connect-info" */).then(c => wrapFunctional(c.default || c)),
  CustomPage: () => import('../../components/CustomPage.vue' /* webpackChunkName: "components/custom-page" */).then(c => wrapFunctional(c.default || c)),
  DefaultInfoPopover: () => import('../../components/DefaultInfoPopover.vue' /* webpackChunkName: "components/default-info-popover" */).then(c => wrapFunctional(c.default || c)),
  FeedbackInfo: () => import('../../components/FeedbackInfo.vue' /* webpackChunkName: "components/feedback-info" */).then(c => wrapFunctional(c.default || c)),
  FilterProducts: () => import('../../components/FilterProducts.vue' /* webpackChunkName: "components/filter-products" */).then(c => wrapFunctional(c.default || c)),
  HiddenBuyer: () => import('../../components/HiddenBuyer.vue' /* webpackChunkName: "components/hidden-buyer" */).then(c => wrapFunctional(c.default || c)),
  InputDefault: () => import('../../components/InputDefault.vue' /* webpackChunkName: "components/input-default" */).then(c => wrapFunctional(c.default || c)),
  LendingPage: () => import('../../components/LendingPage.vue' /* webpackChunkName: "components/lending-page" */).then(c => wrapFunctional(c.default || c)),
  MainBannerSlider: () => import('../../components/MainBannerSlider.vue' /* webpackChunkName: "components/main-banner-slider" */).then(c => wrapFunctional(c.default || c)),
  MapShops: () => import('../../components/MapShops.vue' /* webpackChunkName: "components/map-shops" */).then(c => wrapFunctional(c.default || c)),
  MenuDelimeter: () => import('../../components/MenuDelimeter.vue' /* webpackChunkName: "components/menu-delimeter" */).then(c => wrapFunctional(c.default || c)),
  Metrika: () => import('../../components/Metrika.vue' /* webpackChunkName: "components/metrika" */).then(c => wrapFunctional(c.default || c)),
  NewYearSanta: () => import('../../components/NewYearSanta.vue' /* webpackChunkName: "components/new-year-santa" */).then(c => wrapFunctional(c.default || c)),
  NewYearSnow: () => import('../../components/NewYearSnow.vue' /* webpackChunkName: "components/new-year-snow" */).then(c => wrapFunctional(c.default || c)),
  OnlinePaymentIFrame: () => import('../../components/OnlinePaymentIFrame.vue' /* webpackChunkName: "components/online-payment-i-frame" */).then(c => wrapFunctional(c.default || c)),
  PageNotFound: () => import('../../components/PageNotFound.vue' /* webpackChunkName: "components/page-not-found" */).then(c => wrapFunctional(c.default || c)),
  PaymentQr: () => import('../../components/PaymentQr.vue' /* webpackChunkName: "components/payment-qr" */).then(c => wrapFunctional(c.default || c)),
  PizzaManGame: () => import('../../components/PizzaManGame.vue' /* webpackChunkName: "components/pizza-man-game" */).then(c => wrapFunctional(c.default || c)),
  PopoverBlock: () => import('../../components/PopoverBlock.vue' /* webpackChunkName: "components/popover-block" */).then(c => wrapFunctional(c.default || c)),
  PreloaderSpinner: () => import('../../components/PreloaderSpinner.vue' /* webpackChunkName: "components/preloader-spinner" */).then(c => wrapFunctional(c.default || c)),
  PreloaderSpinnerUpdated: () => import('../../components/PreloaderSpinnerUpdated.vue' /* webpackChunkName: "components/preloader-spinner-updated" */).then(c => wrapFunctional(c.default || c)),
  RestaurantCard: () => import('../../components/RestaurantCard.vue' /* webpackChunkName: "components/restaurant-card" */).then(c => wrapFunctional(c.default || c)),
  SelectDefault: () => import('../../components/SelectDefault.vue' /* webpackChunkName: "components/select-default" */).then(c => wrapFunctional(c.default || c)),
  TabOrderStep: () => import('../../components/TabOrderStep.vue' /* webpackChunkName: "components/tab-order-step" */).then(c => wrapFunctional(c.default || c)),
  TetrisUnityGame: () => import('../../components/TetrisUnityGame.vue' /* webpackChunkName: "components/tetris-unity-game" */).then(c => wrapFunctional(c.default || c)),
  TetrisUnityGameMobile: () => import('../../components/TetrisUnityGameMobile.vue' /* webpackChunkName: "components/tetris-unity-game-mobile" */).then(c => wrapFunctional(c.default || c)),
  ToggleDefault: () => import('../../components/ToggleDefault.vue' /* webpackChunkName: "components/toggle-default" */).then(c => wrapFunctional(c.default || c)),
  TopCart: () => import('../../components/TopCart.vue' /* webpackChunkName: "components/top-cart" */).then(c => wrapFunctional(c.default || c)),
  ValidationHint: () => import('../../components/ValidationHint.vue' /* webpackChunkName: "components/validation-hint" */).then(c => wrapFunctional(c.default || c)),
  WalletBonusCardForm: () => import('../../components/WalletBonusCardForm.vue' /* webpackChunkName: "components/wallet-bonus-card-form" */).then(c => wrapFunctional(c.default || c)),
  Search: () => import('../../components/search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c)),
  TabsNavigation: () => import('../../components/account/TabsNavigation.vue' /* webpackChunkName: "components/tabs-navigation" */).then(c => wrapFunctional(c.default || c)),
  CertBanner: () => import('../../components/certificates/CertBanner.vue' /* webpackChunkName: "components/cert-banner" */).then(c => wrapFunctional(c.default || c)),
  CertConfirm: () => import('../../components/certificates/CertConfirm.vue' /* webpackChunkName: "components/cert-confirm" */).then(c => wrapFunctional(c.default || c)),
  CertDate: () => import('../../components/certificates/CertDate.vue' /* webpackChunkName: "components/cert-date" */).then(c => wrapFunctional(c.default || c)),
  CertFooter: () => import('../../components/certificates/CertFooter.vue' /* webpackChunkName: "components/cert-footer" */).then(c => wrapFunctional(c.default || c)),
  CertPayment: () => import('../../components/certificates/CertPayment.vue' /* webpackChunkName: "components/cert-payment" */).then(c => wrapFunctional(c.default || c)),
  CertificateCopyLink: () => import('../../components/certificates/CertificateCopyLink.vue' /* webpackChunkName: "components/certificate-copy-link" */).then(c => wrapFunctional(c.default || c)),
  CertificateImage: () => import('../../components/certificates/CertificateImage.vue' /* webpackChunkName: "components/certificate-image" */).then(c => wrapFunctional(c.default || c)),
  CertificateShowcase: () => import('../../components/certificates/CertificateShowcase.vue' /* webpackChunkName: "components/certificate-showcase" */).then(c => wrapFunctional(c.default || c)),
  CertsApp: () => import('../../components/certificates/CertsApp.vue' /* webpackChunkName: "components/certs-app" */).then(c => wrapFunctional(c.default || c)),
  CurrentCategory: () => import('../../components/functionality/CurrentCategory.vue' /* webpackChunkName: "components/current-category" */).then(c => wrapFunctional(c.default || c)),
  DragDrop: () => import('../../components/functionality/DragDrop.vue' /* webpackChunkName: "components/drag-drop" */).then(c => wrapFunctional(c.default || c)),
  HeaderAnimation: () => import('../../components/functionality/HeaderAnimation.vue' /* webpackChunkName: "components/header-animation" */).then(c => wrapFunctional(c.default || c)),
  AddToGPay: () => import('../../components/icons/AddToGPay.vue' /* webpackChunkName: "components/add-to-g-pay" */).then(c => wrapFunctional(c.default || c)),
  AddressMapIcon: () => import('../../components/icons/AddressMapIcon.vue' /* webpackChunkName: "components/address-map-icon" */).then(c => wrapFunctional(c.default || c)),
  AppQr: () => import('../../components/icons/AppQr.vue' /* webpackChunkName: "components/app-qr" */).then(c => wrapFunctional(c.default || c)),
  AppSroreBlack: () => import('../../components/icons/AppSroreBlack.vue' /* webpackChunkName: "components/app-srore-black" */).then(c => wrapFunctional(c.default || c)),
  ApplePayIcon: () => import('../../components/icons/ApplePayIcon.vue' /* webpackChunkName: "components/apple-pay-icon" */).then(c => wrapFunctional(c.default || c)),
  AppleStore: () => import('../../components/icons/AppleStore.vue' /* webpackChunkName: "components/apple-store" */).then(c => wrapFunctional(c.default || c)),
  Arrow: () => import('../../components/icons/Arrow.vue' /* webpackChunkName: "components/arrow" */).then(c => wrapFunctional(c.default || c)),
  ArrowBody: () => import('../../components/icons/ArrowBody.vue' /* webpackChunkName: "components/arrow-body" */).then(c => wrapFunctional(c.default || c)),
  ArrowBottomSlide: () => import('../../components/icons/ArrowBottomSlide.vue' /* webpackChunkName: "components/arrow-bottom-slide" */).then(c => wrapFunctional(c.default || c)),
  ArrowCircle: () => import('../../components/icons/ArrowCircle.vue' /* webpackChunkName: "components/arrow-circle" */).then(c => wrapFunctional(c.default || c)),
  ArrowLong: () => import('../../components/icons/ArrowLong.vue' /* webpackChunkName: "components/arrow-long" */).then(c => wrapFunctional(c.default || c)),
  ArrowNext: () => import('../../components/icons/ArrowNext.vue' /* webpackChunkName: "components/arrow-next" */).then(c => wrapFunctional(c.default || c)),
  ArrowSlide: () => import('../../components/icons/ArrowSlide.vue' /* webpackChunkName: "components/arrow-slide" */).then(c => wrapFunctional(c.default || c)),
  Auth: () => import('../../components/icons/Auth.vue' /* webpackChunkName: "components/auth" */).then(c => wrapFunctional(c.default || c)),
  BeforeSearch: () => import('../../components/icons/BeforeSearch.vue' /* webpackChunkName: "components/before-search" */).then(c => wrapFunctional(c.default || c)),
  BetClose: () => import('../../components/icons/BetClose.vue' /* webpackChunkName: "components/bet-close" */).then(c => wrapFunctional(c.default || c)),
  BurgerClose: () => import('../../components/icons/BurgerClose.vue' /* webpackChunkName: "components/burger-close" */).then(c => wrapFunctional(c.default || c)),
  Calendar: () => import('../../components/icons/Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c)),
  CameraIcon: () => import('../../components/icons/CameraIcon.vue' /* webpackChunkName: "components/camera-icon" */).then(c => wrapFunctional(c.default || c)),
  CameraIconCircle: () => import('../../components/icons/CameraIconCircle.vue' /* webpackChunkName: "components/camera-icon-circle" */).then(c => wrapFunctional(c.default || c)),
  Card: () => import('../../components/icons/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c)),
  Cart: () => import('../../components/icons/Cart.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c)),
  CartSmallTruck: () => import('../../components/icons/CartSmallTruck.vue' /* webpackChunkName: "components/cart-small-truck" */).then(c => wrapFunctional(c.default || c)),
  CertPreview: () => import('../../components/icons/CertPreview.vue' /* webpackChunkName: "components/cert-preview" */).then(c => wrapFunctional(c.default || c)),
  Check: () => import('../../components/icons/Check.vue' /* webpackChunkName: "components/check" */).then(c => wrapFunctional(c.default || c)),
  CheckCircle: () => import('../../components/icons/CheckCircle.vue' /* webpackChunkName: "components/check-circle" */).then(c => wrapFunctional(c.default || c)),
  CircleIcon: () => import('../../components/icons/CircleIcon.vue' /* webpackChunkName: "components/circle-icon" */).then(c => wrapFunctional(c.default || c)),
  CircleLargeIcon: () => import('../../components/icons/CircleLargeIcon.vue' /* webpackChunkName: "components/circle-large-icon" */).then(c => wrapFunctional(c.default || c)),
  ClearInputCross: () => import('../../components/icons/ClearInputCross.vue' /* webpackChunkName: "components/clear-input-cross" */).then(c => wrapFunctional(c.default || c)),
  ClockIcon: () => import('../../components/icons/ClockIcon.vue' /* webpackChunkName: "components/clock-icon" */).then(c => wrapFunctional(c.default || c)),
  ConfirmCall: () => import('../../components/icons/ConfirmCall.vue' /* webpackChunkName: "components/confirm-call" */).then(c => wrapFunctional(c.default || c)),
  ConfirmSms: () => import('../../components/icons/ConfirmSms.vue' /* webpackChunkName: "components/confirm-sms" */).then(c => wrapFunctional(c.default || c)),
  ConfirmTelegram: () => import('../../components/icons/ConfirmTelegram.vue' /* webpackChunkName: "components/confirm-telegram" */).then(c => wrapFunctional(c.default || c)),
  ConsistIcon: () => import('../../components/icons/ConsistIcon.vue' /* webpackChunkName: "components/consist-icon" */).then(c => wrapFunctional(c.default || c)),
  CopyIcon: () => import('../../components/icons/CopyIcon.vue' /* webpackChunkName: "components/copy-icon" */).then(c => wrapFunctional(c.default || c)),
  CrossBig: () => import('../../components/icons/CrossBig.vue' /* webpackChunkName: "components/cross-big" */).then(c => wrapFunctional(c.default || c)),
  CrossCircle: () => import('../../components/icons/CrossCircle.vue' /* webpackChunkName: "components/cross-circle" */).then(c => wrapFunctional(c.default || c)),
  CrossMiddle: () => import('../../components/icons/CrossMiddle.vue' /* webpackChunkName: "components/cross-middle" */).then(c => wrapFunctional(c.default || c)),
  CrossSmall: () => import('../../components/icons/CrossSmall.vue' /* webpackChunkName: "components/cross-small" */).then(c => wrapFunctional(c.default || c)),
  CurrencyRu: () => import('../../components/icons/CurrencyRu.vue' /* webpackChunkName: "components/currency-ru" */).then(c => wrapFunctional(c.default || c)),
  Edit: () => import('../../components/icons/Edit.vue' /* webpackChunkName: "components/edit" */).then(c => wrapFunctional(c.default || c)),
  EditInfoIcon: () => import('../../components/icons/EditInfoIcon.vue' /* webpackChunkName: "components/edit-info-icon" */).then(c => wrapFunctional(c.default || c)),
  EmptySearch: () => import('../../components/icons/EmptySearch.vue' /* webpackChunkName: "components/empty-search" */).then(c => wrapFunctional(c.default || c)),
  ExclamationPoint: () => import('../../components/icons/ExclamationPoint.vue' /* webpackChunkName: "components/exclamation-point" */).then(c => wrapFunctional(c.default || c)),
  ExclamationPointBig: () => import('../../components/icons/ExclamationPointBig.vue' /* webpackChunkName: "components/exclamation-point-big" */).then(c => wrapFunctional(c.default || c)),
  EyeIcon: () => import('../../components/icons/EyeIcon.vue' /* webpackChunkName: "components/eye-icon" */).then(c => wrapFunctional(c.default || c)),
  EyeLockIcon: () => import('../../components/icons/EyeLockIcon.vue' /* webpackChunkName: "components/eye-lock-icon" */).then(c => wrapFunctional(c.default || c)),
  Facebook: () => import('../../components/icons/Facebook.vue' /* webpackChunkName: "components/facebook" */).then(c => wrapFunctional(c.default || c)),
  FeedbackPhone: () => import('../../components/icons/FeedbackPhone.vue' /* webpackChunkName: "components/feedback-phone" */).then(c => wrapFunctional(c.default || c)),
  FeedbackWhatsApp: () => import('../../components/icons/FeedbackWhatsApp.vue' /* webpackChunkName: "components/feedback-whats-app" */).then(c => wrapFunctional(c.default || c)),
  FilterIcon: () => import('../../components/icons/FilterIcon.vue' /* webpackChunkName: "components/filter-icon" */).then(c => wrapFunctional(c.default || c)),
  Gear: () => import('../../components/icons/Gear.vue' /* webpackChunkName: "components/gear" */).then(c => wrapFunctional(c.default || c)),
  Gift: () => import('../../components/icons/Gift.vue' /* webpackChunkName: "components/gift" */).then(c => wrapFunctional(c.default || c)),
  GooglePayInfo: () => import('../../components/icons/GooglePayInfo.vue' /* webpackChunkName: "components/google-pay-info" */).then(c => wrapFunctional(c.default || c)),
  GooglePlay: () => import('../../components/icons/GooglePlay.vue' /* webpackChunkName: "components/google-play" */).then(c => wrapFunctional(c.default || c)),
  GoogleStore: () => import('../../components/icons/GoogleStore.vue' /* webpackChunkName: "components/google-store" */).then(c => wrapFunctional(c.default || c)),
  Heart: () => import('../../components/icons/Heart.vue' /* webpackChunkName: "components/heart" */).then(c => wrapFunctional(c.default || c)),
  IconArrowLeft: () => import('../../components/icons/IconArrowLeft.vue' /* webpackChunkName: "components/icon-arrow-left" */).then(c => wrapFunctional(c.default || c)),
  Info: () => import('../../components/icons/Info.vue' /* webpackChunkName: "components/info" */).then(c => wrapFunctional(c.default || c)),
  InfoStrong: () => import('../../components/icons/InfoStrong.vue' /* webpackChunkName: "components/info-strong" */).then(c => wrapFunctional(c.default || c)),
  InputGeo: () => import('../../components/icons/InputGeo.vue' /* webpackChunkName: "components/input-geo" */).then(c => wrapFunctional(c.default || c)),
  Instagram: () => import('../../components/icons/Instagram.vue' /* webpackChunkName: "components/instagram" */).then(c => wrapFunctional(c.default || c)),
  Like: () => import('../../components/icons/Like.vue' /* webpackChunkName: "components/like" */).then(c => wrapFunctional(c.default || c)),
  LineWave: () => import('../../components/icons/LineWave.vue' /* webpackChunkName: "components/line-wave" */).then(c => wrapFunctional(c.default || c)),
  LoaderSnake: () => import('../../components/icons/LoaderSnake.vue' /* webpackChunkName: "components/loader-snake" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../components/icons/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  LogoLove: () => import('../../components/icons/LogoLove.vue' /* webpackChunkName: "components/logo-love" */).then(c => wrapFunctional(c.default || c)),
  LogoMobile: () => import('../../components/icons/LogoMobile.vue' /* webpackChunkName: "components/logo-mobile" */).then(c => wrapFunctional(c.default || c)),
  LogoNg: () => import('../../components/icons/LogoNg.vue' /* webpackChunkName: "components/logo-ng" */).then(c => wrapFunctional(c.default || c)),
  LogoNgSmall: () => import('../../components/icons/LogoNgSmall.vue' /* webpackChunkName: "components/logo-ng-small" */).then(c => wrapFunctional(c.default || c)),
  LogoSmall: () => import('../../components/icons/LogoSmall.vue' /* webpackChunkName: "components/logo-small" */).then(c => wrapFunctional(c.default || c)),
  LogoWomenDay: () => import('../../components/icons/LogoWomenDay.vue' /* webpackChunkName: "components/logo-women-day" */).then(c => wrapFunctional(c.default || c)),
  LogoutIcon: () => import('../../components/icons/LogoutIcon.vue' /* webpackChunkName: "components/logout-icon" */).then(c => wrapFunctional(c.default || c)),
  LongCheck: () => import('../../components/icons/LongCheck.vue' /* webpackChunkName: "components/long-check" */).then(c => wrapFunctional(c.default || c)),
  MapDot: () => import('../../components/icons/MapDot.vue' /* webpackChunkName: "components/map-dot" */).then(c => wrapFunctional(c.default || c)),
  MapIcon: () => import('../../components/icons/MapIcon.vue' /* webpackChunkName: "components/map-icon" */).then(c => wrapFunctional(c.default || c)),
  MenuIconList: () => import('../../components/icons/MenuIconList.vue' /* webpackChunkName: "components/menu-icon-list" */).then(c => wrapFunctional(c.default || c)),
  MenuIconTile: () => import('../../components/icons/MenuIconTile.vue' /* webpackChunkName: "components/menu-icon-tile" */).then(c => wrapFunctional(c.default || c)),
  MenuMapIcon: () => import('../../components/icons/MenuMapIcon.vue' /* webpackChunkName: "components/menu-map-icon" */).then(c => wrapFunctional(c.default || c)),
  Minus: () => import('../../components/icons/Minus.vue' /* webpackChunkName: "components/minus" */).then(c => wrapFunctional(c.default || c)),
  MobileAppArrow: () => import('../../components/icons/MobileAppArrow.vue' /* webpackChunkName: "components/mobile-app-arrow" */).then(c => wrapFunctional(c.default || c)),
  MobileAppArrowM: () => import('../../components/icons/MobileAppArrowM.vue' /* webpackChunkName: "components/mobile-app-arrow-m" */).then(c => wrapFunctional(c.default || c)),
  NewIcon: () => import('../../components/icons/NewIcon.vue' /* webpackChunkName: "components/new-icon" */).then(c => wrapFunctional(c.default || c)),
  NoConnectionIcon: () => import('../../components/icons/NoConnectionIcon.vue' /* webpackChunkName: "components/no-connection-icon" */).then(c => wrapFunctional(c.default || c)),
  PaymentCard: () => import('../../components/icons/PaymentCard.vue' /* webpackChunkName: "components/payment-card" */).then(c => wrapFunctional(c.default || c)),
  PaymentMir: () => import('../../components/icons/PaymentMir.vue' /* webpackChunkName: "components/payment-mir" */).then(c => wrapFunctional(c.default || c)),
  PaymentPay: () => import('../../components/icons/PaymentPay.vue' /* webpackChunkName: "components/payment-pay" */).then(c => wrapFunctional(c.default || c)),
  PaymentSpb: () => import('../../components/icons/PaymentSpb.vue' /* webpackChunkName: "components/payment-spb" */).then(c => wrapFunctional(c.default || c)),
  PaymetnMastercard: () => import('../../components/icons/PaymetnMastercard.vue' /* webpackChunkName: "components/paymetn-mastercard" */).then(c => wrapFunctional(c.default || c)),
  PaymetnVisa: () => import('../../components/icons/PaymetnVisa.vue' /* webpackChunkName: "components/paymetn-visa" */).then(c => wrapFunctional(c.default || c)),
  Plus: () => import('../../components/icons/Plus.vue' /* webpackChunkName: "components/plus" */).then(c => wrapFunctional(c.default || c)),
  PlusCross: () => import('../../components/icons/PlusCross.vue' /* webpackChunkName: "components/plus-cross" */).then(c => wrapFunctional(c.default || c)),
  PointMapIcon: () => import('../../components/icons/PointMapIcon.vue' /* webpackChunkName: "components/point-map-icon" */).then(c => wrapFunctional(c.default || c)),
  Politics: () => import('../../components/icons/Politics.vue' /* webpackChunkName: "components/politics" */).then(c => wrapFunctional(c.default || c)),
  Quote: () => import('../../components/icons/Quote.vue' /* webpackChunkName: "components/quote" */).then(c => wrapFunctional(c.default || c)),
  RepeatIcon: () => import('../../components/icons/RepeatIcon.vue' /* webpackChunkName: "components/repeat-icon" */).then(c => wrapFunctional(c.default || c)),
  ScoreIcon: () => import('../../components/icons/ScoreIcon.vue' /* webpackChunkName: "components/score-icon" */).then(c => wrapFunctional(c.default || c)),
  SearchIcon: () => import('../../components/icons/SearchIcon.vue' /* webpackChunkName: "components/search-icon" */).then(c => wrapFunctional(c.default || c)),
  SmileIcon: () => import('../../components/icons/SmileIcon.vue' /* webpackChunkName: "components/smile-icon" */).then(c => wrapFunctional(c.default || c)),
  SortIcon: () => import('../../components/icons/SortIcon.vue' /* webpackChunkName: "components/sort-icon" */).then(c => wrapFunctional(c.default || c)),
  SortIconDown: () => import('../../components/icons/SortIconDown.vue' /* webpackChunkName: "components/sort-icon-down" */).then(c => wrapFunctional(c.default || c)),
  SortIconUp: () => import('../../components/icons/SortIconUp.vue' /* webpackChunkName: "components/sort-icon-up" */).then(c => wrapFunctional(c.default || c)),
  StarIcon: () => import('../../components/icons/StarIcon.vue' /* webpackChunkName: "components/star-icon" */).then(c => wrapFunctional(c.default || c)),
  StepArrow: () => import('../../components/icons/StepArrow.vue' /* webpackChunkName: "components/step-arrow" */).then(c => wrapFunctional(c.default || c)),
  Tail: () => import('../../components/icons/Tail.vue' /* webpackChunkName: "components/tail" */).then(c => wrapFunctional(c.default || c)),
  Telegram: () => import('../../components/icons/Telegram.vue' /* webpackChunkName: "components/telegram" */).then(c => wrapFunctional(c.default || c)),
  TelegramBig: () => import('../../components/icons/TelegramBig.vue' /* webpackChunkName: "components/telegram-big" */).then(c => wrapFunctional(c.default || c)),
  TextCopy: () => import('../../components/icons/TextCopy.vue' /* webpackChunkName: "components/text-copy" */).then(c => wrapFunctional(c.default || c)),
  TooltipArrow: () => import('../../components/icons/TooltipArrow.vue' /* webpackChunkName: "components/tooltip-arrow" */).then(c => wrapFunctional(c.default || c)),
  Trash: () => import('../../components/icons/Trash.vue' /* webpackChunkName: "components/trash" */).then(c => wrapFunctional(c.default || c)),
  TriplePoints: () => import('../../components/icons/TriplePoints.vue' /* webpackChunkName: "components/triple-points" */).then(c => wrapFunctional(c.default || c)),
  UserIcon: () => import('../../components/icons/UserIcon.vue' /* webpackChunkName: "components/user-icon" */).then(c => wrapFunctional(c.default || c)),
  Vk: () => import('../../components/icons/Vk.vue' /* webpackChunkName: "components/vk" */).then(c => wrapFunctional(c.default || c)),
  VkBg: () => import('../../components/icons/VkBg.vue' /* webpackChunkName: "components/vk-bg" */).then(c => wrapFunctional(c.default || c)),
  VkBig: () => import('../../components/icons/VkBig.vue' /* webpackChunkName: "components/vk-big" */).then(c => wrapFunctional(c.default || c)),
  AnonsTrackingCouriers: () => import('../../components/modal/AnonsTrackingCouriers.vue' /* webpackChunkName: "components/anons-tracking-couriers" */).then(c => wrapFunctional(c.default || c)),
  AnotherAddressPopup: () => import('../../components/modal/AnotherAddressPopup.vue' /* webpackChunkName: "components/another-address-popup" */).then(c => wrapFunctional(c.default || c)),
  AuthPopup: () => import('../../components/modal/AuthPopup.vue' /* webpackChunkName: "components/auth-popup" */).then(c => wrapFunctional(c.default || c)),
  BackcallModal: () => import('../../components/modal/BackcallModal.vue' /* webpackChunkName: "components/backcall-modal" */).then(c => wrapFunctional(c.default || c)),
  BetOffer: () => import('../../components/modal/BetOffer.vue' /* webpackChunkName: "components/bet-offer" */).then(c => wrapFunctional(c.default || c)),
  BonusCardPopup: () => import('../../components/modal/BonusCardPopup.vue' /* webpackChunkName: "components/bonus-card-popup" */).then(c => wrapFunctional(c.default || c)),
  BonusCardRegistration: () => import('../../components/modal/BonusCardRegistration.vue' /* webpackChunkName: "components/bonus-card-registration" */).then(c => wrapFunctional(c.default || c)),
  BonusCardRestore: () => import('../../components/modal/BonusCardRestore.vue' /* webpackChunkName: "components/bonus-card-restore" */).then(c => wrapFunctional(c.default || c)),
  CalendarNoTime: () => import('../../components/modal/CalendarNoTime.vue' /* webpackChunkName: "components/calendar-no-time" */).then(c => wrapFunctional(c.default || c)),
  CancelOrder: () => import('../../components/modal/CancelOrder.vue' /* webpackChunkName: "components/cancel-order" */).then(c => wrapFunctional(c.default || c)),
  ChangeCityWarning: () => import('../../components/modal/ChangeCityWarning.vue' /* webpackChunkName: "components/change-city-warning" */).then(c => wrapFunctional(c.default || c)),
  CitySelect: () => import('../../components/modal/CitySelect.vue' /* webpackChunkName: "components/city-select" */).then(c => wrapFunctional(c.default || c)),
  ConfirmOrder: () => import('../../components/modal/ConfirmOrder.vue' /* webpackChunkName: "components/confirm-order" */).then(c => wrapFunctional(c.default || c)),
  DeliveryMap: () => import('../../components/modal/DeliveryMap.vue' /* webpackChunkName: "components/delivery-map" */).then(c => wrapFunctional(c.default || c)),
  DonateModal: () => import('../../components/modal/DonateModal.vue' /* webpackChunkName: "components/donate-modal" */).then(c => wrapFunctional(c.default || c)),
  ForgottenProducts: () => import('../../components/modal/ForgottenProducts.vue' /* webpackChunkName: "components/forgotten-products" */).then(c => wrapFunctional(c.default || c)),
  HeaderLoginForm: () => import('../../components/modal/HeaderLoginForm.vue' /* webpackChunkName: "components/header-login-form" */).then(c => wrapFunctional(c.default || c)),
  InfoModal: () => import('../../components/modal/InfoModal.vue' /* webpackChunkName: "components/info-modal" */).then(c => wrapFunctional(c.default || c)),
  MobileAppBanner: () => import('../../components/modal/MobileAppBanner.vue' /* webpackChunkName: "components/mobile-app-banner" */).then(c => wrapFunctional(c.default || c)),
  ModalWrapper: () => import('../../components/modal/ModalWrapper.vue' /* webpackChunkName: "components/modal-wrapper" */).then(c => wrapFunctional(c.default || c)),
  Modals: () => import('../../components/modal/Modals.vue' /* webpackChunkName: "components/modals" */).then(c => wrapFunctional(c.default || c)),
  NoDelivery: () => import('../../components/modal/NoDelivery.vue' /* webpackChunkName: "components/no-delivery" */).then(c => wrapFunctional(c.default || c)),
  NotificationModal: () => import('../../components/modal/NotificationModal.vue' /* webpackChunkName: "components/notification-modal" */).then(c => wrapFunctional(c.default || c)),
  PaymentQrModal: () => import('../../components/modal/PaymentQrModal.vue' /* webpackChunkName: "components/payment-qr-modal" */).then(c => wrapFunctional(c.default || c)),
  ProductSchedule: () => import('../../components/modal/ProductSchedule.vue' /* webpackChunkName: "components/product-schedule" */).then(c => wrapFunctional(c.default || c)),
  PromoCancelledModal: () => import('../../components/modal/PromoCancelledModal.vue' /* webpackChunkName: "components/promo-cancelled-modal" */).then(c => wrapFunctional(c.default || c)),
  PromoPopupDelivery: () => import('../../components/modal/PromoPopupDelivery.vue' /* webpackChunkName: "components/promo-popup-delivery" */).then(c => wrapFunctional(c.default || c)),
  RateProductModal: () => import('../../components/modal/RateProductModal.vue' /* webpackChunkName: "components/rate-product-modal" */).then(c => wrapFunctional(c.default || c)),
  RateProductsPopup: () => import('../../components/modal/RateProductsPopup.vue' /* webpackChunkName: "components/rate-products-popup" */).then(c => wrapFunctional(c.default || c)),
  SelectCity: () => import('../../components/modal/SelectCity.vue' /* webpackChunkName: "components/select-city" */).then(c => wrapFunctional(c.default || c)),
  SelfDeliveryPopup: () => import('../../components/modal/SelfDeliveryPopup.vue' /* webpackChunkName: "components/self-delivery-popup" */).then(c => wrapFunctional(c.default || c)),
  SmsConfirmation: () => import('../../components/modal/SmsConfirmation.vue' /* webpackChunkName: "components/sms-confirmation" */).then(c => wrapFunctional(c.default || c)),
  SortModal: () => import('../../components/modal/SortModal.vue' /* webpackChunkName: "components/sort-modal" */).then(c => wrapFunctional(c.default || c)),
  StopListConfirmation: () => import('../../components/modal/StopListConfirmation.vue' /* webpackChunkName: "components/stop-list-confirmation" */).then(c => wrapFunctional(c.default || c)),
  StopListInfo: () => import('../../components/modal/StopListInfo.vue' /* webpackChunkName: "components/stop-list-info" */).then(c => wrapFunctional(c.default || c)),
  StoriesSliderModal: () => import('../../components/modal/StoriesSliderModal.vue' /* webpackChunkName: "components/stories-slider-modal" */).then(c => wrapFunctional(c.default || c)),
  TestingComponent: () => import('../../components/modal/TestingComponent.vue' /* webpackChunkName: "components/testing-component" */).then(c => wrapFunctional(c.default || c)),
  TrackCourier: () => import('../../components/modal/TrackCourier.vue' /* webpackChunkName: "components/track-courier" */).then(c => wrapFunctional(c.default || c)),
  UserAgreements: () => import('../../components/modal/UserAgreements.vue' /* webpackChunkName: "components/user-agreements" */).then(c => wrapFunctional(c.default || c)),
  VideocameraOnline: () => import('../../components/modal/VideocameraOnline.vue' /* webpackChunkName: "components/videocamera-online" */).then(c => wrapFunctional(c.default || c)),
  SmallInfoModal: () => import('../../components/modal/smallInfoModal.vue' /* webpackChunkName: "components/small-info-modal" */).then(c => wrapFunctional(c.default || c)),
  ApplePay: () => import('../../components/order/ApplePay.vue' /* webpackChunkName: "components/apple-pay" */).then(c => wrapFunctional(c.default || c)),
  BonusCardOffer: () => import('../../components/order/BonusCardOffer.vue' /* webpackChunkName: "components/bonus-card-offer" */).then(c => wrapFunctional(c.default || c)),
  CharityQuestionnaire: () => import('../../components/order/CharityQuestionnaire.vue' /* webpackChunkName: "components/charity-questionnaire" */).then(c => wrapFunctional(c.default || c)),
  CloudPaymentsForm: () => import('../../components/order/CloudPaymentsForm.vue' /* webpackChunkName: "components/cloud-payments-form" */).then(c => wrapFunctional(c.default || c)),
  CloudPaymentsFormIframe: () => import('../../components/order/CloudPaymentsFormIframe.vue' /* webpackChunkName: "components/cloud-payments-form-iframe" */).then(c => wrapFunctional(c.default || c)),
  CloudPaymentsPaymentType: () => import('../../components/order/CloudPaymentsPaymentType.vue' /* webpackChunkName: "components/cloud-payments-payment-type" */).then(c => wrapFunctional(c.default || c)),
  Compensation: () => import('../../components/order/Compensation.vue' /* webpackChunkName: "components/compensation" */).then(c => wrapFunctional(c.default || c)),
  GiftSelect: () => import('../../components/order/GiftSelect.vue' /* webpackChunkName: "components/gift-select" */).then(c => wrapFunctional(c.default || c)),
  GooglePay: () => import('../../components/order/GooglePay.vue' /* webpackChunkName: "components/google-pay" */).then(c => wrapFunctional(c.default || c)),
  OrderApp: () => import('../../components/order/OrderApp.vue' /* webpackChunkName: "components/order-app" */).then(c => wrapFunctional(c.default || c)),
  OrderNavigation: () => import('../../components/order/OrderNavigation.vue' /* webpackChunkName: "components/order-navigation" */).then(c => wrapFunctional(c.default || c)),
  TotalsBlock: () => import('../../components/order/TotalsBlock.vue' /* webpackChunkName: "components/totals-block" */).then(c => wrapFunctional(c.default || c)),
  AttractiveOffers: () => import('../../components/products/AttractiveOffers.vue' /* webpackChunkName: "components/attractive-offers" */).then(c => wrapFunctional(c.default || c)),
  CartPreview: () => import('../../components/products/CartPreview.vue' /* webpackChunkName: "components/cart-preview" */).then(c => wrapFunctional(c.default || c)),
  CategoryProduct: () => import('../../components/products/CategoryProduct.vue' /* webpackChunkName: "components/category-product" */).then(c => wrapFunctional(c.default || c)),
  ConstituentProduct: () => import('../../components/products/ConstituentProduct.vue' /* webpackChunkName: "components/constituent-product" */).then(c => wrapFunctional(c.default || c)),
  MenuCategory: () => import('../../components/products/MenuCategory.vue' /* webpackChunkName: "components/menu-category" */).then(c => wrapFunctional(c.default || c)),
  ModifiersPreview: () => import('../../components/products/ModifiersPreview.vue' /* webpackChunkName: "components/modifiers-preview" */).then(c => wrapFunctional(c.default || c)),
  ModifiersProduct: () => import('../../components/products/ModifiersProduct.vue' /* webpackChunkName: "components/modifiers-product" */).then(c => wrapFunctional(c.default || c)),
  ModifiersProductGroup: () => import('../../components/products/ModifiersProductGroup.vue' /* webpackChunkName: "components/modifiers-product-group" */).then(c => wrapFunctional(c.default || c)),
  PreviewModifier: () => import('../../components/products/PreviewModifier.vue' /* webpackChunkName: "components/preview-modifier" */).then(c => wrapFunctional(c.default || c)),
  PreviewProduct: () => import('../../components/products/PreviewProduct.vue' /* webpackChunkName: "components/preview-product" */).then(c => wrapFunctional(c.default || c)),
  ProductActions: () => import('../../components/products/ProductActions.vue' /* webpackChunkName: "components/product-actions" */).then(c => wrapFunctional(c.default || c)),
  ProductActionsMod: () => import('../../components/products/ProductActionsMod.vue' /* webpackChunkName: "components/product-actions-mod" */).then(c => wrapFunctional(c.default || c)),
  ProductBeforeDiscountPrice: () => import('../../components/products/ProductBeforeDiscountPrice.vue' /* webpackChunkName: "components/product-before-discount-price" */).then(c => wrapFunctional(c.default || c)),
  ProductConsist: () => import('../../components/products/ProductConsist.vue' /* webpackChunkName: "components/product-consist" */).then(c => wrapFunctional(c.default || c)),
  ProductEnergy: () => import('../../components/products/ProductEnergy.vue' /* webpackChunkName: "components/product-energy" */).then(c => wrapFunctional(c.default || c)),
  ProductEnergyTable: () => import('../../components/products/ProductEnergyTable.vue' /* webpackChunkName: "components/product-energy-table" */).then(c => wrapFunctional(c.default || c)),
  ProductInCart: () => import('../../components/products/ProductInCart.vue' /* webpackChunkName: "components/product-in-cart" */).then(c => wrapFunctional(c.default || c)),
  ProductIngredients: () => import('../../components/products/ProductIngredients.vue' /* webpackChunkName: "components/product-ingredients" */).then(c => wrapFunctional(c.default || c)),
  ProductModifiersGroups: () => import('../../components/products/ProductModifiersGroups.vue' /* webpackChunkName: "components/product-modifiers-groups" */).then(c => wrapFunctional(c.default || c)),
  ProductNoticement: () => import('../../components/products/ProductNoticement.vue' /* webpackChunkName: "components/product-noticement" */).then(c => wrapFunctional(c.default || c)),
  ProductPopupImg: () => import('../../components/products/ProductPopupImg.vue' /* webpackChunkName: "components/product-popup-img" */).then(c => wrapFunctional(c.default || c)),
  ProductScore: () => import('../../components/products/ProductScore.vue' /* webpackChunkName: "components/product-score" */).then(c => wrapFunctional(c.default || c)),
  ProductTags: () => import('../../components/products/ProductTags.vue' /* webpackChunkName: "components/product-tags" */).then(c => wrapFunctional(c.default || c)),
  RecommendationList: () => import('../../components/products/RecommendationList.vue' /* webpackChunkName: "components/recommendation-list" */).then(c => wrapFunctional(c.default || c)),
  RecommendationProduct: () => import('../../components/products/RecommendationProduct.vue' /* webpackChunkName: "components/recommendation-product" */).then(c => wrapFunctional(c.default || c)),
  RecommendedProduct: () => import('../../components/products/RecommendedProduct.vue' /* webpackChunkName: "components/recommended-product" */).then(c => wrapFunctional(c.default || c)),
  RecommendedProductAction: () => import('../../components/products/RecommendedProductAction.vue' /* webpackChunkName: "components/recommended-product-action" */).then(c => wrapFunctional(c.default || c)),
  RecommendedProducts: () => import('../../components/products/RecommendedProducts.vue' /* webpackChunkName: "components/recommended-products" */).then(c => wrapFunctional(c.default || c)),
  SearchProduct: () => import('../../components/products/SearchProduct.vue' /* webpackChunkName: "components/search-product" */).then(c => wrapFunctional(c.default || c)),
  SetConsist: () => import('../../components/products/SetConsist.vue' /* webpackChunkName: "components/set-consist" */).then(c => wrapFunctional(c.default || c)),
  AddressSelectMenuShimmer: () => import('../../components/sections/AddressSelectMenuShimmer.vue' /* webpackChunkName: "components/address-select-menu-shimmer" */).then(c => wrapFunctional(c.default || c)),
  FooterSection: () => import('../../components/sections/FooterSection.vue' /* webpackChunkName: "components/footer-section" */).then(c => wrapFunctional(c.default || c)),
  HeaderSection: () => import('../../components/sections/HeaderSection.vue' /* webpackChunkName: "components/header-section" */).then(c => wrapFunctional(c.default || c)),
  MenuPopup: () => import('../../components/sections/MenuPopup.vue' /* webpackChunkName: "components/menu-popup" */).then(c => wrapFunctional(c.default || c)),
  NavSoc: () => import('../../components/sections/NavSoc.vue' /* webpackChunkName: "components/nav-soc" */).then(c => wrapFunctional(c.default || c)),
  ProductPopup: () => import('../../components/sections/ProductPopup.vue' /* webpackChunkName: "components/product-popup" */).then(c => wrapFunctional(c.default || c)),
  TopLine: () => import('../../components/sections/TopLine.vue' /* webpackChunkName: "components/top-line" */).then(c => wrapFunctional(c.default || c)),
  OrderHistory: () => import('../../components/account/history/OrderHistory.vue' /* webpackChunkName: "components/order-history" */).then(c => wrapFunctional(c.default || c)),
  ProductHistory: () => import('../../components/account/history/ProductHistory.vue' /* webpackChunkName: "components/product-history" */).then(c => wrapFunctional(c.default || c)),
  PseudoOrder: () => import('../../components/account/history/PseudoOrder.vue' /* webpackChunkName: "components/pseudo-order" */).then(c => wrapFunctional(c.default || c)),
  CurrentOrderShimmers: () => import('../../components/account/tabs/CurrentOrderShimmers.vue' /* webpackChunkName: "components/current-order-shimmers" */).then(c => wrapFunctional(c.default || c)),
  CurrentOrdersTab: () => import('../../components/account/tabs/CurrentOrdersTab.vue' /* webpackChunkName: "components/current-orders-tab" */).then(c => wrapFunctional(c.default || c)),
  OrdersHistoryTab: () => import('../../components/account/tabs/OrdersHistoryTab.vue' /* webpackChunkName: "components/orders-history-tab" */).then(c => wrapFunctional(c.default || c)),
  ProfileTab: () => import('../../components/account/tabs/ProfileTab.vue' /* webpackChunkName: "components/profile-tab" */).then(c => wrapFunctional(c.default || c)),
  NewCityGift: () => import('../../components/lendings/newCity/NewCityGift.vue' /* webpackChunkName: "components/new-city-gift" */).then(c => wrapFunctional(c.default || c)),
  NewCityPromo: () => import('../../components/lendings/newCity/NewCityPromo.vue' /* webpackChunkName: "components/new-city-promo" */).then(c => wrapFunctional(c.default || c)),
  NewCityTeam: () => import('../../components/lendings/newCity/NewCityTeam.vue' /* webpackChunkName: "components/new-city-team" */).then(c => wrapFunctional(c.default || c)),
  SlideAdvantages: () => import('../../components/lendings/newCity/SlideAdvantages.vue' /* webpackChunkName: "components/slide-advantages" */).then(c => wrapFunctional(c.default || c)),
  AccurateDelivery: () => import('../../components/lendings/old/AccurateDelivery.vue' /* webpackChunkName: "components/accurate-delivery" */).then(c => wrapFunctional(c.default || c)),
  FoodQuality: () => import('../../components/lendings/old/FoodQuality.vue' /* webpackChunkName: "components/food-quality" */).then(c => wrapFunctional(c.default || c)),
  GetGiftForm: () => import('../../components/lendings/old/GetGiftForm.vue' /* webpackChunkName: "components/get-gift-form" */).then(c => wrapFunctional(c.default || c)),
  NewCityBanner: () => import('../../components/lendings/old/NewCityBanner.vue' /* webpackChunkName: "components/new-city-banner" */).then(c => wrapFunctional(c.default || c)),
  NewCityGifts: () => import('../../components/lendings/old/NewCityGifts.vue' /* webpackChunkName: "components/new-city-gifts" */).then(c => wrapFunctional(c.default || c)),
  NewCityGreetings: () => import('../../components/lendings/old/NewCityGreetings.vue' /* webpackChunkName: "components/new-city-greetings" */).then(c => wrapFunctional(c.default || c)),
  NewCityHeader: () => import('../../components/lendings/old/NewCityHeader.vue' /* webpackChunkName: "components/new-city-header" */).then(c => wrapFunctional(c.default || c)),
  RightCookFooter: () => import('../../components/lendings/old/RightCookFooter.vue' /* webpackChunkName: "components/right-cook-footer" */).then(c => wrapFunctional(c.default || c)),
  RightRollsGreetings: () => import('../../components/lendings/old/RightRollsGreetings.vue' /* webpackChunkName: "components/right-rolls-greetings" */).then(c => wrapFunctional(c.default || c)),
  RollsFallApart: () => import('../../components/lendings/old/RollsFallApart.vue' /* webpackChunkName: "components/rolls-fall-apart" */).then(c => wrapFunctional(c.default || c)),
  TestForRoll: () => import('../../components/lendings/old/TestForRoll.vue' /* webpackChunkName: "components/test-for-roll" */).then(c => wrapFunctional(c.default || c)),
  YouCreateMenu: () => import('../../components/lendings/old/YouCreateMenu.vue' /* webpackChunkName: "components/you-create-menu" */).then(c => wrapFunctional(c.default || c)),
  CaviarTobico: () => import('../../components/lendings/restaurants-advantages/CaviarTobico.vue' /* webpackChunkName: "components/caviar-tobico" */).then(c => wrapFunctional(c.default || c)),
  EdgesRolls: () => import('../../components/lendings/restaurants-advantages/EdgesRolls.vue' /* webpackChunkName: "components/edges-rolls" */).then(c => wrapFunctional(c.default || c)),
  FishSalmon: () => import('../../components/lendings/restaurants-advantages/FishSalmon.vue' /* webpackChunkName: "components/fish-salmon" */).then(c => wrapFunctional(c.default || c)),
  FreshRolls: () => import('../../components/lendings/restaurants-advantages/FreshRolls.vue' /* webpackChunkName: "components/fresh-rolls" */).then(c => wrapFunctional(c.default || c)),
  OurTechnologies: () => import('../../components/lendings/restaurants-advantages/OurTechnologies.vue' /* webpackChunkName: "components/our-technologies" */).then(c => wrapFunctional(c.default || c)),
  RestaurantAdvantages: () => import('../../components/lendings/restaurants-advantages/RestaurantAdvantages.vue' /* webpackChunkName: "components/restaurant-advantages" */).then(c => wrapFunctional(c.default || c)),
  RiceCompensation: () => import('../../components/lendings/restaurants-advantages/RiceCompensation.vue' /* webpackChunkName: "components/rice-compensation" */).then(c => wrapFunctional(c.default || c)),
  AppleButton: () => import('../../components/order/payments/AppleButton.vue' /* webpackChunkName: "components/apple-button" */).then(c => wrapFunctional(c.default || c)),
  CashButton: () => import('../../components/order/payments/CashButton.vue' /* webpackChunkName: "components/cash-button" */).then(c => wrapFunctional(c.default || c)),
  CashlessButton: () => import('../../components/order/payments/CashlessButton.vue' /* webpackChunkName: "components/cashless-button" */).then(c => wrapFunctional(c.default || c)),
  GoogleButton: () => import('../../components/order/payments/GoogleButton.vue' /* webpackChunkName: "components/google-button" */).then(c => wrapFunctional(c.default || c)),
  OnlineButton: () => import('../../components/order/payments/OnlineButton.vue' /* webpackChunkName: "components/online-button" */).then(c => wrapFunctional(c.default || c)),
  QrButton: () => import('../../components/order/payments/QrButton.vue' /* webpackChunkName: "components/qr-button" */).then(c => wrapFunctional(c.default || c)),
  SberButton: () => import('../../components/order/payments/SberButton.vue' /* webpackChunkName: "components/sber-button" */).then(c => wrapFunctional(c.default || c)),
  StopListProduct: () => import('../../components/order/stop_list/StopListProduct.vue' /* webpackChunkName: "components/stop-list-product" */).then(c => wrapFunctional(c.default || c)),
  TabDetailsStopListProducts: () => import('../../components/order/stop_list/TabDetailsStopListProducts.vue' /* webpackChunkName: "components/tab-details-stop-list-products" */).then(c => wrapFunctional(c.default || c)),
  BonusSlider: () => import('../../components/order/tabs/BonusSlider.vue' /* webpackChunkName: "components/bonus-slider" */).then(c => wrapFunctional(c.default || c)),
  CartProduct: () => import('../../components/order/tabs/CartProduct.vue' /* webpackChunkName: "components/cart-product" */).then(c => wrapFunctional(c.default || c)),
  DeliveryTime: () => import('../../components/order/tabs/DeliveryTime.vue' /* webpackChunkName: "components/delivery-time" */).then(c => wrapFunctional(c.default || c)),
  DonateInfo: () => import('../../components/order/tabs/DonateInfo.vue' /* webpackChunkName: "components/donate-info" */).then(c => wrapFunctional(c.default || c)),
  EcoProduct: () => import('../../components/order/tabs/EcoProduct.vue' /* webpackChunkName: "components/eco-product" */).then(c => wrapFunctional(c.default || c)),
  EcologicalConsumption: () => import('../../components/order/tabs/EcologicalConsumption.vue' /* webpackChunkName: "components/ecological-consumption" */).then(c => wrapFunctional(c.default || c)),
  EmptyCart: () => import('../../components/order/tabs/EmptyCart.vue' /* webpackChunkName: "components/empty-cart" */).then(c => wrapFunctional(c.default || c)),
  MultipleToggler: () => import('../../components/order/tabs/MultipleToggler.vue' /* webpackChunkName: "components/multiple-toggler" */).then(c => wrapFunctional(c.default || c)),
  PaymentBonus: () => import('../../components/order/tabs/PaymentBonus.vue' /* webpackChunkName: "components/payment-bonus" */).then(c => wrapFunctional(c.default || c)),
  RecommendationActions: () => import('../../components/order/tabs/RecommendationActions.vue' /* webpackChunkName: "components/recommendation-actions" */).then(c => wrapFunctional(c.default || c)),
  Recommendations: () => import('../../components/order/tabs/Recommendations.vue' /* webpackChunkName: "components/recommendations" */).then(c => wrapFunctional(c.default || c)),
  RecommendationsCategory: () => import('../../components/order/tabs/RecommendationsCategory.vue' /* webpackChunkName: "components/recommendations-category" */).then(c => wrapFunctional(c.default || c)),
  Sort: () => import('../../components/order/tabs/Sort.vue' /* webpackChunkName: "components/sort" */).then(c => wrapFunctional(c.default || c)),
  TabDetails: () => import('../../components/order/tabs/TabDetails.vue' /* webpackChunkName: "components/tab-details" */).then(c => wrapFunctional(c.default || c)),
  TabDetailsAddressHistory: () => import('../../components/order/tabs/TabDetailsAddressHistory.vue' /* webpackChunkName: "components/tab-details-address-history" */).then(c => wrapFunctional(c.default || c)),
  TabDetailsAddressSelf: () => import('../../components/order/tabs/TabDetailsAddressSelf.vue' /* webpackChunkName: "components/tab-details-address-self" */).then(c => wrapFunctional(c.default || c)),
  TabDetailsTime: () => import('../../components/order/tabs/TabDetailsTime.vue' /* webpackChunkName: "components/tab-details-time" */).then(c => wrapFunctional(c.default || c)),
  TabFinish: () => import('../../components/order/tabs/TabFinish.vue' /* webpackChunkName: "components/tab-finish" */).then(c => wrapFunctional(c.default || c)),
  TabOrder: () => import('../../components/order/tabs/TabOrder.vue' /* webpackChunkName: "components/tab-order" */).then(c => wrapFunctional(c.default || c)),
  TabOrderAdditionalProducts: () => import('../../components/order/tabs/TabOrderAdditionalProducts.vue' /* webpackChunkName: "components/tab-order-additional-products" */).then(c => wrapFunctional(c.default || c)),
  TabOrderAdditionalProductsChangeCount: () => import('../../components/order/tabs/TabOrderAdditionalProductsChangeCount.vue' /* webpackChunkName: "components/tab-order-additional-products-change-count" */).then(c => wrapFunctional(c.default || c)),
  TabOrderBonusRegister: () => import('../../components/order/tabs/TabOrderBonusRegister.vue' /* webpackChunkName: "components/tab-order-bonus-register" */).then(c => wrapFunctional(c.default || c)),
  TabOrderForm: () => import('../../components/order/tabs/TabOrderForm.vue' /* webpackChunkName: "components/tab-order-form" */).then(c => wrapFunctional(c.default || c)),
  TabOrderProducts: () => import('../../components/order/tabs/TabOrderProducts.vue' /* webpackChunkName: "components/tab-order-products" */).then(c => wrapFunctional(c.default || c)),
  TabOrderPromo: () => import('../../components/order/tabs/TabOrderPromo.vue' /* webpackChunkName: "components/tab-order-promo" */).then(c => wrapFunctional(c.default || c)),
  TabOrderPromoGift: () => import('../../components/order/tabs/TabOrderPromoGift.vue' /* webpackChunkName: "components/tab-order-promo-gift" */).then(c => wrapFunctional(c.default || c)),
  TabPayment: () => import('../../components/order/tabs/TabPayment.vue' /* webpackChunkName: "components/tab-payment" */).then(c => wrapFunctional(c.default || c)),
  Toggler: () => import('../../components/order/tabs/Toggler.vue' /* webpackChunkName: "components/toggler" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
