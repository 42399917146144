//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
import ProductEnergy from "~/components/products/ProductEnergy.vue";

export default {
    name: "ConstituentProduct",
    components: { ProductEnergy },
    props: ["product", "parentElem"],

    data() {
        return {
            productImage: `url(${this.product.photo})`,
            openEnergyValues: false,
        };
    },
    mounted() {
        this.setProductImage();
    },
    methods: {
        ...mapActions(["products/changeCurrentProductEnergyValue", "products/changeMarkupForModal"]),

        showEnergyValue() {
            this.setProductModalValues({ product: this.product });
            if (Object.keys(this.$store.getters["products/getProductEnergyInfo"]).length > 0) {
                this.openEnergyValues = true;
            }
        },
        hideEnergyValue() {
            this.openEnergyValues = false;
            this.setProductModalValues(null);
        },
        setProductModalValues(values) {
            this.$store.dispatch("products/changeCurrentProductEnergyValue", values);
        },
        setProductImage() {
            if (this.product.photo) {
                this.productImage = `url(${this.product.photo})`;
            } else {
                this.productImage = "url(/_nuxt/assets/img/no-img-low.jpg)";
            }
        },
    },
};
