//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import { sendYandexMetric } from "~/service/ecommerce";
import SortIcon from "~/components/icons/SortIcon.vue";
import NewIcon from "~/components/icons/NewIcon.vue";
import Like from "~/components/icons/Like.vue";
import StarIcon from "~/components/icons/StarIcon.vue";
import SortIconUp from "~/components/icons/SortIconUp.vue";
import SortIconDown from "~/components/icons/SortIconDown.vue";
import Arrow from "~/components/icons/Arrow.vue";

export default {
    name: "Sort",
    components: {
        Arrow,
        SortIconDown,
        SortIconUp,
        StarIcon,
        Like,
        NewIcon,
        SortIcon,
    },
    // props: ["currentSort"],
    directives: { ClickOutside },
    props: {
        currentSort: {
            type: String,
            default: null,
        },
        recommendationProducts: {
            type: Boolean,
            default: false,
        },
        noArrow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            sortBlock: false,

            sortParam: {
                priceDown: {
                    name: "priceDown",
                    title: "сначала дороже",
                    iconComponent: "SortIconUp",
                    metric() {
                        sendYandexMetric("sort-desc");
                    },
                },
                priceUp: {
                    name: "priceUp",
                    title: "сначала дешевле",
                    iconComponent: "SortIconDown",
                    metric() {
                        sendYandexMetric("sort-asc");
                    },
                },
                new: {
                    name: "new",
                    title: "по новизне",
                    iconComponent: "NewIcon",
                    metric() {
                        sendYandexMetric("sort-new");
                    },
                },
                popularity: {
                    name: "popularity",
                    title: "по популярности",
                    iconComponent: "Like",
                    metric() {
                        sendYandexMetric("sort-default");
                    },
                },
                evaluation: {
                    name: "evaluation",
                    title: "по рейтингу",
                    iconComponent: "StarIcon",
                    metric() {
                        sendYandexMetric("sort-rating");
                    },
                },
            },
        };
    },
    computed: {
        ...mapState("products", ["products"]),
        ...mapGetters("auth", ["isLoggedIn"]),
        isTablet() {
            return this.$screen.width <= 1024;
        },
    },
    methods: {
        ...mapActions("modals", ["openModal"]),
        changeSort(sortName) {
            if (sortName === this.currentSort) {
                return;
            }
            if (this.recommendationProducts) {
                this.$emit("currentSort", sortName);
                return;
            }
            if (this.sortParam[sortName].metric) {
                this.sortParam[sortName].metric();
            }
            this.$store.commit("products/updateCurrentSort", sortName);
        },
        toggleSort() {
            if (this.$screen.width > 480) {
                this.sortBlock = !this.sortBlock;
            } else {
                this.openModal({
                    modalName: "SortModal",
                    modalData: { title: "Сортировать" },
                    componentProps: { size: "xl", noAnimate: true },
                });
            }

            if (this.sortBlock) {
                sendYandexMetric("sortirovka");
            }
        },
        hideSort() {
            this.sortBlock = false;
        },
    },
};
