import { getByPath } from "./get-set";
import { castArray } from "./helpers";
export const CLEAR_ERRORS_TIMEOUT = 100000;
export const CLEAR_ERRORS_POPUP_TIMEOUT = 5000;

function _getError(obj, keys) {
    if (typeof obj !== "object") {
        return obj;
    }
    let val = null;
    if (keys) {
        keys = castArray(keys);
    } else {
        keys = Object.keys(obj);
    }

    for (let i = 0; i < keys.length; i++) {
        val = getByPath(obj, keys[i]);

        if (typeof val === "object") {
            return _getError(val);
        } else if (val) {
            return val;
        }
    }

    return val || null;
}

export const ErrorsPlugin = {
    install: (Vue, options) => {
        if (Vue.__hasDisplayErrorsPluginMixin__) {
            return;
        }
        Vue.__hasDisplayErrorsPluginMixin__ = true;
        Vue.mixin({
            methods: {
                getDisplayError(err) {
                    // var errVal = _getError(this.$store.state.errors.displayList, err)
                    return _getError(this.$store.state.errors.displayList, err);
                },
                getError(err) {
                    // var errVal = _getError(this.$store.state.errors.errorList, err)
                    return _getError(this.$store.state.errors.list, err);
                },
                /* mapErrors: function (err, params) {
                    return {test: 111}
                }, */
            },
        });
    },
};

export const findAllKeys = (obj, tmpl_keys) => {
    const keys = [];
    if (!tmpl_keys) {
        return keys;
    }
    if (tmpl_keys == "*" && obj) {
        return Object.keys(obj);
    }
    if (!Array.isArray(tmpl_keys)) {
        tmpl_keys = [tmpl_keys];
    }
    tmpl_keys.forEach((key) => {
        if (key.includes("*")) {
            const findKey = key.replace("*", "");
            for (const objKey in obj) {
                if (objKey.includes(findKey)) {
                    keys.push(objKey);
                }
            }
        } else {
            keys.push(key);
        }
    });

    return keys;
};

export const getErrors = (obj, errorKey) => {
    const keys = errorKey ? findAllKeys(obj, errorKey) : Object.keys(obj);
    const values = {};
    keys.forEach((key) => {
        if (obj[key]) {
            values[key] = obj[key];
        }
    });
    return values;
};

export const getError = (obj, errorKey) => {
    if (obj) {
        castArray(errorKey).forEach((key) => {
            if (obj[key]) {
                // eslint-disable-next-line no-undef
                values[key] = obj[key];
            }
        });
    }

    return null;
};

export const hasError = (obj, errorKey) => {
    const keys = findAllKeys(obj, errorKey);
    keys.forEach((key) => {
        if (obj[key]) {
            return true;
        }
    });

    return false;
};
