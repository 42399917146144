//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "CartSmallTruck",
    props: {
        width: {
            type: [Number, String],
            default: 23.418,
        },
        height: {
            type: [Number, String],
            default: 21.283,
        },
        type: { type: String },
    },
};
