import { render, staticRenderFns } from "./TopCart.vue?vue&type=template&id=a8b3e62e&scoped=true&"
import script from "./TopCart.vue?vue&type=script&lang=js&"
export * from "./TopCart.vue?vue&type=script&lang=js&"
import style0 from "./TopCart.vue?vue&type=style&index=0&id=a8b3e62e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8b3e62e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CircleLoader: require('/usr/src/app/components/CircleLoader.vue').default,CartPreview: require('/usr/src/app/components/products/CartPreview.vue').default})
