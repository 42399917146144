export function cloneDeep<T>(obj: T): T {
    if ((!Array.isArray(obj) && typeof obj !== "object") || obj === null) {
        return obj;
    }

    const output = (Array.isArray(obj) ? [] : {}) as T;

    for (const key in obj) {
        output[key] = cloneDeep(obj[key]);
    }

    return output;
}
