import { render, staticRenderFns } from "./catalog.vue?vue&type=template&id=4596392f&scoped=true&"
import script from "./catalog.vue?vue&type=script&lang=js&"
export * from "./catalog.vue?vue&type=script&lang=js&"
import style0 from "./catalog.vue?vue&type=style&index=0&id=4596392f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4596392f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppOnlineChecker: require('/usr/src/app/components/common/AppOnlineChecker.vue').default,HeaderSection: require('/usr/src/app/components/sections/HeaderSection.vue').default,Metrika: require('/usr/src/app/components/Metrika.vue').default,FooterSection: require('/usr/src/app/components/sections/FooterSection.vue').default})
