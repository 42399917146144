import moment from "moment";
import { IncomingMessage } from "connect";

let _defaultLastMod: string | null = null;
export function getDefaultLastMod(): string {
    if (_defaultLastMod === null) {
        const currentMoment = moment();
        const currentWeekday = currentMoment.isoWeekday();

        _defaultLastMod = currentMoment.subtract(currentWeekday - 1, "days").format("YYYY-MM-DD");
    }
    return _defaultLastMod;
}

export function trimUrlSlashes(url: string) {
    return url.trim().replace(/^\/|\/$/g, "");
}

export function getHostFromRequest(req: IncomingMessage): string | undefined {
    return req.headers.host;
}

export function getHostFromLocation(location: Location): string | undefined {
    return location.origin
        .split("/")
        .filter((p): p is string => !!p)
        .slice(-1)[0];
}
