//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AuthBlockMixin from "~/mixins/AuthBlockMixin.js";
import Auth from "~/components/icons/Auth.vue";
import LogoutIcon from "~/components/icons/LogoutIcon.vue";
import UserIcon from "~/components/icons/UserIcon.vue";

export default {
    name: "AuthBlock",
    components: { UserIcon, LogoutIcon, Auth },
    mixins: [AuthBlockMixin],
    computed: {
        canShowBonusGame() {
            return !!this.isLoggedIn && !!this.bonusCard;
        },
        pizzaManGamePath() {
            return "/pizza_man";
        },
    },
};
