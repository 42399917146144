//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import { numberWithSpaces } from "~/util/helpers";
import ProductBeforeDiscountPrice from "~/components/products/ProductBeforeDiscountPrice.vue";

export default {
    name: "ProductActionsMod",
    components: { ProductBeforeDiscountPrice },
    directives: { ClickOutside },
    props: ["location", "product", "viewCatalog", "inPopup", "disabled"],
    data() {
        return {
            scrollValue: 0,
            displayMiniCart: false,
            modifiedProducts: [],
            selectedModifiers: [],
            selectedModifiersQuantity: 0,
        };
    },
    computed: {
        ...mapGetters("cart", ["productsInCart"]),
        ...mapState("metrika", ["paramsAB"]),
        isMobile() {
            return this.$screen.width < 1025;
        },
        getQantity() {
            return this.selectedModifiersQuantity;
        },
        getFilteredSelectedModifiers() {
            return this.selectedModifiers;
        },
        inCatalog() {
            return this.location == "catalog";
        },
        inSearch() {
            return this.location == "search";
        },
        productActionDiscount() {
            const arrModifiers = this.productModifiers;
            if (arrModifiers.length > 0) {
                const minPriceModifier = arrModifiers[0];

                if (minPriceModifier.price_before_discount) {
                    return minPriceModifier.price_before_discount;
                }
            }
            return null;
        },
        productModifiers() {
            let modifierGroupComplement = null;
            let arrModifiers = [];

            if (this.product.modifier_groups?.length > 0) {
                modifierGroupComplement = this.product.modifier_groups
                    .slice()
                    .find((item) => item.is_complement === false);
            }

            if (modifierGroupComplement && this.product.available_modifiers?.length > 0) {
                arrModifiers = this.product.available_modifiers
                    .slice()
                    .filter((item) => item.group_id === modifierGroupComplement.id)
                    .sort((a, b) => (a.price > b.price ? 1 : -1));
            }

            return arrModifiers;
        },
        productActionChooseText() {
            const arrModifiers = this.productModifiers;
            if (arrModifiers.length > 0) {
                const minPriceModifier = arrModifiers[0];
                return `от ${this.formatValue(minPriceModifier.price)} ₽`;
            }
            return `от ${this.formatValue(this.product.price)} ₽`;
        },
        hideButtonInCatalog() {
            return this.disabled && this.inCatalog;
        },
        productInCart() {
            return this.productsInCart?.filter((prod) => {
                return this.product.id == prod.id;
            });
        },
        productCount() {
            return this.productInCart?.reduce((start, accum) => {
                return start + (accum?.quantity || 0);
            }, 0);
        },
    },
    watch: {
        productsInCart() {
            this.updateModifiersInfo();
        },
    },
    mounted() {
        this.updateModifiersInfo();
    },
    methods: {
        ...mapActions("modals", ["openModal"]),
        showProductPopup(payload) {
            const modalPayload = Object.assign({}, payload, { product: this.product });
            this.$store.dispatch("productPopup/showProductByPayload", { product: this.product });
        },
        formatValue(value) {
            return numberWithSpaces(value);
        },
        toggleCart() {
            if (this.isMobile) {
                this.$store.commit("productPopup/setModifiersButtonClicked", true);
                this.showProductPopup(this.product);
            } else {
                this.displayMiniCart = !this.displayMiniCart;
            }
        },
        showCart() {
            this.displayMiniCart = true;
        },
        hideCart() {
            this.displayMiniCart = false;
        },
        getModifiedProducts() {
            if (this.product.available_modifiers?.length) {
                const productsWithModifiers = [];
                this.productsInCart.forEach((prod) => {
                    if (prod.id === this.product.id && prod.sel_modifiers?.length) {
                        productsWithModifiers.push(prod);
                    }
                });
                this.modifiedProducts = [];
                this.modifiedProducts = productsWithModifiers;
                this.selectedModifiersQuantity = productsWithModifiers.length || 0;
            }
        },
        getSelectedModifiers() {
            this.modifiedProducts.forEach((prod) => {
                prod.sel_modifiers.forEach((mod) => {
                    if (mod.price && mod.name && mod.quantity > 0) {
                        this.selectedModifiers.push(mod);
                    }
                });
            });
        },
        getSelectedModifiersQuantity() {
            let sum = 0;
            this.modifiedProducts.forEach((product) => {
                sum += product.quantity;
            });
            this.selectedModifiersQuantity = sum;
        },
        updateModifiersInfo() {
            this.getModifiedProducts();
            this.getSelectedModifiers();
            this.getSelectedModifiersQuantity();
        },
    },
};
