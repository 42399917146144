//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "Telegram",
    props: {
        width: {
            type: [Number, String],
            default: 17,
        },
        height: {
            type: [Number, String],
            default: 14,
        },
        type: { type: String },
        fill: {
            type: String,
            default: "#fff",
        },
    },
};
