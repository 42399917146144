//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ModifierGroupsMixin } from "product-modifier-groups/lib";
import { mapGetters, mapState } from "vuex";
import ModifierProductGroup from "~/components/products/ModifiersProductGroup.vue";
import StopListMixin from "~/mixins/StopListMixin";

export default {
    name: "ModifierProduct",
    components: { ModifierProductGroup },
    mixins: [ModifierGroupsMixin, StopListMixin],
    props: ["product", "modifiers", "groups", "productMod", "inCart"],
    computed: {
        ...mapState("metrika", ["paramsAB"]),
        ...mapGetters("products", ["getModifiersErrors"]),
        isShowGroupsModifiers() {
            return this.modifierGroups.some((group) => this.showGroupModifier(group));
        },
        sortedGroups() {
            const sorted = {
                main: [],
                compliment: [],
                multi: [],
            };

            this.modifierGroups.forEach((group) => {
                // assemble_yourself_combo
                if (group.assemble_yourself_combo) {
                    sorted.multi.push(group);
                } else if (!group.is_complement) {
                    sorted.main.push(group);
                } else {
                    sorted.compliment.push(group);
                }
            });

            return sorted;
        },
    },
    watch: {
        getModifiersErrors(nw) {
            if (nw.length) {
                const el = this.$refs.modifier_wrapper.querySelector(`#group${nw[0]}`);
                if (el) {
                    el.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                    });
                }
            }
        },
    },
    updated() {
        const modsStop = this.checkModsStop(this.sortedGroups, this.modifiers);
        this.$emit("modsStop", modsStop);
    },
    mounted() {
        if (this.inCart) {
            this.selectedModifiers = this.selected;
        }

        if (this.product.sel_modifiers) {
            this.product.sel_modifiers.forEach((item) => {
                this.chooseModifiers(this.getGroupByGroupId(item.group_id), this.getModifierByModifierId(item.id));
            });
        }

        this.$emit("selectProductMod", this.baseProduct);

        const modsStop = this.checkModsStop(this.sortedGroups, this.modifiers);
        this.$emit("modsStop", modsStop);
    },
    methods: {
        showGroupModifier(group) {
            if (this.inCart) {
                return !!this.getGroupSelectedModifiers(group)?.length;
            }
            return !!this.getGroupModifiers(group)?.length;
        },
        getGroupByGroupId(id) {
            return this.groups.find((group) => group.id == id);
        },
        getModifierByModifierId(id) {
            return this.modifiers.find((modifier) => modifier.id == id);
        },

        chooseModifiers({ group, modifierByGroup }) {
            this.clickModifier(group, modifierByGroup);
            if (!group.is_complement) {
                this.$emit("selectProductMod", this.baseProduct);
            }
        },
        plusModifier({ group, modifierByGroup, isMaxInGrop, isMaxModifier }) {
            if (isMaxInGrop || isMaxModifier) {
                return;
            }
            this.increaseModifier(group, modifierByGroup);
        },
        minusModifier({ group, modifierByGroup }) {
            const mod = this.selectedModifiers.find((selMod) => selMod.id === modifierByGroup.id);
            if (mod && mod.quantity - 1 !== 0) {
                this.decreaseModifier(group, modifierByGroup);
            } else {
                const idx = this.findSelectedModifierIndex(group, modifierByGroup);
                this.removeModifierByIndex(idx);
                this.onChanges();
            }
        },
    },
};
