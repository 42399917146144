import { render, staticRenderFns } from "./LogoutIcon.vue?vue&type=template&id=54156e61&scoped=true&"
import script from "./LogoutIcon.vue?vue&type=script&lang=js&"
export * from "./LogoutIcon.vue?vue&type=script&lang=js&"
import style0 from "./LogoutIcon.vue?vue&type=style&index=0&id=54156e61&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54156e61",
  null
  
)

export default component.exports