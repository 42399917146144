//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Trash from "~/components/icons/Trash.vue";
import Gear from "~/components/icons/Gear.vue";

export default {
    name: "PreviewModifier",
    components: { Gear, Trash },
    props: ["product"],
    comuted: {
        isUnset() {
            return this.unset;
        },
    },
    data() {
        return {
            unset: false,
            modifiersNames: null,
        };
    },
    mounted() {
        this.getModifiersNames();
    },
    methods: {
        showProductPopup(product) {
            this.$store.dispatch("productPopup/showProductByPayload", { product });
        },
        async removeProduct() {
            await this.$store.dispatch("products/deleteProduct", this.product);
        },
        getModifiersNames() {
            if (this.product?.sel_modifiers) {
                this.modifiersNames = this.product.sel_modifiers
                    .map((mod) => {
                        return mod.name;
                    })
                    .join(", ");
            }
        },
        unsetProduct() {
            this.unset = true;
        },
    },
};
