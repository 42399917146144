//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "PaymentVisa",
    props: {
        width: {
            type: [Number, String],
            default: 48,
        },
        height: {
            type: [Number, String],
            default: 17,
        },
        type: { type: String },
    },
};
