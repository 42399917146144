import { AbstractStorage } from "../abstract-storage/index";
import { cityMetatags, MetaResponse } from "~/store_types/meta.types";

export class MetaStorage extends AbstractStorage {
    protected getKey() {
        const cityId = this.getCityId();
        return `_meta.${cityId}`;
    }

    protected getLogName() {
        return "META_STORAGE";
    }

    protected getUpdatePeriod(): number | null {
        return 60;
    }

    protected getRemovePeriod(): number | null {
        return 15 * 60;
    }

    protected async apiRequest(): Promise<void | cityMetatags[]> {
        try {
            const metaResponse: MetaResponse = await this.getApi().get("/info/metatags");

            if (!metaResponse.success) {
                throw metaResponse;
            }

            return metaResponse.data;
        } catch (error) {
            console.warn("Ошибка запроса метатегов");
            console.error(error);
        }
    }
}
