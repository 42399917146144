import cityLinksHelper from "~/util/city-links-helper";

type LaunchCitiesMap = { [key: string]: LaunchCityItem };

export interface LaunchCityItem {
    path: string;
    name: string;
    id: number;
    teamMember: TeamMember;
    department_id: number;
    product_id: number;
    citySoc: { [key: string]: SitySoc } | null;
}

interface TeamMember {
    description: string;
    image: string;
    soc: { [key: string]: TeamMemberSoc } | Object;
}
interface TeamMemberSoc {
    path: string;
    name?: string;
}

interface SitySoc {
    path: string;
    name: string;
}

const cities: LaunchCityItem[] = [
    // {
    //     path: "/tobolsk",
    //     title: "Тобольск",
    //     citySoc: {
    //         vk: {
    //             path: "https://vk.com/sushkof.pizza_tobolsk",
    //             name: "vk",
    //         },
    //         telegram: {
    //             path: "https://t.me/eda1_tobolsk",
    //             name: "telegram",
    //         },
    //     },
    //     department_id: 585,
    //     product_id: 1,
    //     teamMember: {
    //         description:
    //             "Хотим познакомить вас с&nbsp;владельцем ресторана в&nbsp;Тобольске&nbsp;&mdash; Алексеем. Алексей владеет уже 3&nbsp;ресторанами в&nbsp;Тюмени и&nbsp;знает что ценят в&nbsp;доставке. Если у&nbsp;вас появились вопросы или пожелания&nbsp;&mdash; напишите ему.",
    //         image: "pavel-deryagin.jpg",
    //         soc: {
    //             telegram: {
    //                 path: "https://t.me/pavlovv_alexey",
    //                 name: "@pavlovv_alexey",
    //             },
    //         },
    //     },
    // },
];

const citiesMap: LaunchCitiesMap = cities.reduce((carry, item) => {
    carry[item.path] = item;
    return carry;
}, {} as LaunchCitiesMap);

function getLaunchCityItemByPath(path: string): LaunchCityItem | undefined {
    // НЕ route.fullPath, нужен именно route.path
    const getCitySlug = cityLinksHelper.getCityItemFromPath(path)?.slug;
    return citiesMap[`/${getCitySlug}`];
}

function getLaunchCities() {
    return [...cities];
}

export default {
    getLaunchCityItemByPath,
    getLaunchCities,
};
