//
//
//
//
//
//
//
//
//

export default {
    name: "MenuDelimeter",
    props: ["title"],
};
