import { CookieSerializeOptions } from "cookie";
import { NuxtCookies } from "cookie-universal-nuxt";

export const DEFAULT_COOKIES_OPTIONS: CookieSerializeOptions = {
    maxAge: 365 * 24 * 3600,
    path: "/",
    secure: true,
};

export function coalesceCookiesValue(cookies: NuxtCookies | undefined, key: string, defaultValue: any = undefined) {
    if (!cookies) {
        return defaultValue;
    }

    // Сначала пробуем взять значение из куков ответа
    const resValue = cookies.get(key, { fromRes: true });
    if (resValue !== undefined) {
        return resValue;
    }

    // Затем пробуем взять из куков запроса
    const reqValue = cookies.get(key, { fromRes: false });
    if (reqValue !== undefined) {
        return reqValue;
    }

    return defaultValue;
}
