import Vue from "vue";
import moment from "moment";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { cloneDeep } from "../util/clone";
import { ACCOUNT_TABS, DEFAULT_CITY_ID, DELIVERY_TIME, DELIVERY_TYPE, ORDER_STEPS } from "../util/types";
import { getEcommerceProductData, getTime, orderBy } from "../util/helpers";
import cartUtils from "../util/cart";
import { EcommerceYandex, canUseEcommerce } from "../service/ecommerce";
import { CartState } from "~/store_types/cart.types";
import { RootState } from "~/store_types/index.types";
import { CartProduct } from "~/util/api.types";
import { NuxtCookies } from "~/node_modules/cookie-universal-nuxt";
import { PAYMENT_IFRAME_QUERY_KEY, PaymentOnlineType, PaymentType } from "~/store_types/payment.types";
import { log, logEcommerce } from "~/service/client-logger";
import { sendYandexMetric } from "~/service/ecommerce";
// import sharedCart from '../../service/shared_cart';
// import {CHANGES_COMMENTS, KITCHEN_COMMENTS} from '../../util/types'

const root = { root: true };
const CART_REFRESH_INTERVAL = 20 * 60; // Время перезапроса корзины с сервера (сек)

export const state: () => CartState = () => ({
    content: null,
    ecommerceOrders: {},
    showCourierTracking: false,
    markdown: false,
    currentOrderCode: null,
    lastRefreshTimestamp: null,
    onlineProcessingFlag: false,
    ecoAvailable: null,
    donateInfo: null,
    //     exchange: CHANGES_COMMENTS.CALL_CHANGE,
});

export const getters: GetterTree<CartState, RootState> = {
    isCartLoaded: (state) => {
        return !!state.content;
    },

    currentOrderCode: (state, getters, rootState, rootGetters) => {
        if (!state.content) {
            return "";
        }
        // console.log("getCurrentOrderCode", state.currentOrderCode, state.content.current_order_code);
        return state.currentOrderCode || state.content.current_order_code;
    },
    totals: (state, getters) => {
        if (!state.content) {
            return {};
        }
        return {
            summ: state.content.total_amount,
            summClear: state.content.total_amount_clean,
            summClearBeforeDiscount: state.content.total_amount_before_discount,
            discountPercent: state.content.discount_percent,
            discountPromoPercent: state.content.promoevent_discount_percent,
            // discount withot products discount
            discount:
                state.content.discount_amount -
                (state.content.total_amount_before_discount - state.content.total_amount),
            total_discount: state.content.total_amount - state.content.total_amount_clean,
            bonus: state.content.paid_by_bonuses || 0,
            deliveryPrice: getters.deliveryPrice,
            hasDiscount:
                0 + state.content.total_amount + parseInt(getters.deliveryPrice) != state.content.total_amount_clean,
            totalCount: state.content.products ? state.content.products.length : 0,
            info: state.content.products.reduce((res, val) => {
                if (!val.info) {
                    return res;
                }

                for (const infoKey of Object.keys(val.info)) {
                    const infoVal = val.info[infoKey];

                    if (res[infoKey] == undefined) {
                        res[infoKey] = 0;
                    }

                    res[infoKey] += infoVal ? parseInt(String(infoVal).replace(/[^[0-9]/, "")) * val.quantity : 0;
                }

                return res;
            }, {}),
        };
    },
    productsInCart: (state) => {
        if (!state.content) {
            return [];
        }
        return state.content.products;
    },
    deliveryDepartmentId: (state) => {
        return state.content && state.content.delivery_department_id ? state.content.delivery_department_id : null;
    },
    deliveryAddress: (state) => {
        return state.content && state.content.delivery_address ? state.content.delivery_address : null;
    },
    //     deliveryPrice: (state) => {
    //         return state.content.delivery_price ? state.content.delivery_price : 0;
    //     },
    emptyCart: (state, getters, rootState, rootGetters) => {
        if (!state.content) {
            return true;
        }
        return state.content.products && state.content.products.length == 0;
    },
    bonusGained: (state, getters, rootState, rootGetters) => {
        if (!state.content) {
            return 0;
        }
        const defaultPercent = rootState.account.bonusDefaultPercent;
        if (!rootGetters["account/bonusCard"] && defaultPercent) {
            return Math.floor((state.content.total_amount_clean * defaultPercent) / 100);
        }
        return state.content.bonuses_gained;
    },
    activeCompensations: (state) => {
        if (!state.content) {
            return [];
        }
        return state.content.compensations == undefined ? [] : state.content.compensations;
    },
    getEcoOptions: (state) => {
        return state.content && state.content.eco_options ? state.content.eco_options : null;
    },
    ingredientsChange: (state) => {
        if (!state.content) {
            return {};
        }
        if (!state.content.ingredients_changes) {
            return {};
        }

        return state.content.ingredients_changes.reduce((carry, item) => {
            carry[item.id] = item;
            return carry;
        }, {});
    },
    // Делим все продукты на доступные и те что в стопе (по количеству или есть замена)
    dividedByStopListProducts(state: CartState, getters): { stopped: CartProduct[]; available: CartProduct[] } | null {
        if (!state.content || !state.content.products) {
            return null;
        }

        const stopped: CartProduct[] = [];
        const available: CartProduct[] = [];

        for (const p of state.content.products) {
            const isMaxQuantityExceed =
                p.max_quantity !== null &&
                p.max_quantity !== undefined &&
                p.max_quantity !== -1 &&
                p.max_quantity < p.quantity;
            const hasIngredientsChange = !!p.possible_ingredients_changes;

            if (!isMaxQuantityExceed && !hasIngredientsChange) {
                available.push(p);
                continue;
            }

            const product = cloneDeep(p);

            if (hasIngredientsChange && product.possible_ingredients_changes) {
                product.possible_ingredients_changes = product.possible_ingredients_changes.map((changeId) => {
                    const changeInfo = getters.ingredientsChange[changeId];
                    return changeInfo && !changeInfo.approval ? changeInfo : null;
                });

                product.possible_ingredients_changes = product.possible_ingredients_changes.filter((c) => !!c);
                if (product.possible_ingredients_changes.length === 0) {
                    available.push(p);
                    continue;
                }
            }

            stopped.push(product);
        }

        return { stopped, available };
    },
    stopListProducts: (state, getters) => {
        return getters.dividedByStopListProducts && getters.dividedByStopListProducts.stopped
            ? getters.dividedByStopListProducts.stopped
            : [];
    },
    availableProducts: (state, getters) => {
        return getters.dividedByStopListProducts && getters.dividedByStopListProducts.available
            ? getters.dividedByStopListProducts.available
            : [];
    },
    hasStopListProducts: (state, getters) => {
        return getters.stopListProducts.length > 0;
    },
    getFullDeliveryTime: (state) => {
        if (!state.content) {
            return;
        }
        return state.content.delivery_time;
    },
    getOnlineError: (state) => {
        return state.content?.online_error;
    },
    closestDepartmentsIdStr(state, getters) {
        return getters.closestDepartments.map((item) => item.id).join(",");
    },
    closestDepartments: (state) => {
        return state.content?.closest_departments || [];
    },
    cartDepartmentId(state) {
        return state.content?.cart_department_id;
    },
    cityId(state) {
        return state.content?.city_id;
    },
    deliveryType(state) {
        return state.content?.delivery_type || DELIVERY_TYPE.DELIVERY;
    },

    schedule: (state) => {
        return state.content?.schedule || [];
    },
};

export const mutations: MutationTree<CartState> = {
    initLocalCart(state) {
        Vue.set(
            state,
            "content",
            Object.assign({}, state.content, {
                total_amount: 0,
                total_amount_clean: 0,
                products: [],
                additional_products: [],
                delivery_type: "",
                payment_info: { payment_type: null },
                discount_percent: 0,
                discount_amount: 0,
            })
        );
    },
    setCart(state, newCart) {
        // console.log('%cnew cart is ', 'font-size:25px; color: white; background-color: green', newCart)
        state.content = newCart;

        if (!newCart.delivery_time) {
            Vue.set(
                state,
                "content",
                Object.assign({}, state.content, { delivery_time: moment().add(15, "m").format() })
            );
        }

        state.lastRefreshTimestamp = getTime();
    },
    setProductData(state, payload) {
        if (!state.content) {
            return;
        }
        const productIdx = state.content.products.findIndex((prod) => {
            const cartProdModIds = orderBy(
                (prod.sel_modifiers || []).map((m) => (typeof m.id === "string" ? parseInt(m.id) : m.id))
            ).join("_");
            const payloadModIds = orderBy(
                (payload.modifiers || []).map((m) => (typeof m.id === "string" ? parseInt(m.id) : m.id))
            ).join("_");

            return payload.id == prod.id && payloadModIds == cartProdModIds;
        });

        payload.sel_modifiers = payload.modifiers;

        if (productIdx < 0) {
            state.content.products.push(payload);
        } else if (payload.quantity <= 0) {
            state.content.products.splice(productIdx, 1);
        } else {
            const product = state.content.products[productIdx];

            product.quantity = Math.max(payload.quantity, 0);
            product.max_quantity = payload.max_quantity;
        }
    },
    setTotalAmountClean(state, payload) {
        if (!state.content) {
            return;
        }

        state.content.total_amount_clean = payload;
    },
    setAdditionalProductCnt(state, additionalProductsMap) {
        if (!state.content) {
            return;
        }
        state.content.additional_products
            .flatMap((ap) => ap.products)
            .forEach((product) => {
                product.selected_count = Math.max(additionalProductsMap[product.id] || 0, 0);
            });
    },
    setDeliveryAddress(state, address) {
        if (!state.content) {
            return;
        }
        state.content.delivery_address = address;
    },
    setDeliveryType(state, type) {
        if (!state.content) {
            return;
        }
        state.content.delivery_type = type;
    },
    //     setSmsConfirmCode(state, val) {
    //         state.sms_confirm_code = val;
    //     },
    setEcommerceOrders(state, orderId) {
        Vue.set(state.ecommerceOrders, orderId, orderId);
    },
    setSelectedGifts(state, gift) {
        if (!state.content) {
            return;
        }
        const selected = state.content.selected_gifts || [];
        const selectedGift = selected.find((sg) => sg.id == gift.id);
        if (selectedGift) {
            Vue.set(selectedGift, "products", gift.products);
        } else {
            Vue.set(state.content, "selected_gifts", [gift]);
        }
    },
    setBonusPointsUsed(state, points) {
        if (!state.content) {
            return;
        }
        Vue.set(state.content, "paid_by_bonuses", points);
    },
    setMarkdown(state, val) {
        state.markdown = val;
    },
    resetGifts(state) {
        if (!state.content) {
            return;
        }
        state.content.available_gifts = [];
        state.content.selected_gifts = [];
    },
    setOnlineProcessingFlag(state, value) {
        state.onlineProcessingFlag = value;
    },
    setCurrentOrderCode(state, value) {
        state.currentOrderCode = value;
    },
    setShowCourierTracking(state, val) {
        state.showCourierTracking = val;
    },
    calculateSumm(state) {
        if (!state.content) {
            return;
        }
        const amounts = cartUtils.calculateTotalAmounts(state.content);

        state.content.total_amount = amounts.totalAmount;
        state.content.total_amount_clean = amounts.totalAmountClean;
    },
    setApiMinTime(state, time) {
        state.postCartMinimumTime = time;
    },
    setEcoAvailable(state, item) {
        state.ecoAvailable = item;
    },
    setEcoProductsData(state, payload) {
        if (!state.content) {
            return;
        }
        Vue.set(state.content, "eco_options", payload);
    },
    setDonateInfo(state, payload) {
        state.donateInfo = payload;
    },
};

export const actions: ActionTree<CartState, RootState> = {
    async loadCart({ state, commit, dispatch }, needUpdate) {
        try {
            const resp = await this.$api.get("cart", { need_update: Boolean(needUpdate) });
            // const isSameDelivery = sharedCart.isSameDeliveryData(state.content, resp);
            commit("setCart", resp);
            /* if(!isSameDelivery) {
                dispatch('delivery/setCartDepartmentId', state.content, {root: true});
            } */
        } catch (err: any) {
            console.warn("Ошибка загрузки корзины");
            console.error(err);
            return Promise.reject("Ошибка загрузки корзины");
        }
    },
    async getEcoAvailable({ commit }) {
        const resp = await this.$api.get("cart/ecoAvailable");
        commit("setEcoAvailable", resp);
    },
    async getDonateInfo({ commit }, payload) {
        const resp = await this.$api.get(`info/foundDonationOffers?order_id=${payload}`);
        commit("setDonateInfo", resp);
    },
    async setEcoProducts({ commit }, payload) {
        commit("setEcoProductsData", payload);
        await this.$postCartQueue.add("cart/eco", { options: payload });
    },
    completeOrder({ commit, dispatch }, orderCode) {
        commit("setCurrentOrderCode", orderCode);
        dispatch("clearCart");
    },
    async clearCart({ dispatch, getters, commit, rootGetters }) {
        if (rootGetters["delivery/isDelivery"] && !rootGetters["account/findBetOfferOrder"]) {
            // commit('setShowCourierTracking', true);
            dispatch("modals/openModal", { modalName: "TrackCourier" }, root);
        }

        commit("initLocalCart");

        commit("setDeliveryAddress", null);
        commit("setApiMinTime", null);
        commit("setDeliveryType", null);
        dispatch("clearPaymentTokens", true);
        commit("setMarkdown", false);
        commit("payment/setSmsSubscription", true, root);
        commit("payment/setEmailSubscription", true, root);
        commit("payment/setChangeFrom", null, root);
        commit("payment/updateBonusCardCode", null, root);
        commit("payment/clearBonusCard", null, root);
        commit("delivery/setCurrentDateTime", null, root);
        commit("delivery/setDeliverySelfDepartment", null, root);
        commit("delivery/setTimeType", DELIVERY_TIME.CURRENT, root);
        commit("delivery/setDeliveryAddress", null, root);
        commit("delivery/setDeliveryAddressComment", null, root);
        commit("delivery/clearLastUpdateTime", null, root);
        commit("delivery/setDeliveryAddressAdditional", {}, root);
        commit("form/setChanged", true, root);
        commit("delivery/setLastDeliveryUpdate", null, root);
        commit("account/setLastUpdated", null, root);
        commit("payment/setPaymentUrl", null, root);
        await dispatch("deleteCart");
    },
    async deleteCart({ commit }) {
        const resp = await this.$api.delete("cart");
        if (resp && resp.success && resp.cart) {
            commit("setCart", resp.cart);
        }
    },
    clearPaymentTokens({ commit }, clearCurrentCard) {
        commit("payment/setPaymentToken", null, root);
        if (clearCurrentCard) {
            commit("payment/setSaveCard", true, { root: true });
            commit("payment/setPaymentCardId", null, { root: true });
        }
    },
    onlinePaymentError({ commit, dispatch, rootGetters, rootState, state }, error) {
        dispatch("wait/end", "cart.save", root);
        dispatch("errors/addError", { "cart.online": error }, root);
        dispatch("clearPaymentTokens");
    },
    async setCompensationID({ commit }, id) {
        const ajaxResp = await this.$postCartQueue.add(
            "cart/compensations",
            {
                compensations: id,
                queueKey: id.join(","),
            },
            500
        );

        if (!ajaxResp.success === true) {
            alert("Ошибка!");
        }
    },
    async onAfterCartSend({ commit, dispatch, rootState, rootGetters }, resp) {
        commit("order/setCurrentPhone", rootGetters["form/getPhone"], root);

        try {
            if (resp.qr_url) {
                commit("payment/setQrId", resp.qr_id, root);
                commit("payment/setQrUrl", resp.qr_url, root);

                await dispatch(
                    "modals/openModal",
                    {
                        modalName: "PaymentQrModal",
                        componentProps: { size: "xl" },
                        modalData: { link: resp.qr_url },
                    },
                    { root: true }
                );
                if (this.$screen.width! <= 768) {
                    window?.open(resp.qr_url, "_blank")?.focus();
                }

                return Promise.reject({
                    error_code: "need_pay",
                    error_message: "Необходимо заплатить",
                });
            }

            if (resp.payment_url) {
                commit("setOnlineProcessingFlag", true);
                commit("payment/setPaymentUrl", resp.payment_url, root);
                this.$router.push(`${this.$router.currentRoute.path}?${PAYMENT_IFRAME_QUERY_KEY}`);
                return Promise.reject({
                    error_code: "need_pay",
                    error_message: "Необходимо заплатить",
                });
            }

            const res = await dispatch("loadCart");

            if (resp.success) {
                dispatch("completeOrder", resp.order_number);
                sendYandexMetric("typePayment", { method: rootState.payment.currentPaymentType?.id });
                await this.$router.push(`/order/finish?code=${resp.order_number}`);
            } else {
                console.warn("Ошибка запроса после отправки корзины");
                console.error(resp);
                return Promise.reject("error");
            }
        } catch (err) {
            console.warn("Ошибка после отправки корзины");
            console.error(err);
            return Promise.reject(err);
        } finally {
            dispatch("wait/end", "cart.save", root);
        }

        // reject();
    },
    setCart({ state, commit }, cart) {
        if (state.content?.total_amount_clean != cart.total_amount_clean) {
            commit("payment/setChangeFrom", null, root);
        }

        commit("setCart", cart);
    },
    onErrorCartSend({ rootState, rootGetters, commit, dispatch }, err) {
        // в сообщении об ошимбке моджет прийти обновленная корзина
        if (err.cart) {
            commit("setCart", err.cart);
        }
        // очистим токены apple pay\google pay
        dispatch("clearPaymentTokens");
        dispatch("wait/end", "cart.save", root);

        const error_code = err.error_code;
        const error_message = err.error_message;
        // console.log(error_code,  error_message)

        let showError = true;

        switch (error_code) {
            case "sms_confirmation":
                showError = false;
                Vue.bus.emit("sms_confirmation");
                dispatch(
                    "modals/openModal",
                    {
                        modalName: "ConfirmOrder",
                        modalData: {
                            isCert: false,
                            phone: rootGetters["form/getPhone"],
                        },
                    },
                    root
                );
                console.log(err);
                break;
            case "stop_list":
                showError = false;
                dispatch(
                    "modals/openModal",
                    {
                        modalName: "NotificationModal",
                        modalData: { title: error_message },
                    },
                    root
                );
                // Vue.bus.emit('stop_list', { products: err.products });
                break;
            case "time_changed":
                // eslint-disable-next-line no-case-declarations
                // const newTime = moment.parseZone(err.cart.delivery_time);
                // eslint-disable-next-line no-case-declarations
                // const isSameDate = newTime.isSame(moment.parseZone(), "day");
                // error_message += " на " + newTime.format(isSameDate ? "HH:mm" : "DD.MM.YYYY HH:mm");
                dispatch(
                    "modals/openModal",
                    {
                        modalName: "InfoModal",
                        modalData: {
                            text: error_message,
                            confirmation: true,
                            yes: "Подтвердить",
                            no: "Другое время",
                            agreeHandler: () => {
                                dispatch("order/nextStep", null, root);
                            },
                            regretHandler: () => {
                                dispatch("order/gotoStep", ORDER_STEPS.DETAILS, root);
                            },
                        },
                    },
                    root
                );
                break;
            case "online_error":
                dispatch("clearPaymentTokens");
                dispatch("errors/addPopupError", { errorTitle: error_message, errorKey: "online_error" }, root);
                break;
            case "need_pay":
                showError = false;
                return err;
            case "cart.online_error":
                dispatch(
                    "modals/openModal",
                    {
                        modalName: "NotificationModal",
                        modalData: { title: error_message || "Неизвестная ошибка" },
                    },
                    root
                );
                break;
            default:
                console.log(error_code, error_message);
                dispatch(
                    "modals/openModal",
                    {
                        modalName: "NotificationModal",
                        modalData: { title: error_message || "Неизвестная ошибка" },
                    },
                    root
                );
        }

        return showError ? { ["cart." + error_code]: error_message } : null;
    },
    async send({ commit, dispatch, rootGetters, rootState, state }) {
        // На случай, если будут тестировать сохранение заказа, оно идёт в прод
        // return;

        dispatch("wait/start", "cart.save", root);

        const sendMethod = "send";
        const sendData = await cartUtils.getCartRequestData();

        if (screen?.width <= 1024) {
            sendData.mobile_site = true;
        }

        dispatch("errors/clearError", "cart", root);

        // показать Google Pay
        if (rootGetters["payment/isGooglePay"] && !rootState.payment.paymentToken) {
            Vue.bus.emit("gpay");
            return Promise.reject("Google Pay requested");
        }

        // показать Apple Pay
        if (rootGetters["payment/isApplePay"] && !rootState.payment.paymentToken) {
            Vue.bus.emit("apay");
            return Promise.reject("Apple Pay requested");
        }

        // оплатить CloudPayments
        if (
            rootGetters["payment/isOnlineCardPayment"] &&
            // нет токена для оплаты и не выбрана сохранённная карта
            !rootState.payment.paymentToken &&
            rootGetters["payment/emptyPaymentCardId"] &&
            // пока только клауд
            rootGetters["payment/isOnlineCloudPayment"]
        ) {
            Vue.bus.emit("cloudpayment");
            return Promise.reject("CloudPayments requested");
        }

        EcommerceYandex.reachGoal("start_cook");
        dispatch("metrika/sendCharityProductGoal", {}, { root: true });

        try {
            const resp = await this.$api.post("cart/send", sendData);
            await dispatch("onAfterCartSend", resp);
        } catch (err) {
            const errResult = await dispatch("onErrorCartSend", err);
            return Promise.reject(errResult);
        }

        // const resp = await this.$api.post('cart/send', sendData)
        //     .then(resp => {
        //         dispatch('onAfterCartSend', resp).then(() => {
        //             resolve();
        //         }).catch((err) => {
        //             reject(err);
        //         })
        //     })
        //     .catch((err) => {
        //         dispatch('onErrorCartSend', err)
        //             .then(errResult => {
        //                 reject(errResult);
        //             });
        //     }).finally(() => {
        //
        // });
    },

    completeOnline({ commit, dispatch }, payload) {
        if (payload.order_number) {
            dispatch("completeOrder", payload.order_number);

            if (!payload.cancelRoute) {
                this.$router.push(`/order/finish?code=${payload.order_number}`);
            }
        } else {
            if (payload.error) {
                dispatch(
                    "errors/addPopupError",
                    {
                        errorKey: "cart.online_error",
                        errorTitle: payload.error,
                    },
                    root
                );
            }
            dispatch("clearPaymentTokens");
        }
        dispatch("wait/end", "cart.save", root);
    },

    async repeatOrder({ commit, dispatch, rootGetters, rootState, state }, payload) {
        dispatch("wait/start", "cart.repeat_order", root);

        const order = payload.order;
        const replace = Boolean(payload.replace);

        if (!order || !order.products) {
            return;
        }

        const products: any[] = [];
        order.products.forEach((prod) => {
            products.push({
                id: prod.id,
                quantity: prod.quantity,
                modifiers: prod.sel_modifiers,
            });
        });

        try {
            const resp = await this.$postCartQueue.add(
                "cart/products",
                {
                    products,
                    replace,
                    ignore_invalid_products: true,
                },
                0
            );
        } catch (error: any) {
            console.warn("Ошибка повторного заказа");
            console.error(error);
            return Promise.reject("Ошибка повторного заказа");
        } finally {
            dispatch("wait/end", "cart.repeat_order", root);
        }
    },
    sendEcommerce({ commit, dispatch, state, rootState }, order) {
        if (!order) {
            return;
        }
        if (state.ecommerceOrders[order.id]) {
            return;
        }
        if (!order.products) {
            return;
        }

        const orderId = order.id;

        try {
            logEcommerce("sendEcommerce:prepare_data", orderId);
            const metadata = rootState.products.metadata;
            const orderProducts: any = [];
            order.products.forEach((prod) => {
                const md = metadata[prod.id];
                let ecomerceProduct = Object.assign({ quantity: prod.quantity }, getEcommerceProductData(prod));
                if (md) {
                    ecomerceProduct = Object.assign(ecomerceProduct, getEcommerceProductData(md));
                }
                orderProducts.push(ecomerceProduct);
            });
            logEcommerce("sendEcommerce:commit_setEcommerceOrders", orderId);
            commit("setEcommerceOrders", order.id);
            const promocode = order.promocodes ? order.promocodes[0] : null;

            logEcommerce("sendEcommerce:canUseEcommerce_check", orderId, {
                canUseEcommerce: canUseEcommerce(),
                // @ts-ignore
                globalExists: window.Ecommerce !== undefined,
            });
            if (canUseEcommerce()) {
                // @ts-ignore
                window.Ecommerce.orderStepFinish(
                    orderProducts,
                    order,
                    promocode
                ); /* eslint-disable-line dot-notation */
            }
        } catch (err) {
            logEcommerce("error", order.id);
        }
    },
    validate({ commit, dispatch, state }) {
        console.log("cart validate");
        EcommerceYandex.reachGoal("test_validate");
        return true;
    },
    async refreshCartData({ state, commit, dispatch }) {
        if (state.lastRefreshTimestamp && state.lastRefreshTimestamp + CART_REFRESH_INTERVAL > getTime()) {
            return false;
        }
        await dispatch("loadCart", true);
        return true;
    },
    checkOnlineProcessingFlag({ state, commit }) {
        if (state.onlineProcessingFlag) {
            this.$postCartQueue.add("cart/unlockOnline", {}, 0).then(() => {
                commit("setOnlineProcessingFlag", false);
            });
        }
    },
    async repeatLastOrder({ dispatch }, payload) {
        try {
            await this.$postCartQueue.add("/cart/repeatLastOrder", payload);
        } catch (error: any) {
            if (error.error_code == "not_available") {
                dispatch(
                    "modals/openModal",
                    {
                        modalName: "NotificationModal",
                        modalData: { title: error.error_message },
                    },
                    root
                );
            }
        }
    },
    // Меняем сумму корзины локально (избегаем лоадера)
    changeAmountClean({ dispatch, getters, state, commit, rootState }, paylod) {
        const productsCartSumm = getters.productsInCart.reduce((accumulator, currentProduct) => {
            let priceProductWithMod;
            let haveSubstituteMod;

            // Считаем с учетом модификаторов (если товар с корзины (бэк) price уже посчитан)
            if (currentProduct.sel_modifiers?.length && currentProduct.local) {
                priceProductWithMod = currentProduct.sel_modifiers.reduce((summ, mod) => {
                    if (!mod.is_complement) {
                        haveSubstituteMod = true;
                    }

                    return summ + mod.price * mod.quantity;
                }, 0);

                priceProductWithMod = haveSubstituteMod
                    ? priceProductWithMod
                    : currentProduct.price + priceProductWithMod;
            }

            return accumulator + (priceProductWithMod || currentProduct.price) * currentProduct.quantity;
        }, 0);

        commit("setTotalAmountClean", productsCartSumm);
    },
    //     async lockCart({getters}) {
    //         if(!getters.isSharedCart) {
    //             return;
    //         }

    //         try {
    //             await api.postCartQueue.add('cart/lockCart', {}, 0);
    //         } catch(err) {
    //             sharedCart.onError(err.error_message);
    //             return Promise.reject();
    //         }
    //     },
    //     async checkCartIsUnlocked({commit, getters}) {
    //         if(!getters.isSharedCart) {
    //             return Promise.resolve();
    //         }

    //         const response = await api.get('cart/isCartUnlocked');
    //         if(!response || response.locked_by === undefined) {
    //             return;
    //         }

    //         commit('setCartLockedBy', response.locked_by);

    //         if(!response.success) {
    //             sharedCart.onError(response.error_message);
    //             return Promise.reject();
    //         }

    //         return Promise.resolve();
    //     },
    //     async reselectCurrentCategory({}) {
    //         if(window.app_page === 'account') {
    //             location.reload();
    //         }
    //         if((window.app_page === 'product_list') && typeof reselectCurrentCategory === 'function') {
    //             await reselectCurrentCategory();
    //         }
    //     },
};
