import { render, staticRenderFns } from "./PreviewProduct.vue?vue&type=template&id=21181d36&scoped=true&"
import script from "./PreviewProduct.vue?vue&type=script&lang=js&"
export * from "./PreviewProduct.vue?vue&type=script&lang=js&"
import style0 from "./PreviewProduct.vue?vue&type=style&index=0&id=21181d36&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21181d36",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Trash: require('/usr/src/app/components/icons/Trash.vue').default,ProductBeforeDiscountPrice: require('/usr/src/app/components/products/ProductBeforeDiscountPrice.vue').default,ProductActions: require('/usr/src/app/components/products/ProductActions.vue').default})
