//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { isMobile, numberWithSpaces, orderBy } from "~/util/helpers";
import CartSmallTruck from "~/components/icons/CartSmallTruck.vue";
import Minus from "~/components/icons/Minus.vue";
import Plus from "~/components/icons/Plus.vue";
import Trash from "~/components/icons/Trash.vue";
import ProductBeforeDiscountPrice from "~/components/products/ProductBeforeDiscountPrice.vue";
import { sendYandexMetric } from "~/service/ecommerce";

export default {
    name: "RecommendedProductAction",
    components: { ProductBeforeDiscountPrice, Plus, Minus },
    props: {
        product: { type: Object },
        disabled: { type: Boolean },
        recommended: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            productIsLoading: false,
        };
    },
    computed: {
        ...mapState("metrika", ["paramsAB"]),
        ...mapState("cart", ["content"]),
        ...mapGetters("delivery", ["deliveryAddress", "deliverySelfDepartemnt"]),
        ...mapGetters("cart", ["productsInCart"]),
        ...mapGetters("products", ["getStopList"]),
        productInCart() {
            return this.content?.products?.find((prod) => {
                const cartProdModIds = orderBy(
                    (prod.sel_modifiers || []).map((m) => (typeof m.id === "string" ? parseInt(m.id) : m.id))
                ).join("_");
                const payloadModIds = orderBy(
                    (this.sel_modifiers || this.modifiers || []).map((m) =>
                        typeof m.id === "string" ? parseInt(m.id) : m.id
                    )
                ).join("_");

                return this.product.id == prod.id && payloadModIds == cartProdModIds;
            });
        },
        productCount() {
            if (this.productInCart?.quantity) {
                return this.productInCart.quantity;
            }

            return null;
        },
        price() {
            if (!this.isLoading && this.productInCart) {
                return this.availableProduct.price;
            }

            return this.fullModifiersInfo?.price || this.availableProduct.price;
        },
        availableProduct() {
            if (this.productInCart) {
                return this.productInCart;
            }
            return this.product;
        },
        addressSelected() {
            return (
                (this.deliveryAddress && this.deliveryAddress.street !== undefined) || this.deliverySelfDepartemnt
            );
        },
        isLoading() {
            return this.productIsLoading;
        },
        hideButtonInCatalog() {
            return this.disabled && this.inCatalog;
        },
        priceBeforeDiscount() {
            if (this.modifiers && this.modifiers[0]) {
                if (this.modifiers[0]?.price_before_discount) {
                    const priceIngredients = this.price - this.modifiers[0].price;
                    return this.modifiers[0].price_before_discount + priceIngredients;
                }
                return null;
            } else {
                return this.product.price_before_discount;
            }
        },
    },
    methods: {
        ...mapActions("modals", ["openModal"]),
        ...mapMutations("products", ["setRecommendWaitToAdd"]),
        async addProduct() {
            if (this.addressSelected) {
                const inStop = this.getStopList.filter((p) => p.id === this.availableProduct.id);

                if (inStop && inStop.stops && !inStop.stops[0].date_to) {
                    this.openModal({
                        modalName: "InfoModal",
                        modalData: {
                            text: "Данное блюдо недоступно к заказу",
                            confirmation: true,
                            no: "Понятно",
                            regretHandler: async () => {},
                        },
                    });
                    return;
                }

                if (this.availableProduct.available_modifiers && this.availableProduct.available_modifiers.length) {
                    this.$emit("withModifiers");
                    return;
                }

                this.productIsLoading = true;

                sendYandexMetric("click-on-recommendation-card");
                await this.$store.dispatch("products/addProduct", {
                    product: this.product,
                    isRecommendation: true,
                    recommended_by_product: this.recommended,
                });

                this.productIsLoading = false;
            } else {
                this.setRecommendWaitToAdd({
                    product: this.product,
                    isRecommendation: true,
                    recommended_by_product: this.recommended,
                });
                this.openModal({
                    modalName: "DeliveryMap",
                    modalData: { chosenCity: this.selectedCity },
                });
            }
        },
        async removeProduct() {
            this.productIsLoading = true;
            await this.$store.dispatch("products/removeProduct", this.availableProduct);
            this.productIsLoading = false;
        },
        formatValue(value) {
            return numberWithSpaces(value);
        },
    },
};
