import Vue from "vue";
import { Plugin } from "@nuxt/types";
import { Context, Inject } from "@nuxt/types/app";
import { debounce } from "~/util/helpers";

const exportPlugin: Plugin = (context: Context, inject: Inject) => {
    const screen = Vue.observable({ width: 1920 });

    if (process.client) {
        screen.width = window.innerWidth;
        const setScreenWidth = debounce(() => {
            screen.width = window.innerWidth;
        }, 300);
        window.addEventListener("resize", setScreenWidth);
    }

    inject("screen", screen);
};

export default exportPlugin;
