import { render, staticRenderFns } from "./RecommendedProducts.vue?vue&type=template&id=69a6405d&scoped=true&"
import script from "./RecommendedProducts.vue?vue&type=script&lang=js&"
export * from "./RecommendedProducts.vue?vue&type=script&lang=js&"
import style0 from "./RecommendedProducts.vue?vue&type=style&index=0&id=69a6405d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69a6405d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Shimmer: require('/usr/src/app/components/common/Shimmer.vue').default,RecommendedProduct: require('/usr/src/app/components/products/RecommendedProduct.vue').default})
