//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import { plural } from "~/util/helpers";

export default {
    name: "CustomOunter",
    props: ["selectedCount", "inCart", "group", "modifiers"],
    data() {
        return {};
    },
    computed: {
        ...mapGetters("products", ["getModifiersErrors"]),
        isFull() {
            return this.selectedCount === this.maxCount;
        },
        isAllowStacking() {
            return this.group.allow_stacking_identical_dishes;
        },
        maxCount() {
            let maxCount = 0;
            if (this.isAllowStacking) {
                const countMaxChose = this.modifiers.reduce((sum, current) => {
                    const max = current.max_number_to_choose | this.group.max_modifiers_count;
                    return sum + max;
                }, 0);
                maxCount =
                    countMaxChose > this.group.max_modifiers_count ? this.group.max_modifiers_count : countMaxChose;
            } else {
                maxCount = this.modifiers.length;
            }
            return maxCount;
        },
        rezMinSelected() {
            return this.group.min_modifiers_count - this.selectedCount;
        },
        isNoMinSelected() {
            return this.selectedCount < this.group.min_modifiers_count;
        },
        isShowError() {
            if (this.group.assemble_yourself_combo) {
                const isError = this.getModifiersErrors.includes(this.group.id);
                return this.isNoMinSelected && isError;
            }

            return false;
        },
        errorText() {
            return `Еще ${this.rezMinSelected} ${plural(this.rezMinSelected, "блюдо", "блюда", "блюд")}`;
        },
        selected() {
            return this.selectedCount || 0;
        },
        isShow() {
            if (this.inCart) {
                return this.group.assemble_yourself_combo;
            }

            return true;
        },
    },
    methods: {},
};
