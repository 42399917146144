import { Config } from "./types";

function applyEnvVariables(baseConfig: Config) {
    for (const key in baseConfig) {
        const envValue = process.env[key];
        if (envValue === undefined) {
            continue;
        }
        baseConfig[key] = envValue;
    }
}

export function applyOverridenConfigs(baseConfig) {
    applyEnvVariables(baseConfig);
}
