import { render, staticRenderFns } from "./ModifiersPreview.vue?vue&type=template&id=8b5cad90&scoped=true&"
import script from "./ModifiersPreview.vue?vue&type=script&lang=js&"
export * from "./ModifiersPreview.vue?vue&type=script&lang=js&"
import style0 from "./ModifiersPreview.vue?vue&type=style&index=0&id=8b5cad90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b5cad90",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PreviewModifier: require('/usr/src/app/components/products/PreviewModifier.vue').default})
