//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";

export default {
    name: "ProductIngredients",
    props: ["product", "disableChange", "inCart"],
    data() {
        return {
            comments: [],
        };
    },
    computed: {
        ...mapGetters("cart", ["productsInCart"]),
        cartProduct() {
            return this.productsInCart.find((p) => p.id == this.product.id);
        },
        cartKitchenComments() {
            return this.cartProduct &&
                this.cartProduct.kitchen_comment &&
                Array.isArray(this.cartProduct.kitchen_comment)
                ? this.cartProduct.kitchen_comment
                : [];
        },
        isShowComments() {
            if (this.inCart) {
                return this.cartKitchenComments.length > 0;
            }
            return true;
        },
    },
    mounted() {
        if (this.inCart) {
            this.fillCommentsFromCart();
        }
    },
    methods: {
        isShowComment(comment) {
            if (this.inCart) {
                return this.commentEnable(comment.id);
            }
            return true;
        },
        reset() {
            if (this.disableChange) {
                return;
            }
            this.comments = [];
            this.$emit("getComments", this.comments);
        },
        setComment(comment) {
            if (this.disableChange) {
                return;
            }
            const existingComment = this.comments.indexOf(comment.id);
            existingComment !== -1 ? this.comments.splice(existingComment, 1) : this.comments.push(comment.id);
            this.$emit("getComments", this.comments);
        },
        commentEnable(id) {
            return this.comments.includes(id);
        },
        fillCommentsFromCart() {
            this.comments = this.cartKitchenComments.map((kc) => kc.id);
        },
    },
};
