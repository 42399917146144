//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapGetters, mapActions, mapState } from "vuex";
import Product from "~/models/Product";
import RecommendedProduct from "~/components/products/RecommendedProduct.vue";
import Shimmer from "~/components/common/Shimmer.vue";

export default {
    name: "RecommendedProducts",
    components: {
        Shimmer,
        RecommendedProduct,
        Swiper,
        SwiperSlide,
    },
    props: ["recommends", "recommendedByProductId", "isSlider", "loading"],
    data() {
        return {
            swiperOptions: {
                slidesPerView: "auto",
                navigation: {
                    nextEl: ".button-next",
                    prevEl: ".button-prev",
                },
                pagination: {
                    el: ".pagination__list",
                    clickable: true,
                },
                imgLoaded: false,
            },
        };
    },
    computed: {
        ...mapState("cart", ["content"]),
        ...mapGetters("products", ["recommendations"]),
        ...mapGetters("delivery", ["emptyDelivery", "deliveryAddress", "deliverySelfDepartemnt"]),
        isMobile() {
            return this.$screen.width <= 960;
        },
        addressSelected() {
            return (
                (this.deliveryAddress && this.deliveryAddress.street !== undefined) || this.deliverySelfDepartemnt
            );
        },
    },
    mounted() {
        this.checkSwiper();
    },
    methods: {
        ...mapActions("design-system/modals", ["openModal"]),
        getRecommends(products) {
            if (products.length > 6) {
                return products.slice(0, 6);
            } else {
                return products;
            }
        },
        getPhoto(product) {
            return new Product(product).photoSmall;
        },
        async removeProduct(product) {
            await this.$store.dispatch("products/removeProduct", product);
        },
        checkSwiper() {
            const swipers = this.$refs.recommendsSwiper;
            if (!swipers || this.isMobile) {
                return;
            }
            swipers.destroySwiper();
        },
    },
};
