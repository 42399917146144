import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from "../store_types/index.types";
import { Modal, ModalState } from "../store_types/modals.types";

// Чтобы сделать модальное окно, создай компонент в папке modals и вызови метод openModal({modalName, modalDate})
// модалки сразу создаются с белым фоном и крестиком справа вверху
// чтобы этого не было, добавь имя своего компонента в массив ниже

// Массив модалок, которым НЕ нужен дефолтный контейнер с фоном и кнопкой закрытия
const customLayout: string[] = [
    "HeaderLoginForm",
    "AnotherAddressPopup",
    "CancelOrder",
    "TrackCourier",
    "VideocameraOnline",
    "BetOffer",
    "DeliveryMap",
    "CitySelect",
    "ForgottenProducts",
];

export const state: () => ModalState = () => ({
    arrOpenedModals: [],
    searchOpen: false,
});

export const getters: GetterTree<ModalState, RootState> = {
    // Для создания кастомного контейнера модалки
    defaultLayout(state: ModalState, getters: any): boolean {
        return customLayout.every((modalName) => modalName != getters.getOpenedModal?.modalName);
    },

    getOpenedModal(state: ModalState): Modal {
        return state.arrOpenedModals?.[state.arrOpenedModals?.length - 1];
    },

    getPreviewModal(state: ModalState): Modal | null {
        if (state.arrOpenedModals.length > 1) {
            return state.arrOpenedModals[state.arrOpenedModals.length - 2];
        }
        return null;
    },

    anyOpenModals(state: ModalState): number {
        return state.arrOpenedModals?.length;
    },

    getSearchOpen(state) {
        return state.searchOpen;
    },
};

export const mutations: MutationTree<ModalState> = {
    addModal(state: ModalState, modal: Modal): void {
        state.arrOpenedModals.push(modal);
    },

    removeModal(state: ModalState, modalName: string): void {
        state.arrOpenedModals = state.arrOpenedModals.filter((item: Modal) => item.modalName !== modalName);
    },

    removeLastModal(state: ModalState): void {
        state.arrOpenedModals.pop();
    },

    clearModals(state): void {
        state.arrOpenedModals = [];
    },

    setSearchOpen(state, payload) {
        state.searchOpen = payload;
    },
};

export const actions: ActionTree<ModalState, RootState> = {
    openModal({ state, getters, commit }, properties: Modal): void {
        const currentModalInArr = state.arrOpenedModals.find(
            (modal) => modal.modalName === getters.getOpenedModal?.modalName
        );

        if (!currentModalInArr) {
            commit("addModal", properties);
        } else {
            commit("removeModal", properties.modalName);
            commit("addModal", properties);
        }
    },

    closeModal({ state, commit }, modalName: string | undefined): void {
        if (modalName) {
            commit("removeModal", modalName);
        } else {
            commit("removeLastModal");
        }
    },

    clearModals({ state, commit }): void {
        commit("clearModals");
    },
};
