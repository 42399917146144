//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { isIndexPage } from "~/service/catalog-router/helpers";
import TopLine from "~/components/sections/TopLine";
import MenuPopup from "~/components/sections/MenuPopup";

export default {
    name: "HeaderSection",
    components: {
        TopLine,
        MenuPopup,
    },
    data() {
        return { menu: false };
    },
    computed: {
        ...mapState("products", ["noticedProduct"]),
        ...mapState("account", ["showMobileAppBanner"]),
        openedBanner() {
            return this.showMobileAppBanner;
        },
        routeIsCatalog() {
            return isIndexPage(this.$router.currentRoute);
        },
    },
    watch: {
        $route() {
            if (this.menu) {
                this.toggleMenu();
            }
        },
    },
    mounted() {
        // console.log('updated 2')
    },
    beforeDestroy() {
        document.body.classList.remove("modal-open");
        clearAllBodyScrollLocks();
    },
    methods: {
        toggleMenu() {
            this.menu = !this.menu;
            if (window.matchMedia("(max-width: 900px)").matches && this.menu) {
                document.body.classList.add("modal-open");
                if (this.$device.isIos) {
                    this.innerWrap = document.querySelector(".js-menu__inner");
                    disableBodyScroll(this.innerWrap);
                }
            } else {
                if (this.$device.isIos && !this.menu) {
                    enableBodyScroll(this.innerWrap);
                }
                document.body.classList.remove("modal-open");
            }
        },
    },
};
