import { cloneDeep } from "../../../util/clone";
import { ServerStorage } from "../../../modules/server-storage/storage";
import { UpdateFunction } from "../../../modules/storages-updater/storage-updater";
import { PossibleContextData } from "./types";

export class SharedContextData {
    private data: PossibleContextData = {};

    private serverStorage: ServerStorage | undefined;

    private updateFunction: UpdateFunction | undefined;

    getData() {
        return cloneDeep(this.data);
    }

    setData(value: PossibleContextData) {
        this.data = value;
        return this;
    }

    setServerStorage(value: ServerStorage) {
        this.serverStorage = value;
        return this;
    }

    getServerStorage() {
        return this.serverStorage;
    }

    setUpdateFunction(value: UpdateFunction) {
        this.updateFunction = value;
        return this;
    }

    getUpdateFunction() {
        return this.updateFunction;
    }
}
