import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from "../store_types/index.types";
import { ModalState } from "../store_types/modals.types_old";

// Чтобы сделать модальное окно, создай компонент в папке modals и вызови метод openModal({modalName, modalDate})
// модалки сразу создаются с белым фоном и крестиком справа вверху
// чтобы этого не было, добавь имя своего компонента в массив ниже

// Массив модалок, которым НЕ нужен дефолтный контейнер с фоном и кнопкой закрытия
const noDefaultLayout = [
    "HeaderLoginForm",
    "AnotherAddressPopup",
    "CancelOrder",
    "TrackCourier",
    "VideocameraOnline",
    "BetOffer",
    "DeliveryMap",
];

export const state: () => ModalState = () => ({
    currentModal: "", // Имя открытой модалки
    modalData: null, // Данные внутри модалки
    cameFrom: null, // Данные из модалки, которая находится "под" текущей
    properties: null, // Данные, передающиеся в properties
});

export const getters: GetterTree<ModalState, RootState> = {
    defaultLayout(state): boolean {
        // Для создания кастомного контейнера модалки
        return noDefaultLayout.every((modalName) => modalName != state.currentModal);
    },
};

export const mutations: MutationTree<ModalState> = {
    openModal(state, modal: string = "") {
        state.currentModal = modal;
    },
    openModalProps(state, props = null) {
        state.modalData = props;
    },
    setCameFrom(state, lastPopup) {
        const currentBuffer = state.cameFrom;
        state.cameFrom = {
            currentModal: lastPopup.currentModal,
            modalData: lastPopup.modalData,
            cameFrom: currentBuffer,
            properties: lastPopup.properties,
        };
    },
    goBack(state) {
        const lastPopup = Object.assign({}, state.cameFrom);
        state.currentModal = lastPopup.currentModal;
        state.modalData = lastPopup.modalData;
        state.cameFrom = lastPopup.cameFrom;
        state.properties = lastPopup.properties;
    },
    setComponentProps(state, props = null) {
        state.properties = props;
    },
};
