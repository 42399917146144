import { render, staticRenderFns } from "./MenuCategory.vue?vue&type=template&id=c4d0bc4a&scoped=true&"
import script from "./MenuCategory.vue?vue&type=script&lang=js&"
export * from "./MenuCategory.vue?vue&type=script&lang=js&"
import style0 from "./MenuCategory.vue?vue&type=style&index=0&id=c4d0bc4a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4d0bc4a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchIcon: require('/usr/src/app/components/icons/SearchIcon.vue').default,Sort: require('/usr/src/app/components/order/tabs/Sort.vue').default,NuxtCityLink: require('/usr/src/app/components/common/NuxtCityLink.vue').default,Search: require('/usr/src/app/components/search.vue').default,Container: require('/usr/src/app/components/common/Container.vue').default})
