//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import Product from "~/models/Product";
import Arrow from "~/components/icons/Arrow.vue";

export default {
    name: "ProductPopupImg",
    components: {
        Arrow,
        Swiper,
        SwiperSlide,
    },
    props: ["product", "productCount"],
    data() {
        return {
            swiperOptions: {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: this.photoList > 1,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".swiper-button_next",
                    prevEl: ".swiper-button_prev",
                },
                keyboard: true,
            },
            imgLoad: false,
        };
    },
    computed: {
        photo() {
            return new Product(this.product).photoBig;
        },
        photoList() {
            return new Product(this.product).photos;
        },
    },
};
