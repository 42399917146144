//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "Gear",
    props: {
        width: {
            type: [Number, String],
            default: 20.124,
        },
        height: {
            type: [Number, String],
            default: 20.124,
        },
        type: { type: String },
    },
};
