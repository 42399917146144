import { Plugin } from "@nuxt/types";
import { Context, Inject } from "@nuxt/types/app";
import { RawLocation } from "vue-router";

const exportPlugin: Plugin = (ctx: Context, inject: Inject) => {
    const router = ctx.app.router;
    if (!router) {
        return;
    }

    router.pushAsync = function (location: RawLocation) {
        return new Promise((resolve, reject) => {
            this.push(location, resolve, reject);
        });
    };
};

export default exportPlugin;
