import VueRouter from "vue-router";

export async function removeQueryParams(router: VueRouter, params: string | string[]): Promise<void> {
    const arParams: string[] = Array.isArray(params) ? params : [params];

    const path = router.currentRoute.path;
    const query = { ...router.currentRoute.query };

    for (const param of arParams) {
        if (query[param] === undefined) {
            continue;
        }
        delete query[param];
    }

    if (Object.keys(query).length === Object.keys(router.currentRoute.query).length) {
        return;
    }

    await router.replace({ path, query });
}

export function generateQueryString(data: any): string {
    return new URLSearchParams(data).toString();
}
