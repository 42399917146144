import { render, staticRenderFns } from "./FooterSection.vue?vue&type=template&id=41e4f54b&scoped=true&"
import script from "./FooterSection.vue?vue&type=script&lang=js&"
export * from "./FooterSection.vue?vue&type=script&lang=js&"
import style0 from "./FooterSection.vue?vue&type=style&index=0&id=41e4f54b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41e4f54b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/usr/src/app/components/icons/Logo.vue').default,PaymentMir: require('/usr/src/app/components/icons/PaymentMir.vue').default,PaymentPay: require('/usr/src/app/components/icons/PaymentPay.vue').default,PaymentSpb: require('/usr/src/app/components/icons/PaymentSpb.vue').default,AppQr: require('/usr/src/app/components/icons/AppQr.vue').default,Container: require('/usr/src/app/components/common/Container.vue').default})
