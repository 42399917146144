import { render, staticRenderFns } from "./ProductPopupImg.vue?vue&type=template&id=cfa58a1e&scoped=true&"
import script from "./ProductPopupImg.vue?vue&type=script&lang=js&"
export * from "./ProductPopupImg.vue?vue&type=script&lang=js&"
import style0 from "./ProductPopupImg.vue?vue&type=style&index=0&id=cfa58a1e&scoped=true&lang=scss&"
import style1 from "./ProductPopupImg.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfa58a1e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Arrow: require('/usr/src/app/components/icons/Arrow.vue').default,ProductScore: require('/usr/src/app/components/products/ProductScore.vue').default})
