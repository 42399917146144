//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from "vuex";
import ClickOutside from "vue-click-outside";
import moment from "moment";
import launchCityHelper from "~/service/launch-city-helper/index";
import cityLinksHelper from "~/util/city-links-helper";
import AddressMapIcon from "~/components/icons/AddressMapIcon.vue";
import Arrow from "~/components/icons/Arrow.vue";
import MenuMapIcon from "~/components/icons/MenuMapIcon.vue";
import { DELIVERY_TYPE } from "~/util/types";
import CircleLoader from "~/components/CircleLoader.vue";
import SmallInfoModal from "~/components/modal/smallInfoModal.vue";

export default {
    name: "ConnectInfo",
    components: { SmallInfoModal, CircleLoader, MenuMapIcon, Arrow, AddressMapIcon },
    directives: { ClickOutside },
    props: ["menuPopup", "newCity", "cityOpen", "addressSelectorShown", "openMenu", "isLoadingGeo"],
    data() {
        return {
            isButtonActive: false,
            selectCityModal: false,
            haveDeliveryAddress: true,
            address: "",
            addressLoading: false,
            triggerAddress: null,
            isShowActualPopup: false,
        };
    },
    computed: {
        ...mapState("delivery", {
            cities: "cities",
            selectedCity: "city",
            isPopupAddressSelectOpen: "isPopupAddressSelectOpen",
        }),
        ...mapGetters("cart", ["deliveryType"]),
        ...mapState("account", ["showMobileAppBanner"]),
        ...mapGetters("delivery", [
            "isDelivery",
            "deliveryAddress",
            "cityWorkTimeHtml",
            "minutesToDelivery",
            "deliverySelfDepartemnt",
        ]),
        needIndent() {
            if (process.browser) {
                if (window.innerWidth <= 480) {
                    return this.showMobileAppBanner;
                }
            }
            return false;
        },
        launchCities() {
            return launchCityHelper.getLaunchCities();
        },
        selectedCityName() {
            return this.newCity || this.selectedCity.name;
        },
        addressFormat() {
            if (Object.keys(this.deliveryAddress).length === 0 || this.deliveryAddress.street === undefined) {
                return "";
            }

            const subCity =
                this.selectedCity && this.deliveryAddress.city !== this.selectedCity.name
                    ? this.deliveryAddress.city
                    : this.selectedCity.name;

            return {
                address: `${subCity ? subCity + ", " : ""}${this.deliveryAddress.street}`,
                house: `${this.deliveryAddress.house}`,
                fullAddress: `${subCity ? subCity + ", " : ""}${this.deliveryAddress.street}, ${
                    this.deliveryAddress.house
                }`,
            };
        },
        isMobile() {
            return this.$screen.width <= 1279;
        },
        isLoading() {
            return this.$wait.is("cart.delivery") || this.$wait.is("delivery") || this.isLoadingGeo;
        },
        hasAddressSelectorShown() {
            return this.addressSelectorShown;
        },
        selectedAddress() {
            return this.addressFormat.address
                ? this.addressFormat.address
                : this.deliverySelfDepartemnt?.address || this.deliverySelfDepartemnt?.departmentAddress;
        },
        showAddress() {
            if (!this.selectedAddress) {
                return false;
            }

            if (this.deliveryType === DELIVERY_TYPE.DELIVERY) {
                return !!this.addressFormat.address;
            } else if (this.deliveryType !== DELIVERY_TYPE.DELIVERY) {
                return this.deliverySelfDepartemnt;
            }
            return false;
        },
        replaceBr() {
            return this.cityWorkTimeHtml.replace(/<br>/gi, " | ");
        },

        timeToDelivery() {
            if (this.deliveryType === DELIVERY_TYPE.DELIVERY) {
                return `≈ ${this.minutesToDelivery} мин.`;
            } else {
                return "≈ 15 мин.";
            }
        },
        windowWidth() {
            return this.$screen.width;
        },
        textUnderCity() {
            const city = this.windowWidth >= 1024 ? `${this.selectedCity.name},` : "";

            if (this.deliveryType === DELIVERY_TYPE.DELIVERY) {
                return this.addressFormat.address ? this.addressFormat : null;
            }
            if (this.deliverySelfDepartemnt) {
                if (this.deliverySelfDepartemnt.departmentAddress) {
                    const address = this.deliverySelfDepartemnt.departmentAddress.split(", ");
                    return {
                        address: `${city} ${address[0]}`,
                        house: address[1],
                    };
                }

                const address = this.deliverySelfDepartemnt.address.split(", ");
                return {
                    address: `${city} ${address[0]}`,
                    house: address[1],
                };
            }
            return this.replaceBr;
        },
        sortedCities() {
            return [...this.cities].sort((a, b) => a.name.localeCompare(b.name));
        },

        cityWorkTime() {
            let nowDay = moment().day();
            nowDay = nowDay === 0 ? 7 : nowDay;
            if (!this.selectedCity.workTime) {
                return;
            }
            const nowWorkTime = this.selectedCity.workTime.find((day) => day.day === nowDay);

            if (nowWorkTime?.fullDay) {
                return "Круглосуточно";
            } else if (nowWorkTime?.start && nowWorkTime?.end) {
                return `c ${nowWorkTime?.start} до ${nowWorkTime?.end}`;
            }
            return null;
        },
    },
    watch: {
        openMenu(nv) {
            if (nv === true) {
                this.$store.commit("delivery/setPopupAddressSelectOpen", false);
            }
        },
        isPopupAddressSelectOpen(nv) {
            if (nv) {
                if (!this.showAddress && !this.menuPopup && !this.newCity && !this.isLoading) {
                    this.triggerAddress = this.$refs.address;
                    this.isShowActualPopup = true;
                }
            } else {
                this.isShowActualPopup = false;
            }
        },
    },
    mounted() {
        this.watchHideInfo();
    },
    methods: {
        ...mapActions("modals", ["openModal", "resetDeliveryAddress"]),
        getTypeOfDelivery(type) {
            const types = {
                delivery: "Доставка",
                hall: "Зал",
                self: "Самовывоз",
            };
            return types[type];
        },
        showModal() {
            this.openModal({ modalName: "BackcallModal" });
        },
        selectCityModalToggle() {
            this.selectCityModal = !this.selectCityModal;
        },
        selectCityModalHide() {
            this.selectCityModal = false;
        },
        async chooseCity(city) {
            // this.$store.dispatch('delivery/resetDeliveryAddress');
            if (this.newCity) {
                this.$store.dispatch("delivery/reloadByCityId", {
                    cityId: city.id,
                    url: "/",
                });
                return;
            }

            try {
                await this.$store.dispatch("delivery/reloadByCityId", { cityId: city.id });
            } catch (err) {
                //
            }
        },
        showMap() {
            this.openModal({
                modalName: "DeliveryMap",
                modalData: { chosenCity: this.selectedCity },
            });
        },
        isNewCity(city) {
            return this.launchCities.find((item) => item.title === city.name);
        },
        watchHideInfo() {
            const classList = [
                "product-action__add-block",
                "product-action",
                "product-action__add",
                "product-action__add-icon",
            ];
            window.addEventListener("click", (ev) => {
                ev.stopPropagation();
                const isButton = classList.find((c) => ev.target.classList.contains(c));

                if (!document.querySelector(".city-address__popover") || isButton) {
                    return;
                }

                this.$store.commit("delivery/setPopupAddressSelectOpen", false);
            });
        },
        cityLink(id) {
            return cityLinksHelper.getNuxtLinkToPath(
                cityLinksHelper.cleanCityPath(this.$router.currentRoute.path),
                id
            );
        },
    },
};
