import { Route } from "vue-router";

export const BLACKLIST_ROUTE_PARTS = ["/user/", "/supply_request/", "/order/"];

export const META_PAGE_TITLE_FIELD = "_#_pagetitle";

export function splitMetaMapByGroups(metaMap: any) {
    const meta: any = {};
    const links: any = {};

    for (const key of Object.keys(metaMap)) {
        const value = metaMap[key];

        if (key == "canonical") {
            links[key] = value;
        } else {
            meta[key] = value;
        }
    }

    if (links.canonical) {
        links.canonical = links.canonical.replace(/osa\.eda1\.ru/g, "www.eda1.ru");
    }

    return { meta, links };
}

export function convertMetaDataItemsToMap(items) {
    const output = {};

    for (const item of items) {
        output[item.name] = item.content;
    }

    return output;
}

type MetaPageTitleFunction = ({ route: Route }) => MetaPageTitle;
type MetaPageTitle = string | undefined;

export function getMetaPageTitleFromComponentOptions(route: Route, opts: any): MetaPageTitle {
    const val: MetaPageTitleFunction | MetaPageTitle =
        opts && typeof opts === "object" && opts[META_PAGE_TITLE_FIELD] ? opts[META_PAGE_TITLE_FIELD] : undefined;
    if (!val) {
        return undefined;
    }
    return typeof val === "function" ? val({ route }) : val;
}

export function getPageTitleFromRoute(route: Route | undefined): string | undefined {
    if (!route) {
        return undefined;
    }
    // @ts-ignore
    return getMetaPageTitleFromComponentOptions(route, route?.matched[0]?.components?.default.options);
}
