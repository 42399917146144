import axios, { AxiosInstance, AxiosStatic, CancelToken, CancelTokenSource, CancelTokenStatic } from "axios";
import { Context } from "@nuxt/types";
import config from "../../config";
import { combineWithKey } from "./helpers";

export class PostCartQueue {
    private readonly api: Api;

    private readonly context: Context;

    private queue: { [key: string]: any } = {};

    constructor(api: Api, context: Context) {
        this.api = api;
        this.context = context;
    }

    getAxios(): AxiosStatic {
        return this.context.$axios;
    }

    add(method: string, data: any, timeout: number = 1): Promise<any> {
        return new Promise((resolve, reject) => {
            const key: string = this.getKey(method, data);
            const CancelToken: CancelTokenStatic = this.getAxios().CancelToken;
            const source: CancelTokenSource = CancelToken.source();

            const timeoutId = setTimeout((): void => {
                this.postCart(method, data, source.token).then(resolve).catch(reject);
            }, timeout);

            this.remove(key);

            this.queue[key] = {
                timeout: timeoutId,
                cancel: source,
            };
        });
    }

    postCart(method: string, params: any, cancelToken: CancelToken): Promise<any> {
        const key: string = this.getKey(method, params);
        const currentTimeoutId: any = this.getTimeout(key);

        return new Promise((resolve, reject) => {
            this.api
                .getRequest()
                .post(method, params, { cancelToken })
                .then((response) => {
                    this.remove(key);

                    // Для сохранения минимального времени досатвки в state
                    if (response.data.cart?.minimum_delivery_time) {
                        this.context.store.commit("cart/setApiMinTime", response.data.cart.minimum_delivery_time, {
                            root: true,
                        });
                    }

                    if (this.isEmpty() && response.data?.cart) {
                        this.context.store.dispatch("cart/setCart", response.data.cart, { root: true });
                        this.context.store.dispatch("products/checkDeliveryChanged", null, { root: true });
                    }

                    // Preset payment type
                    this.context.store.dispatch("payment/initPayments", null, { root: true });

                    if (response.data.success) {
                        resolve(response.data);
                    } else {
                        reject(response.data);
                    }
                })
                .catch((error) => {
                    const axios: AxiosStatic = this.getAxios();
                    this.remove(key, currentTimeoutId);

                    if (axios && !axios.isCancel(error)) {
                        reject({ error_code: 0, error_message: "Неизвестная ошибка корзины" });
                    }
                })
                .finally(() => {
                    this.remove(key, currentTimeoutId);
                });
        });
    }

    remove(key: string, currentTimeoutId?: number): void | null {
        if (this.inQueue(key)) {
            const queueItem: any = this.queue[key];

            if (currentTimeoutId !== undefined && queueItem.timeout != currentTimeoutId) {
                return;
            }

            queueItem.cancel.cancel();
            clearTimeout(queueItem.timeout);
            delete this.queue[key];
        }
    }

    inQueue(key: string): boolean {
        return this.queue[key] !== undefined;
    }

    containsKey(key: string): boolean {
        let contains: boolean = false;

        Object.keys(this.queue).forEach((k) => {
            if (k.includes(key)) {
                contains = true;
            }
        });

        return contains;
    }

    isEmpty(): boolean {
        return Object.keys(this.queue).length == 0;
    }

    getKey(method: string, data: any): string {
        return method + "_" + (data && data.queueKey ? data.queueKey : "");
    }

    getTimeout(key: string): any {
        return key in this.queue[key] ? this.queue[key].timeout : null;
    }
}

export class Api {
    private uuid: string;

    private auth: string;

    private encodedIP: string | undefined;

    private request: AxiosInstance;

    constructor(uuid: string = "", auth: string = "", encodedIP: string | undefined = undefined) {
        this.uuid = uuid;
        this.auth = auth;
        this.encodedIP = encodedIP;

        this.request = this.createRequest();
    }

    getApiKey() {
        if (!this.encodedIP) {
            return "";
        }

        const serverKey = this.encodedIP;
        const secketKey = "Lhkji_asQ4";
        const uuid = this.getUid();

        const n1 = combineWithKey(serverKey, secketKey);
        const n2 = combineWithKey(uuid, secketKey);
        const res = (n1 * n2) % 16771234;
        return `${res}`;
    }

    getBaseApiUrl() {
        return config.API_URL;
    }

    setCredentials(uuid: string | null, auth: string | null) {
        this.uuid = uuid || "";
        this.auth = auth || "";
    }

    recreateRequests() {
        this.request = this.createRequest();
    }

    private createRequest() {
        const request = axios.create({
            baseURL: this.getBaseApiUrl(),
            timeout: 90000,
            headers: {
                uuid: this.uuid,
                sitenew: 1,
                "X-Api-Key": this.getApiKey(),
                // 'test_platform': 1,
            },
        });

        request.interceptors.request.use(
            (config) => {
                if (this.auth) {
                    config.headers.authorization = this.auth;
                }

                return config;
            },
            (error) => Promise.reject(error)
        );

        return request;
    }

    getRequest() {
        return this.request;
    }

    getUuidFromRequest() {
        return this.request.defaults.headers.uuid;
    }

    get(method: string, params: any = {}): Promise<any> {
        return new Promise((resolve, reject) => {
            this.request
                .get(method, { params })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 403) {
                            //   api.context.store.dispatch('auth/logout');
                        }
                        reject(error.response.data);
                    } else if (error.request) {
                        reject({ error_code: 0, error_message: "Unknown error" });
                    }
                });
        });
    }

    post(method: string, params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.request
                .post(method, params)
                .then((response) => {
                    if (response.data === undefined) {
                        reject({ error_code: 0, error_message: "Неизвестная ошибка1" });
                        return;
                    }

                    if (response.data.length === 0) {
                        resolve(undefined);
                        return;
                    }

                    if (params && typeof params === "object" && params.skipCheck) {
                        resolve(response.data);
                        return;
                    }

                    if (typeof response.data === "string") {
                        resolve(response.data);
                        return;
                    }

                    if (response.data.success) {
                        resolve(response.data);
                    } else {
                        reject(response.data);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 403) {
                            //   api.context.store.dispatch('auth/logout');
                        }
                        reject(error.response.data);
                    } else if (error.request) {
                        reject({ error_code: 0, error_message: "Неизвестная ошибка2" });
                    }
                });
        });
    }

    delete(method: string): Promise<any> {
        return this.request.delete(method);
    }

    getUid() {
        return this.uuid;
    }

    getAuth() {
        return this.auth;
    }
}
