import { render, staticRenderFns } from "./search.vue?vue&type=template&id=f0553da6&scoped=true&"
import script from "./search.vue?vue&type=script&lang=js&"
export * from "./search.vue?vue&type=script&lang=js&"
import style0 from "./search.vue?vue&type=style&index=0&id=f0553da6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0553da6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Arrow: require('/usr/src/app/components/icons/Arrow.vue').default,SearchIcon: require('/usr/src/app/components/icons/SearchIcon.vue').default,CrossSmall: require('/usr/src/app/components/icons/CrossSmall.vue').default,Container: require('/usr/src/app/components/common/Container.vue').default,BurgerClose: require('/usr/src/app/components/icons/BurgerClose.vue').default,SearchProduct: require('/usr/src/app/components/products/SearchProduct.vue').default,NuxtCityLink: require('/usr/src/app/components/common/NuxtCityLink.vue').default,BeforeSearch: require('/usr/src/app/components/icons/BeforeSearch.vue').default,Shimmer: require('/usr/src/app/components/common/Shimmer.vue').default,EmptySearch: require('/usr/src/app/components/icons/EmptySearch.vue').default,SmallInfoModal: require('/usr/src/app/components/modal/smallInfoModal.vue').default})
