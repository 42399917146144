//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from "vuex";
import FeedbackPhone from "~/components/icons/FeedbackPhone.vue";
import FeedbackWhatsApp from "~/components/icons/FeedbackWhatsApp.vue";
export default {
    name: "FeedbackInfo",
    components: { FeedbackPhone },
    computed: {
        ...mapGetters("delivery", ["city"]),
        allPhones() {
            return [...this.city.phonesMobile, ...this.city.phonesUrban];
        },
    },
    methods: {
        ...mapActions("modals", ["openModal"]),
        showModal() {
            this.openModal({ modalName: "BackcallModal" });
        },
        phoneValidate(phone) {
            return phone.replace(/\s/g, "");
        },
    },
};
