export const EXTENSION_JPG = "jpg";
export const EXTENSION_PNG = "png";
export const EXTENSION_WEBP = "webp";

type ImageResizeTargetExtensions = typeof EXTENSION_JPG | typeof EXTENSION_PNG | typeof EXTENSION_WEBP;

export interface ImageResizeParams {
    width?: number | null;
    quality?: number | null;
    targetExtension?: ImageResizeTargetExtensions;
}
