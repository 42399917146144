import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RateProductsState } from "../store_types/rateProducts.types";
import { RootState } from "../store_types/index.types";

export const state: () => RateProductsState = () => ({
    scores: [],
    popup_order: null,
    orders_poped_up: [],
});

// getters
export const getters: GetterTree<RateProductsState, RootState> = {
    scores: (state, getters, rootState, rootGetters) => {
        return state.scores;
    },
    getPopupOrder(state) {
        return state.popup_order;
    },
    getOrdersPopedUp(state) {
        return state.orders_poped_up;
    },
};

// actions
export const actions: ActionTree<RateProductsState, RootState> = {
    rate({ commit, dispatch, state, rootState }, data) {
        commit("setScore", data);
    },
    async submit({ commit, dispatch, state, rootState }, order_id) {
        const order_scores = state.scores.filter((score) => {
            return score.order_id == order_id;
        });

        try {
            const resp = await this.$api.post("/orders/score", {
                order_id,
                scores: order_scores,
            });
            if (resp.success) {
                commit("clearOrderScores", order_id);
                commit("setPopupOrder", resp.order);

                dispatch(
                    "modals/openModal",
                    {
                        modalName: "InfoModal",
                        modalData: {
                            text: "Оценка заказа принята",
                            confirmation: false,
                        },
                    },
                    { root: true }
                );
            }
            return resp;
        } catch (err) {
            dispatch(
                "modals/openModal",
                {
                    modalName: "InfoModal",
                    modalData: {
                        text: err && err.error ? err.error : "Неизвестная ошибка сохранения оценок",
                        confirmation: false,
                    },
                },
                { root: true }
            );
            return Promise.reject(err);
        }
    },
    async fetchPopupOrder({ commit, state, rootGetters }) {
        const unratedCurrentOrder = rootGetters["account/unratedOrder"];

        if (!unratedCurrentOrder) {
            commit("setPopupOrder", null);
            return;
        }

        if (state.popup_order && state.popup_order.id && state.popup_order.id === unratedCurrentOrder) {
            return;
        }

        const order = await this.$api.get("/orders/details", { id: unratedCurrentOrder });

        if (!order.can_score) {
            commit("setPopupOrder", null);
            return;
        }

        const details = await this.$api.get(`/orders/details/${order.id}`, { show_components: true });
        commit("setPopupOrder", Object.assign({}, order, details));
    },
    orderPopedUp({ commit }, order_id) {
        commit("addOrderPopedUp", order_id);
    },
    setPopupOrder({ commit }, order) {
        commit("setPopupOrder", order);
    },
};

// mutations
export const mutations: MutationTree<RateProductsState> = {
    setScore(state, data) {
        state.scores = state.scores.filter((score) => {
            return !(
                score.order_id == data.order_id &&
                score.product_id == data.product_id &&
                score.parent_id == data.parent_id
            );
        });

        state.scores.push(data);
    },
    clearOrderScores(state, order_id) {
        state.scores = state.scores.filter((score) => {
            return score.order_id != order_id;
        });
    },
    setPopupOrder(state, order) {
        state.popup_order = order;
    },
    addOrderPopedUp(state, order_id) {
        state.orders_poped_up.push(order_id);
    },
};
