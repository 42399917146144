import { Plugin } from "@nuxt/types";
import { Context } from "@nuxt/types/app";

// !! Имя файла должно быть таким, чтобы найти плагин в списке импорта

const exportPlugin: Plugin = (ctx: Context) => {
    if (!process.client) {
        return;
    }
    // setTimeout(() => ctx.store.dispatch('nuxtClientInit', ctx), 1);
    ctx.store.dispatch("nuxtClientInit", ctx);
};

export default exportPlugin;
