import axios, { AxiosError } from "axios";
import config from "../../config";
import { MenuCategory, MenuProduct } from "~/util/api.types";

interface SortServiceResponse {
    success: boolean;
    categories?: MenuCategory[];
    products?: MenuProduct[];
}

export class CatalogSorterByGuestPreferences {
    private readonly guestToken: string;

    private readonly categories: MenuCategory[];

    private readonly products: MenuProduct[];

    private isGuestExperimental: boolean;

    private serviceResponse: SortServiceResponse | undefined;

    constructor(guestToken: string, categories: MenuCategory[], products: MenuProduct[]) {
        this.guestToken = guestToken;
        this.categories = categories;
        this.products = products;

        this.isGuestExperimental = this.findIfGuestIsExperimental();
    }

    private findIfGuestIsExperimental() {
        const firstHexSymbol = this.guestToken.substr(0, 1);
        const decNumber = parseInt(firstHexSymbol, 16);

        // включаем тест на 25%
        return decNumber % 4 == 0;
        // Цифра нечётная - участвует в эксперименте
        // return decNumber % 2 == 1;
    }

    async sort() {
        this.serviceResponse = undefined;

        const url = `${config.PERSONALISATION_SERVICE_URL}api/products`;
        let resp: any = null;

        try {
            resp = await axios.post(url, {
                guest_token: this.guestToken,
                categories: this.categories,
                products: this.products,
            });
        } catch (e) {
            const err: AxiosError = e;
            console.log("** ex", err.response);
        }

        if (!resp || !resp.data) {
            return;
        }

        this.serviceResponse = resp.data;
    }

    isSuccess() {
        return this.serviceResponse?.success || false;
    }

    getXYMetrikaValue(): string | null {
        if (!this.isSuccess()) {
            return null;
        }
        return this.isGuestExperimental ? "Y" : "X";
    }

    getIsGuestExperimental() {
        return this.isGuestExperimental;
    }

    getResponseCategories(): MenuCategory[] {
        return this.serviceResponse?.categories || [];
    }

    getResponseProducts(): MenuProduct[] {
        return this.serviceResponse?.products || [];
    }
}
