//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const idCounter = 1;
export default {
    name: "PaymentPay",
    props: {
        width: {
            type: [Number, String],
            default: 48,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
        type: { type: String },
    },
    computed: {
        uniqueId() {
            const uniqueId = this._uid;
            return "clip-" + uniqueId;
        },
    },
};
