//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "LogoMobile",
    props: {
        width: {
            type: [Number, String],
            default: 27,
        },
        height: {
            type: [Number, String],
            default: 25,
        },
        type: { type: String },
        fill: { type: String, default: "#000" },
    },
};
