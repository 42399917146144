import { Context } from "@nuxt/types";

export function getIpFromNuxtContext(context: Context): string | null {
    const forwardedIp = context.req.headers["x-forwarded-for"];
    if (typeof forwardedIp === "string") {
        return forwardedIp;
    }

    const realIp = context.req.headers["x-real-ip"];
    if (typeof realIp === "string") {
        return realIp;
    }

    const socketObject = context.req.connection || context.req.socket;
    if (!socketObject) {
        return null;
    }
    return socketObject.remoteAddress || null;
}
