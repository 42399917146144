import io from "socket.io-client";
import Vue from "vue";
import config from "../../../config";

const IS_DEBUG = false;

export abstract class AbstractSocket<D> {
    private readonly ws;

    private identifier: string | undefined;

    private _state = Vue.observable({
        connected: false,
        ready: false,
    });

    private onConnectCallbacks: (() => void)[] = [];

    protected onMessageCallbacks: ((data) => void)[] = [];

    protected abstract getClientKey(): string;

    protected abstract handleMessage(data: D): void;

    constructor() {
        this.ws = io(config.RABBIT_PROXY_WS_URL, {
            path: "",
            autoConnect: true,
            reconnection: true,
            transports: ["websocket"],
        });

        this.addListeners();
    }

    addOnConnectCallback(cb: () => void) {
        this.onConnectCallbacks.push(cb);
    }

    addOnMessageCallback(cb: (data) => void) {
        this.onMessageCallbacks.push(cb);
    }

    connect() {
        if (this.ws) {
            this.ws.close();
        }
        this.ws.open();
    }

    destroy() {
        this.ws.disconnect();
    }

    private determineState() {
        this._state.connected = this.ws.connected;
        this._state.ready = this._state.connected && !!this.identifier;
    }

    protected getLogName() {
        return "Sockets";
    }

    private log(...pieces) {
        if (!IS_DEBUG) {
            return;
        }
        console.log(`[${this.getLogName()}]`, ...pieces);
    }

    setIdentifier(identifier: string) {
        this.identifier = identifier;

        this.determineState();
        this.sendIdentifier();
    }

    // Реактивно (может быть использовано в computed)
    get isConnected() {
        return this._state.connected;
    }

    // Реактивно
    get isReady() {
        return this._state.ready;
    }

    private sendIdentifier() {
        if (!this.identifier) {
            return;
        }

        this.log("Sending identifier");
        this.ws.emit("setIdentifier", {
            clientKey: this.getClientKey(),
            identifier: this.identifier,
        });
    }

    private addListeners() {
        this.ws.on("connect", () => {
            this.log("Connected");
            this.determineState();
            this.sendIdentifier();
        });

        this.ws.on("connect_error", (err) => {
            this.log("Connect ERROR", err);
            this.determineState();
        });

        this.ws.on("error", (err) => {
            this.log("ERROR", err);
            this.determineState();
        });

        this.ws.on("disconnect", () => {
            this.log("Disconnected");
            this.determineState();
        });

        this.ws.on("message", (data: D) => {
            this.log("Received:", data);
            this.handleMessage(data);
        });
    }
}
