import { cloneDeep } from "../../util/clone";
import { PathItem } from "./types";

export class PathsArray {
    private values: PathItem[] = [];

    constructor(values: PathItem[]) {
        this.values = values;
    }

    addBetter(val: PathItem) {
        this.values.unshift(val);
    }

    best(): PathItem | undefined {
        return this.values[0];
    }

    addWorse(val: PathItem) {
        this.values.push(val);
    }

    worst(): PathItem | undefined {
        return this.values[this.values.length - 1];
    }

    getArray() {
        return cloneDeep(this.values);
    }
}
