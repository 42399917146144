export interface GiftCertificateDesign {
    src: string;
    alt: string;
    id: string;
    desc: string;
}

export const GIFT_CERTIFICATE_DESIGNS: GiftCertificateDesign[] = [
    {
        src: "img/certs/style-card1.svg",
        alt: "card-style",
        id: "1",
        desc: "Ярко-красная для любителей уникального стиля.",
    },
    {
        src: "img/certs/style-card2.svg",
        alt: "card-style",
        id: "2",
        desc: "Нежно-белая для романтичных личностей.",
    },
    {
        src: "img/certs/style-card3.svg",
        alt: "card-style",
        id: "3",
        desc: "Строго-чёрная для&nbsp;смелых&nbsp;и&nbsp;уверенных.",
    },
];
