//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";

export default {
    name: "ModifiersPreview",
    props: ["selectedProduct", "isMobile", "selectedModifiers"],
    data() {
        return {
            rightCoord: 0,
            modifiers: [],
        };
    },
    computed: {
        ...mapGetters("cart", ["productsInCart", "totals"]),
        getModifiers() {
            return this.selectedProduct.available_modifiers;
        },
        getCoord() {
            return this.rightCoord + "px";
        },
        selectedProductInCart() {
            return this.productsInCart.filter((item) => this.selectedProduct.id == item.id);
        },
    },
    watch: {
        selectedModifiers(newVal) {
            this.filterModifiers();
        },
    },
    mounted() {
        this.setRightCoord();
        this.filterModifiers();
    },
    methods: {
        setRightCoord() {
            if (this.$el.getBoundingClientRect().left < 370) {
                this.rightCoord = 200;
            } else {
                this.rightCoord = 300;
            }
        },
        filterModifiers() {
            const table = {};
            this.modifiers = this.selectedModifiers.filter(({ id }) => !table[id] && (table[id] = 1));
        },
    },
};
