//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";

export default {
    name: "CartPreview",
    props: ["gotoCart"],
    data() {
        return { isScrolling: false };
    },
    computed: {
        ...mapGetters("cart", ["productsInCart", "totals"]),
        ...mapGetters("delivery", ["minDeliveryAmount"]),
        minDeliveryPercent() {
            return (this.totals.summ / (this.minDeliveryAmount / 100)).toFixed();
        },
        restSumm() {
            return this.minDeliveryAmount - this.totals.summ;
        },
    },
    mounted() {
        const el = this.$refs.productWrap;
        if (el) {
            this.isScrolling = el.scrollHeight > el.clientHeight;
        }
    },
};
