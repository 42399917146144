//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "Logo",
    props: {
        width: {
            type: [Number, String],
            default: 235,
        },
        height: {
            type: [Number, String],
            default: 68,
        },
        type: { type: String },
    },
    computed: {
        uniqueId() {
            const uniqueId = this._uid;
            return "clip-" + uniqueId;
        },
    },
};
