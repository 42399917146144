import Vue from "vue";
import { createPromiseEvent } from "~/util/promise-event";

const STORAGE_KEY = "__site_ls";

const _storage: any = Vue.observable({});
const _storageLoadEvent = process.client ? createPromiseEvent("_storageLoadEvent") : null;

function resetStorageVariable() {
    for (const key of Object.keys(_storage)) {
        delete _storage[key];
    }
}

function isLocalStorageAvailable(): boolean {
    if (!process.client) {
        return false;
    }
    if (!window) {
        return false;
    }
    return !!window.localStorage;
}

function loadLocalStorage() {
    if (!isLocalStorageAvailable()) {
        return;
    }

    try {
        const parsed = JSON.parse(window.localStorage[STORAGE_KEY]);

        for (const key in parsed) {
            Vue.set(_storage, key, parsed[key]);
        }
    } catch (_) {
        resetStorageVariable();
    }

    if (_storageLoadEvent) {
        _storageLoadEvent.result();
    }
}

async function saveLocalStorage() {
    if (!isLocalStorageAvailable()) {
        return;
    }
    if (!_storageLoadEvent) {
        return;
    }

    await _storageLoadEvent.event;
    window.localStorage[STORAGE_KEY] = JSON.stringify(_storage);
}

async function getValue(key: string): Promise<any | undefined> {
    if (!isLocalStorageAvailable()) {
        return undefined;
    }
    if (!_storageLoadEvent) {
        return;
    }

    await _storageLoadEvent.event;
    return _storage[key];
}

async function setValue(key: string, value: any) {
    if (!isLocalStorageAvailable()) {
        return;
    }
    if (!_storageLoadEvent) {
        return;
    }

    await _storageLoadEvent.event;
    Vue.set(_storage, key, value);

    await saveLocalStorage();
}

function getStorage() {
    return _storage;
}

loadLocalStorage();

export default {
    getValue,
    setValue,
};
