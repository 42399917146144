//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "ProductEnergyTable",
    props: ["energyInfo", "bgColor"],

    data() {
        return {};
    },
    computed: {
        backgroundColor() {
            if (this.bgColor) {
                return {
                    backgroundColor: this.bgColor,
                };
            }
            return {};
        },
        productInfo() {
            const info = this.energyInfo;
            const info100 = {
                weight: "На 100 грамм",
                calories: info.calories_100 || "",
                carbohydrates: info.carbohydrates_100 || "",
                fats: info.fats_100 || "",
                proteins: info.proteins_100 || "",
            };

            const weightInfo = {
                weight: "На блюдо",
                calories: info.calories || "",
                carbohydrates: info.carbohydrates || "",
                fats: info.fats || "",
                proteins: info.proteins || "",
            };

            return [info100, weightInfo];
        },
        hasNonEmptyField() {
            const [info100, weightInfo] = this.productInfo;

            const hasNonEmptyInfo100 = Object.keys(info100).some((key) => key !== "weight" && info100[key] !== "");
            const hasNonEmptyWeightInfo = Object.keys(weightInfo).some(
                (key) => key !== "weight" && weightInfo[key] !== ""
            );

            if (!hasNonEmptyInfo100 && !hasNonEmptyWeightInfo) {
                this.$emit("empty");
            }

            return hasNonEmptyInfo100 || hasNonEmptyWeightInfo;
        },
    },
};
