import { Banner, MenuProduct } from "../../util/api.types";
import { getBannerLayers } from "../storages/banners-storage/utils";
import { IMAGE_RESIZE_QUALITY_BEST } from "./constants";
import { ImageResizeHelper } from "./index";
import { EXTENSION_WEBP } from "~/service/image-resize-helper/types";

const SIZES_MAP = {
    photo_small: 640,
    photo_big: 900,
};

const BANNER_WIDTH = 0;

export function resizeBannerImages(banner: Banner): void {
    // Основная картинка
    banner.image_url = new ImageResizeHelper(banner.image_url).getResizedUrl({
        width: BANNER_WIDTH,
        quality: IMAGE_RESIZE_QUALITY_BEST,
        targetExtension: EXTENSION_WEBP,
    });

    // Слои
    const layers = getBannerLayers(banner);

    for (const layer of layers) {
        for (const key in layer.images) {
            layer.images[key] = new ImageResizeHelper(layer.images[key]).getResizedUrl({
                width: BANNER_WIDTH,
                quality: IMAGE_RESIZE_QUALITY_BEST,
                targetExtension: EXTENSION_WEBP,
            });
        }
    }
}

export function resizeProductImages(product: MenuProduct) {
    if (!product.photo) {
        return;
    }

    for (const photoKey in SIZES_MAP) {
        const width = SIZES_MAP[photoKey];
        const quality = photoKey == "photo_big" ? IMAGE_RESIZE_QUALITY_BEST : null;

        product[photoKey] = new ImageResizeHelper(product.photo).getResizedUrl({
            width,
            quality,
            targetExtension: EXTENSION_WEBP,
        });
    }
}
