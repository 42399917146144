//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import cityLinksHelper from "~/util/city-links-helper";
import Product from "~/models/Product";
import { numberWithSpaces, orderBy, throttle, webpToJpg } from "~/util/helpers";
import RecommendedProductAction from "~/components/products/RecommendedProductAction.vue";

export default {
    name: "RecommendedProduct",
    components: { RecommendedProductAction },
    props: ["product", "recommendedByProductId", "routeIsHome"],
    data() {
        return {
            loadedImage: false,
        };
    },
    computed: {
        ...mapState("metrika", ["paramsAB"]),
        ...mapState("products", ["catalogView"]),
        ...mapGetters("cart", ["isCartLoaded", "productsInCart", "closestDepartments"]),
        ...mapState("cart", ["content"]),
        ...mapState("productPopup", ["openIn"]),
        photo() {
            return new Product(this.product).photoSmall;
        },
        productLink() {
            return cityLinksHelper.getNuxtLinkToPath(
                this.$catalogRouter.getProductPath(this.product),
                cityLinksHelper.getCityIdFromVuex(this.$store)
            );
        },
        productCount() {
            const products = this.productsInCart.filter((product) => product.id === this.product.id);

            let count = null;

            products.forEach((product) => {
                count += product.quantity;
            });

            return count;
        },
        productIsDisabled() {
            if (!this.product.remainings || !this.closestDepartments?.length) {
                return;
            }

            return this.closestDepartments.every((department) => {
                return (
                    (this.product.remainings?.[department.id] !== null && department.active) || !department.active
                );
            });
        },
        jpgPhoto() {
            return webpToJpg(this.photo);
        },
    },
    methods: {
        ...mapActions("modals", ["openModal"]),
        ...mapMutations("productPopup", ["setOpenIn"]),
        onProductClick() {
            this.$emit("closePopup");
            setTimeout(() => {
                const newPath = this.getProductLink(this.product);
                if (!this.openIn) {
                    this.setOpenIn(this.$route.path);
                }
                this.$router.push({ path: newPath });
            }, 500);
        },
        formatValue(value) {
            return numberWithSpaces(value);
        },
        getProductLink(product) {
            return cityLinksHelper.getNuxtLinkToPath(
                this.$catalogRouter.getProductPath(product),
                cityLinksHelper.getCityIdFromVuex(this.$store)
            );
        },
    },
};
