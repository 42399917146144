import config from "../../config/index";
import { ImageResizeParams } from "./types";

export class ImageResizeHelper {
    private readonly url: string;

    constructor(url: string) {
        this.url = url;
    }

    private getImageRelativePath(): string | undefined {
        let pieces: string[] = [];

        if (this.url.includes("/upload/")) {
            pieces = this.url.split("/upload/");
        } else if (this.url.includes("/im/")) {
            pieces = this.url.split("/im/");
        }

        if (pieces.length < 2) {
            return undefined;
        }
        return pieces[pieces.length - 1];
    }

    private isResizeAllowed(extension: string): boolean {
        return ["jpg", "jpeg", "png", "webp"].includes(extension);
    }

    private applyResizeParams(imagePath: string, params: ImageResizeParams): string | undefined {
        const splitExt = imagePath.split(".");
        if (splitExt.length < 2) {
            return undefined;
        }

        const srcExt = splitExt.splice(-1, 1)[0];
        let tgtExt = srcExt;

        let resizeUrl = `${config.IMAGE_RESIZER_SERVICE_URL}${splitExt.join(".")}`;

        if (this.isResizeAllowed(srcExt)) {
            if (params.width) {
                resizeUrl += `_w${params.width}`;
            }
            if (params.quality) {
                resizeUrl += `_q${params.quality}`;
            }
            if (params.targetExtension && params.targetExtension != srcExt) {
                tgtExt = params.targetExtension;
                resizeUrl += `_${srcExt}`;
            }
        }

        resizeUrl += `.${tgtExt}`;
        return resizeUrl;
    }

    getResizedUrl(params: ImageResizeParams): string {
        if (!this.url) {
            return this.url;
        }

        const imagePath = this.getImageRelativePath();
        if (!imagePath) {
            return this.url;
        }

        const resizedUrl = this.applyResizeParams(imagePath, params);
        if (!resizedUrl) {
            return this.url;
        }

        return resizedUrl;
    }
}
