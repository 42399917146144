import { render, staticRenderFns } from "./SetConsist.vue?vue&type=template&id=9448cf22&scoped=true&"
import script from "./SetConsist.vue?vue&type=script&lang=js&"
export * from "./SetConsist.vue?vue&type=script&lang=js&"
import style0 from "./SetConsist.vue?vue&type=style&index=0&id=9448cf22&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9448cf22",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConstituentProduct: require('/usr/src/app/components/products/ConstituentProduct.vue').default})
