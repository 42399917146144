import { ActionTree, GetterTree, MutationTree } from "vuex";
import { AuthState } from "../store_types/auth.types";
import { RootState } from "../store_types/index.types";
import { sendYandexMetric } from "../service/ecommerce";
import { Api } from "~/service/api";
import { DEFAULT_COOKIES_OPTIONS } from "~/service/cookies-helper";

export const state: () => AuthState = () => ({ token: null });

export const getters: GetterTree<AuthState, RootState> = {
    token: (state) => {
        return state.token;
    },
    isLoggedIn: (state) => {
        return !!state.token;
    },
};

export const mutations: MutationTree<AuthState> = {
    setToken(state, token) {
        state.token = token;
    },
    logout(state) {
        state.token = null;
    },
};

export const actions: ActionTree<AuthState, RootState> = {
    async login({ commit, dispatch }, credentials) {
        try {
            const resp = await this.$api.post("/user/login", credentials);
            if (!resp.success) {
                return Promise.reject(resp);
            }

            await dispatch("setAuthToken", resp.token);

            return resp;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    logout({ commit, dispatch }) {
        this.$cookies.remove("auth");
        dispatch("cart/clearPaymentTokens", null, { root: true });

        commit("logout");
        commit("account/clearData", null, { root: true });

        this.$router.push({ name: "index" });
    },
    async recoverPassword({ commit }, data) {
        if (data.verify_type === "sms") {
            sendYandexMetric("sms-start");
        }
        try {
            const resp = await this.$api.post("/user/recoverPassword", {
                phone: data.phone,
                verify_type: data.verify_type,
            });
            if (!resp.success) {
                return Promise.reject(resp);
            }

            return resp;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async recoverPasswordConfirm({ commit, dispatch }, data) {
        try {
            const resp = await this.$api.post("/user/recoverPasswordConfirm", data);
            if (!resp.success) {
                return Promise.reject(resp);
            }
            if (data.verify_type === "sms") {
                if (!data.ext) {
                    sendYandexMetric("sms-end");
                }
            }

            dispatch("setAuthToken", resp.token);

            return resp;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async register({ commit }, data) {
        if (data.verify_type === "sms") {
            sendYandexMetric("sms-start");
        }
        try {
            const resp = await this.$api.post("/user/register", data);
            if (!resp.success) {
                return Promise.reject(resp);
            }
            return resp;
        } catch (err) {
            return Promise.reject(err);
        }
    },
    setAuthToken({ state, commit, rootState }, rawToken) {
        const token = rawToken || null;

        if (token) {
            this.$cookies.set("auth", token, DEFAULT_COOKIES_OPTIONS);
        } else {
            this.$cookies.remove("auth");
        }

        commit("setToken", token);

        const api: Api = this.$api;
        api.setCredentials(rootState.uuid, state.token);
        api.recreateRequests();
    },
    async registerConfirm({ dispatch, commit }, data) {
        try {
            const resp = await this.$api.post("/user/registerCheck", data);
            if (!resp.success) {
                return Promise.reject(resp);
            }
            if (data.verify_type === "sms") {
                if (!data.ext) {
                    sendYandexMetric("sms-end");
                }
            }
            dispatch("setAuthToken", resp.token);
            return resp;
        } catch (err) {
            return Promise.reject(err);
        }
    },
};
