export type RequestUtmTags = { [key: string]: string };

export function getRequestUtmTags(url: string | undefined): RequestUtmTags | null {
    const queryString = url;
    if (!queryString) {
        return null;
    }

    const queryParts = queryString.split("?", 2);
    if (queryParts.length < 2) {
        return null;
    }

    const parts = queryParts[1].split("&");
    if (parts.length === 0) {
        return null;
    }

    const result: RequestUtmTags = {};
    for (const part of parts) {
        if (part.substr(0, 4) != "utm_") {
            continue;
        }

        const pos = part.indexOf("=");
        if (pos < 0) {
            continue;
        }

        const key = part.substr(0, pos);
        const value = part.substr(pos + 1);

        result[key] = value;
    }

    return Object.keys(result).length > 0 ? result : null;
}
