// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/pt_root-ui_light/pt_root-ui_light.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/pt_root-ui_light/pt_root-ui_light.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/pt_root-ui_light/pt_root-ui_light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/pt_root-ui_bold/pt_root-ui_bold.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/pt_root-ui_bold/pt_root-ui_bold.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../fonts/pt_root-ui_bold/pt_root-ui_bold.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../fonts/pt_root-ui_medium/pt_root-ui_medium.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../fonts/pt_root-ui_medium/pt_root-ui_medium.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../fonts/pt_root-ui_medium/pt_root-ui_medium.ttf");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../fonts/pt_root-ui_regular/pt_root-ui_regular.woff2");
var ___CSS_LOADER_URL_IMPORT_10___ = require("../fonts/pt_root-ui_regular/pt_root-ui_regular.woff");
var ___CSS_LOADER_URL_IMPORT_11___ = require("../fonts/pt_root-ui_regular/pt_root-ui_regular.ttf");
var ___CSS_LOADER_URL_IMPORT_12___ = require("../fonts/rozovii_chulok/rozoviichulok_regular.woff2");
var ___CSS_LOADER_URL_IMPORT_13___ = require("../fonts/rozovii_chulok/rozoviichulok_regular.woff");
var ___CSS_LOADER_URL_IMPORT_14___ = require("../fonts/rozovii_chulok/rozoviichulok_regular.ttf");
var ___CSS_LOADER_URL_IMPORT_15___ = require("../fonts/tt_sushkof_medium/tt_sushkof_medium.woff2");
var ___CSS_LOADER_URL_IMPORT_16___ = require("../fonts/tt_sushkof_medium/tt_sushkof_medium.woff");
var ___CSS_LOADER_URL_IMPORT_17___ = require("../fonts/tt_sushkof_medium/tt_sushkof_medium.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___);
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_15___);
var ___CSS_LOADER_URL_REPLACEMENT_16___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_16___);
var ___CSS_LOADER_URL_REPLACEMENT_17___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_17___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n\tfont-family: \"PT-Root-Light\";\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n\tfont-display: auto;\n}\n\n@font-face {\n\tfont-family: \"PT-Root-Bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff\"),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");\n\tfont-display: swap;\n}\n\n@font-face {\n\tfont-family: \"PT-Root-Medium\";\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\"),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"truetype\");\n\tfont-display: swap;\n}\n\n@font-face {\n\tfont-family: \"PT-Root-Regular\";\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"woff2\"),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format(\"woff\"),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format(\"truetype\");\n\tfont-display: swap;\n}\n\n@font-face {\n    font-family: \"Rozovii-Chulok-Regular\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ") format(\"woff2\"),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_13___ + ") format(\"woff\"),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_14___ + ") format(\"truetype\");\n    font-display: swap;\n}\n\n@font-face {\n    font-family: \"TT-Sushkof-Medium\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_15___ + ") format(\"woff2\"),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_16___ + ") format(\"woff\"),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_17___ + ") format(\"truetype\");\n    font-display: swap;\n}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
