//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import Cart from "~/components/icons/Cart.vue";
import Arrow from "~/components/icons/Arrow.vue";

export default {
    name: "ProductInCart",
    components: { Arrow, Cart },
    props: ["selectedProduct"],
    data() {
        return { cartProduct: false };
    },
    computed: {
        ...mapGetters("cart", ["isCartLoaded", "totals", "productsInCart"]),
        ...mapGetters("productPopup", ["isModifiersButtonClicked"]),
    },
    mounted() {
        if (this.isModifiersButtonClicked) {
            this.cartProduct = true;
        }
    },
    methods: {
        toogleCartProduct() {
            this.cartProduct = !this.cartProduct;
        },
        filterProducts() {
            const productsArray = [];
            this.productsInCart.forEach((product) => {
                if (product.id === this.selectedProduct.id) {
                    productsArray.push(product);
                }
            });
            return productsArray;
        },
    },
};
