import { AdditionalProductsGroup, CartProduct, MenuProduct } from "../util/api.types";
import { intersect, isEmpty, orderBy } from "../util/helpers";
import { LSAdditionalProductExceedItem } from "../service/local-storage-helper/types";

export function getStepCount(product: CartProduct | MenuProduct) {
    return product["count_step"] || 1; /* eslint-disable-line dot-notation */
}

export function increaseAdditionalProductsLocalExceed(
    lsProducts: LSAdditionalProductExceedItem,
    diffCount: number,
    productId: number
) {
    if (isEmpty(lsProducts[productId])) {
        lsProducts[productId] = 0;
    }
    lsProducts[productId] += diffCount;
}

export function decreaseAdditionalProductsLocalExceed(
    lsProducts: LSAdditionalProductExceedItem,
    diffCount: number,
    productId: number,
    group: AdditionalProductsGroup
) {
    let minusValue = Math.abs(diffCount);
    const keys: string[] = intersect(
        group.products.map((p) => String(p.id)),
        Object.keys(lsProducts)
    );

    if (keys.length === 0) {
        return;
    }

    while (minusValue > 0) {
        let key: string | null = null;

        if (lsProducts[productId] !== undefined) {
            key = String(productId);
        } else {
            key = keys.splice(0, 1)[0];
        }

        if (!key) {
            return;
        }

        const v = Math.min(minusValue, lsProducts[key]);

        if (typeof lsProducts[key] !== "number") {
            lsProducts[key] = 0;
        }

        lsProducts[key] -= v;
        if (lsProducts[key] <= 0) {
            delete lsProducts[key];
        }

        minusValue -= v;
    }
}

export function findProductById(products: MenuProduct[], productId: number | string): MenuProduct | null {
    let foundByProduct: MenuProduct | null = null;
    let foundByModifier: MenuProduct | null = null;

    for (let prodIndex = 0; prodIndex < products.length; prodIndex++) {
        const product: MenuProduct = products[prodIndex];

        // Нашли основной продукт
        if (!foundByProduct && product.id == productId) {
            foundByProduct = product;
        }

        // Ищем в модификаторах продукта
        if (!product.available_modifiers || product.available_modifiers.length === 0) {
            continue;
        }

        for (let modIndex = 0; modIndex < product.available_modifiers.length; modIndex++) {
            const modifier: MenuProduct = product.available_modifiers[modIndex];

            // Нашли модификатор
            if (!foundByModifier && modifier.id == productId) {
                foundByModifier = modifier;
            }
        }

        // Если уже всё нашли, завершаем цикл
        if (foundByProduct && foundByModifier) {
            break;
        }
    }

    return foundByProduct || foundByModifier;
}

export function getProductCart(cartProducts: CartProduct[], product: MenuProduct | CartProduct) {
    return cartProducts.find((prod) => {
        const cartProdModIds = orderBy(
            (prod.sel_modifiers || []).map((m) => (typeof m.id === "string" ? parseInt(m.id) : m.id))
        ).join("_");
        const payloadModIds = orderBy(
            (product.modifiers || []).map((m) => (typeof m.id === "string" ? parseInt(m.id) : m.id))
        ).join("_");

        return product.id == prod.id && payloadModIds == cartProdModIds;
    });
}
