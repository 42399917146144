import { ActionTree, GetterTree, MutationTree } from "vuex";
import { GIFT_CERTIFICATE_DESIGNS } from "../util/gift_certificate_enums";
import { CertificateState } from "~/store_types/certificate.types";
import { RootState } from "~/store_types/index.types";

export const state: () => CertificateState = () => ({
    recipient: {
        name: "",
        phone: "",
        email: "",
        message: "",
        sender: "",
    },
    sender: {
        name: "",
        phone: "",
        email: "",
    },
    cost: 1000,
    paymentMethod: "",
    isSelf: true,
    isAnon: false,
    selectedCard: GIFT_CERTIFICATE_DESIGNS[0],
    step: null,
    isSpecificTime: false,
    currentTime: null,
    currentDate: null,

    merchants: {},
});

export const getters: GetterTree<CertificateState, RootState> = {
    recipient: (state) => state.recipient,
    sender: (state) => state.sender,
    cost: (state) => state.cost,
    paymentMethod: (state) => state.paymentMethod,
    isSelf: (state) => state.isSelf,
    selectedCard: (state) => state.selectedCard,
    step: (state) => state.step,
    isAnon: (state) => state.isAnon,
    cloudPaymentKey: (state) => {
        return state.merchants?.cloud_payment_key;
    },
    isOnlineCloudPayment: (state, getters) => {
        return !!getters.cloudPaymentKey;
    },
    showOnlinePayments: (state, getters, rootState, rootGetters) => {
        return (
            (getters.isOnlineCloudPayment && rootGetters["auth/isLoggedIn"] && rootGetters["account/cards"].length) ||
            (getters.isOnlineCloudPayment &&
                (rootState.payment.paymentCardId == 0 || rootState.payment.paymentCardId == null))
        );
    },
};

export const mutations: MutationTree<CertificateState> = {
    setRecipientName(state, name) {
        state.recipient.name = name;
    },
    setRecipientPhone(state, phone) {
        state.recipient.phone = phone;
    },
    setRecipientEmail(state, email) {
        state.recipient.email = email;
    },
    setRecipientMessage(state, message) {
        state.recipient.message = message;
    },
    setRecipientSender(state, sender) {
        state.recipient.sender = sender;
    },
    setSenderPhone(state, phone) {
        state.sender.phone = phone;
    },
    setSenderName(state, name) {
        state.sender.name = name;
    },
    setSenderEmail(state, email) {
        state.sender.email = email;
    },
    setCost(state, cost) {
        state.cost = cost;
    },
    setPaymentMethod(state, method) {
        state.paymentMethod = method;
    },
    setIsSelf(state, value) {
        state.isSelf = value;
    },
    setIsAnon(state, value) {
        state.isAnon = value;
    },
    setSelectedCard(state, selCard) {
        state.selectedCard = selCard;
    },
    setStep(state, step) {
        state.step = step;
    },
    setSpecificTime(state, value) {
        state.isSpecificTime = value;
    },
    setCurrentTime(state, chosenTime) {
        state.currentTime = chosenTime;
    },
    setCurrentDate(state, chosenDate) {
        state.currentDate = chosenDate;
    },
    setMerchants(state, merchants) {
        state.merchants = merchants;
    },
};

export const actions: ActionTree<CertificateState, RootState> = {
    addCost({ commit }, cost) {
        commit("setCost", cost);
    },

    addPaymentMethod({ commit }, method) {
        commit("setPaymentMethod", method);
    },

    isSelfSwtich({ commit }, value) {
        commit("setIsSelf", value);
    },

    addSelectedCard({ commit }, selCard) {
        commit("setSelectedCard", selCard);
    },

    resetRecipient({ commit }) {
        commit("setRecipientName", "");
        commit("setRecipientPhone", "");
        commit("setRecipientEmail", "");
        commit("setRecipientMessage", "");
        commit("setRecipientSender", "");
        commit("setIsAnon", false);
    },

    resetAllData({ commit, dispatch }) {
        dispatch("resetRecipient");
        commit("setCost", 1000);
        commit("setPaymentMethod", "");
        commit("setIsSelf", true);
        commit("setSelectedCard", GIFT_CERTIFICATE_DESIGNS[0]);
        commit("setIsAnon", false);
        commit("setStep", null);
        commit("setSenderPhone", "");
        commit("setSenderName", "");
        commit("setSenderEmail", "");
        commit("setCurrentDate", null);
        commit("setCurrentTime", null);
        commit("setSpecificTime", false);
    },
};
