import $ from "jquery";
import config from "../../config";
import { DEFAULT_CITY_ID } from "../../util/types";

export function generateUuid() {
    const s4 = function () {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

export function getAddressQuery(cityId?, stringified = false) {
    const output = { city_id: cityId || DEFAULT_CITY_ID };

    if (stringified) {
        return $.param(output);
    }

    return output;
}

export function getAppleWalletUrl(token) {
    return `${config.API_URL}/wallet/personalCard/token/${token}`;
}

function chr(code: number): string {
    return String.fromCharCode(code);
}

function ord(char: string): number {
    return char.charCodeAt(0);
}

export function encodeIP(ip: string): string {
    const ipKey = "HkasdgqwrioujKs18_s";
    let res = "";
    const delta = ord("z") - ord("a");
    for (let i = 0; i < ip.length; i++) {
        let n1 = (ord(ip[i]) * ord(ipKey[i])) % delta;
        res += chr(ord("a") + n1);
        n1 = (ord(ip[i]) * ord(ipKey[ipKey.length - i - 1])) % delta;
        res += chr(ord("A") + n1);
    }
    return res;
}

export function combineWithKey(s: string, key: string): number {
    let res = 0;
    const l1 = s.length;
    const l2 = key.length;
    const maxLen = Math.max(l1, l2);
    for (let i = 0; i < maxLen; i++) {
        res ^= s.charCodeAt(i % l1) * key.charCodeAt(i % l2);
    }
    return res;
}
