import { MenuCategory, MenuProduct } from "../../util/api.types";

export class CatalogFeaturedProductsFilter {
    private readonly categories: MenuCategory[];

    private readonly products: MenuProduct[];

    constructor(categories: MenuCategory[], products: MenuProduct[]) {
        this.categories = categories;
        this.products = products;
    }

    private getFeaturedCategoryIds(): number[] {
        return this.categories
            .filter((c) => c.featured)
            .sort((a, b) => (a.featured && b.featured ? a.featured - b.featured : 0))
            .map((c) => c.id);
    }

    private getCategoryProducts(categoryId: number): MenuProduct[] {
        return this.products.filter((p) => p.categories_ids && p.categories_ids.includes(categoryId));
    }

    getFeatured(): MenuProduct[] {
        const categoryIds = this.getFeaturedCategoryIds();
        // Один и тот же продукт может быть в нескольких featured-категориях, но нужен только один
        const result: MenuProduct[] = [];
        const usedProductsMap: { [key: number]: boolean } = {};

        for (const categoryId of categoryIds) {
            const products = this.getCategoryProducts(categoryId);
            for (const product of products) {
                if (usedProductsMap[product.id]) {
                    continue;
                }
                result.push(product);
                usedProductsMap[product.id] = true;
            }
        }

        return result;
    }
}
