import { MutationTree, GetterTree } from "vuex";
import { SettingsState } from "~/store_types/settings.types";

export const state: () => SettingsState = () => ({ config: {} });

export const mutations: MutationTree<SettingsState> = {
    setConfig(state, value) {
        state.config = value;
    },
};
