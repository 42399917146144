//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomAccordion from "~/components/common/CustomAccordion.vue";
import ProductEnergyTable from "~/components/products/ProductEnergyTable.vue";
import SetConsist from "~/components/products/SetConsist.vue";

export default {
    name: "ProductConsist",
    components: { SetConsist, ProductEnergyTable, CustomAccordion },
    props: ["product"],
    computed: {
        setProduct() {
            return this.product.set_products;
        },
    },
};
