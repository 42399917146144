//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import ProductEnergyTable from "~/components/products/ProductEnergyTable.vue";
import ProductTags from "~/components/products/ProductTags.vue";
import ProductScore from "~/components/products/ProductScore.vue";

export default {
    name: "ProductEnergy",
    components: { ProductScore, ProductTags, ProductEnergyTable },
    data() {
        return {
            infoEmpty: false,
        };
    },
    mounted() {
        if (this.isEmpty) {
            this.$emit("empty");
        }
    },
    computed: {
        ...mapGetters("products", ["getProductEnergyInfo"]),
        isEmpty() {
            return (
                this.infoEmpty &&
                (!this.productEnergyValues?.consist || this.productEnergyValues.consist.length === 0)
            );
        },
        productEnergyValues() {
            return this.getProductEnergyInfo;
        },
        notEmptyEnergy() {
            return Object.keys(this.productEnergyValues).length;
        },
    },
};
