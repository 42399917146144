var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"animation-container small-modal",class:[{ 'animation-fade': _vm.fade }, _vm.styleClass]},[_c('div',{ref:"arrow",staticClass:"small-modal__arrow-desktop",class:{ hidden: _vm.arrowClass.desctop },style:({
            left: _vm.getCoords('x', 'arrowCoords'),
            top: _vm.getCoords('y', 'arrowCoords'),
            rotate: '-90deg',
        })},[_c('tooltip-arrow')],1),_vm._v(" "),_c('div',{ref:"arrow",staticClass:"small-modal__arrow-slider",class:{ hidden: _vm.arrowClass.mobile },style:({
            left: _vm.getCoords('x', 'arrowCoords'),
            top: _vm.getCoords('y', 'arrowCoords'),
        })},[_c('tooltip-arrow')],1),_vm._v(" "),_c('div',{ref:"smallInfoModal",staticClass:"small-modal__wrapper",style:({
            left: _vm.getCoords('x', 'coords'),
            top: _vm.getCoords('y', 'coords'),
        })},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }