//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import cityLinksHelper from "~/util/city-links-helper";
import Vk from "~/components/icons/Vk.vue";
import Telegram from "~/components/icons/Telegram.vue";

export default {
    name: "NavSoc",
    components: { Telegram, Vk },
    data() {
        return {
            citiesSoc: {
                ekb: {
                    inst: "https://www.instagram.com/eda1.ru/",
                    vk: "https://vk.com/sushkof_pizza",
                    telegram: "https://t.me/sushkof_pizza",
                    facebook: "http://www.facebook.com/eda1.ru",
                },
                ryazan: {
                    vk: "https://vk.com/sushkof.pizza_ryazan",
                    inst: "https://www.instagram.com/eda1_ryazan/",
                },
                tagil: { vk: "https://vk.com/sushkof.pizza_tagil" },
                kamensk: { vk: "https://vk.com/sushkof.pizza_kamensk" },
                chelyabinsk: { vk: "https://vk.com/sushkof.pizza_chelyabinsk" },
                tyumen: { vk: "https://vk.com/sushkof.pizza_tyumen" },
                pyshma: { vk: "https://vk.com/sushkof.pizza_pyshma" },
                bereza: { vk: "https://vk.com/sushkof.pizza_bereza" },
                surgut: { vk: "https://vk.com/sushkof.pizza_surgut" },
                revda: { vk: "https://vk.com/sushkof.pizza_revda" },
                "verkhnyaya-salda": { vk: "https://vk.com/sushkof.pizza_salda" },
                orenburg: { vk: "https://vk.com/sushkof.pizza_orenburg" },
                sysert: { vk: "https://vk.com/sushkof.pizza_sysert" },
            },
        };
    },
    computed: {
        ...mapGetters("delivery", ["cityId"]),
        cityItem() {
            return cityLinksHelper.getCityItemById(this.cityId)?.slug;
        },
    },
    methods: {
        getSocLink(soc) {
            return this.citiesSoc?.[this.cityItem]?.[soc] || this.citiesSoc.ekb?.[soc];
        },
    },
};
