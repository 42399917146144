//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Arrow from "~/components/icons/Arrow.vue";

export default {
    name: "CustomAccordion",
    components: { Arrow },
    props: ["title"],
    data() {
        return { consistOpen: false };
    },
    methods: {
        toogleConsist() {
            this.consistOpen = !this.consistOpen;
        },
    },
};
