// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/no-img.webp");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".product[data-v-f22d12ca]{display:flex;padding:16px;border-bottom:1px #e5e5e5 solid;color:var(--main-black);font-size:16px;width:100%;overflow:hidden}.product__img-wrap[data-v-f22d12ca]{width:92px;height:80px;border-radius:5px;overflow:hidden;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;flex-shrink:0}.product__img[data-v-f22d12ca]{width:100%;height:100%;object-fit:cover}.product__base[data-v-f22d12ca]{flex-grow:1;max-width:100%;height:84px;display:flex;flex-flow:column;justify-content:space-between;padding-left:12px;overflow:hidden}.product__info[data-v-f22d12ca]{display:flex;justify-content:space-between}.product__title[data-v-f22d12ca]{width:190px}.mod .product__title[data-v-f22d12ca]{width:calc(100% - 35px)}.product__name[data-v-f22d12ca]{color:var(--main-black);display:block;text-decoration:unset}.product__name[data-v-f22d12ca],.product__modification[data-v-f22d12ca]{white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.product__modification[data-v-f22d12ca]{color:#acacac;white-space:nowrap;font-size:14px;font-family:var(--font-regular);margin-top:4px}.product__mod-icon[data-v-f22d12ca]{margin-right:3px}.product__actions[data-v-f22d12ca]{display:flex;margin-top:5px;align-items:center;justify-content:space-between;padding-bottom:4px}.product__actions__left[data-v-f22d12ca]{display:flex;align-items:center;width:190px}.product__actions-value[data-v-f22d12ca]{margin-right:16px}.product__price[data-v-f22d12ca]{font-size:18px;white-space:nowrap}.product__remove[data-v-f22d12ca]{width:27px;height:27px;border-radius:100%;border:0;display:flex;align-items:center;justify-content:center;background-color:var(--main-red);transition:.3s}.product__remove[data-v-f22d12ca]:hover{background-color:var(--main-red-dark)}.product__remove[data-v-f22d12ca]:focus{box-shadow:0 0 0 4px rgba(207,41,27,.302)}.product__remove-icon[data-v-f22d12ca]{width:11px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
