//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from "vuex";
import { numberWithSpaces } from "~/util/helpers";
import Trash from "~/components/icons/Trash.vue";
import ProductBeforeDiscountPrice from "~/components/products/ProductBeforeDiscountPrice.vue";

export default {
    name: "PreviewProduct",
    components: { ProductBeforeDiscountPrice, Trash },
    props: ["product"],
    data() {
        return { modifiersNames: null };
    },
    computed: {
        ...mapState("metrika", ["paramsAB"]),
        productIngredients() {
            const ingredientsTitles = this.product.kitchen_comment.map((item) => {
                return item.title;
            });
            return ingredientsTitles.join(", ");
        },
    },
    mounted() {
        this.getModifiersNames();
    },
    methods: {
        ...mapActions("products", ["deleteProduct"]),
        productPriceForAll(product) {
            return numberWithSpaces(product.price * product.quantity);
        },
        showProductPopup(product) {
            if (product.kitchen_comment || this.modifiersNames) {
                this.$store.dispatch("productPopup/showProductByPayload", {
                    product,
                    sel_modifiers: product.sel_modifiers,
                });
            } else {
                this.$store.dispatch("productPopup/showProductByPayload", { product });
            }
        },
        getModifiersNames() {
            if (this.product?.sel_modifiers) {
                this.modifiersNames = this.product.sel_modifiers
                    .map((mod) => {
                        return mod.name;
                    })
                    .join(", ");
            }
        },
    },
};
