import { render, staticRenderFns } from "./ConstituentProduct.vue?vue&type=template&id=0e8f3088&scoped=true&"
import script from "./ConstituentProduct.vue?vue&type=script&lang=js&"
export * from "./ConstituentProduct.vue?vue&type=script&lang=js&"
import style0 from "./ConstituentProduct.vue?vue&type=style&index=0&id=0e8f3088&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e8f3088",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductEnergy: require('/usr/src/app/components/products/ProductEnergy.vue').default,SmallInfoModal: require('/usr/src/app/components/modal/smallInfoModal.vue').default})
