import { NuxtCookies } from "cookie-universal-nuxt";
import { Context } from "@nuxt/types";
import { AbstractStorage } from "../abstract-storage/index";
import { PossibleContextData } from "~/service/storages/abstract-storage/types";

export class BannersStorage extends AbstractStorage {
    protected getKey() {
        const cityId = this.getCityId();
        const departmentId = this.getDepartmentId();
        return `_banners.${cityId}.${departmentId}`;
    }

    protected getLogName() {
        return "BANNERS_STORAGE";
    }

    protected getUpdatePeriod(): number | null {
        return 60;
    }

    protected getRemovePeriod(): number | null {
        return 15 * 60;
    }

    protected async apiRequest() {
        const cityId = this.getCityId();
        const departmentId = this.getDepartmentId();
        let url = `/info/banners?type=desktop&city_id=${cityId}&is_webp=1`;
        if (departmentId) {
            url += `&departmentId=${departmentId}`;
        }

        const resp = await this.getApi().get(url);

        return resp;
    }

    private getDepartmentId() {
        return this.getContextValue("departmentId");
    }

    protected parseContextData(ctx: Context): PossibleContextData {
        const cookies: NuxtCookies | undefined = ctx["$cookies"]; /* eslint-disable-line dot-notation */
        return Object.assign(
            { departmentId: cookies ? cookies.get("department_id") : undefined },
            super.parseContextData(ctx)
        );
    }
}
