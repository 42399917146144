export function inputValidation(inputName, inputValue, addValue) {
    let errors = null;

    const validation = {
        fio: () => {
            const invalidCharsFIORegex = /[^А-ЯЁа-яё\s-]/;
            if (!inputValue || !inputValue.length) {
                errors = "Заполните имя";
            } else if (invalidCharsFIORegex.test(inputValue)) {
                errors = "Имя может содержать символы кириллицы и пробел";
            }
        },
        phone: () => {
            if (!inputValue || inputValue.length !== 10) {
                errors = "Некорректный номер телефона";
            }
        },
        password: () => {
            if (!inputValue || inputValue.length < 6) {
                errors = "Пароль должен быть не короче 6 символов";
            }
        },
        passwordRepeat: () => {
            if (inputValue !== addValue.password) {
                errors = "Пароли не совпадают! Попробуйте еще раз.";
            }
        },
        passwordAutorisation: () => {
            if (!inputValue.length) {
                errors = "Введите пароль";
            }
        },
        email: () => {
            const emailRegexp = /\S+@\S+\.(\w(?!\d))+$/;
            // let emailRegexp = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
            if (!inputValue || inputValue.length == 0) {
                // errors = 'Напишите электронный адрес, чтобы отправить информацию о вашем заказе на электронную почту, вместо СМС'
                errors = "Напишите электронный адрес";
            } else if (!emailRegexp.test(inputValue)) {
                errors = "Проверьте адрес электронной почты";
            }
        },
        birthday: () => {
            if (!inputValue) {
                errors = "Заполните дату рождения";
            }
        },
        complianceAgreement: () => {
            if (!inputValue) {
                errors =
                    "Простите, но мы не можем принять Ваш запрос без Вашего согласия на обработку персональных данных";
            }
        },
        bonusAgreement: () => {
            if (!inputValue) {
                errors =
                    "Простите, но мы не можем принять Ваш запрос без Вашего согласия на обработку персональных данных";
            }
        },
        bonusCardNumber: () => {
            if (!inputValue.length) {
                errors = "Заполните номер карты";
            }
        },
        confirmType: () => {
            if (inputValue === false) {
                return;
            }
            if (!inputValue) {
                errors = "Выберите тип подтверждения";
            }
        },
        requiredInput: () => {
            if (!inputValue) {
                errors = "Заполните поле";
            }
        },
    };

    validation[inputName]();
    return errors;
}

export function validateUserData(userData, keyPrefix) {
    const errors = {};
    for (const [key, value] of Object.entries(userData)) {
        if (key === "passwordRepeat") {
            errors[key] = inputValidation(key, value, { password: userData.password });
        } else {
            errors[key] = inputValidation(key, value);
        }
        if (!errors[key]) {
            delete errors[key];
        }
    }

    if (keyPrefix) {
        for (const k in errors) {
            const v = errors[k];
            errors[keyPrefix + k] = v;
            delete errors[k];
        }
    }

    if (Object.keys(errors).length > 0) {
        errors.errorsDisplay = true;
    }

    return errors;
}

export function validateDeliveryAddress(addressComponents, isExternalDoorToDoorDelivery) {
    const errors = {};

    if (isExternalDoorToDoorDelivery) {
        if (!addressComponents) {
            addressComponents = { entrance: null, floor: null, flat: null };
        }

        if (!addressComponents.floor) {
            errors.floor = "Заполните этаж";
        }
        if (!addressComponents.flat) {
            errors.flat = "Заполните квартиру";
        }
    }

    return errors;
}
