//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import NuxtCityLink from "~/components/common/NuxtCityLink.vue";
import MenuDelimeter from "~/components/MenuDelimeter.vue";
import Toggler from "~/components/order/tabs/Toggler.vue";
import NavSoc from "~/components/sections/NavSoc.vue";
import AuthBlock from "~/components/AuthBlock";
import FeedbackInfo from "~/components/FeedbackInfo";
import CameraLive from "~/components/CameraLive";
import ConnectInfo from "~/components/ConnectInfo";
import AppleStore from "~/components/icons/AppleStore.vue";
import GoogleStore from "~/components/icons/GoogleStore.vue";
import Logo from "~/components/icons/Logo.vue";
import BurgerClose from "~/components/icons/BurgerClose.vue";
import MenuIconTile from "~/components/icons/MenuIconTile.vue";
import MenuIconList from "~/components/icons/MenuIconList.vue";
import { sendYandexMetric } from "~/service/ecommerce";

export default {
    name: "MenuPopup",
    components: {
        MenuIconList,
        MenuIconTile,
        BurgerClose,
        Logo,
        GoogleStore,
        AppleStore,
        FeedbackInfo,
        AuthBlock,
        CameraLive,
        ConnectInfo,
        NuxtCityLink,
        MenuDelimeter,
        Toggler,
        NavSoc,
    },
    props: ["showMenu"],
    data() {
        return {
            menuPopup: true,
            mobileScreen: false,
            localCatalog: "",
        };
    },
    computed: {
        ...mapState("products", ["catalogView"]),
        baseUrl() {
            return "";
        },
    },
    watch: {
        showMenu(val) {
            if (val) {
                this.$nuxt.$on("Escape-pressed", () => {
                    this.toggleMenu();
                });
            } else {
                this.$nuxt.$off("Escape-pressed");
            }
        },
    },
    mounted() {
        this.localCatalog = this.catalogView.view;
    },
    methods: {
        toggleMenu() {
            this.$emit("toggleMenu");
        },
        viewMenuSet(type) {
            this.localCatalog = type;
            setTimeout(() => {
                this.$store.dispatch("products/changeViewCatalog", {
                    view: type,
                    screenWidth: innerWidth,
                });
            }, 0);
        },
        closeMenu() {
            if (this.showMenu) {
                this.toggleMenu();
            }
        },
        handlePizzaAdventage() {
            sendYandexMetric("PPPizza");
        },
    },
};
