import axios from "axios";
import config from "../../config/index";

const BASE_LOG_URL = config.LOG_BASE_URL;

export async function log(category: string, data: any): Promise<void> {
    if (!BASE_LOG_URL) {
        console.log("No log url provided");
        return;
    }

    try {
        await axios.post(`${BASE_LOG_URL}/log`, { category, data }, { timeout: 20000 });
    } catch (err) {
        console.warn("Logs");
        console.error(err);
    }
}

export async function logEcommerce(step: string, orderId: string, data: any = {}) {
    return await log("ecommerce", { ...data, step, orderId: +orderId });
}
